import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { red, indigo } from "@material-ui/core/colors";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect"
import ModalEditDiscountItem from "./ModalEditDiscountItem";
import ModalDeleteItemTable from "./ModalDeleteItemTable";
import ModalSelectSepecific from "./ModalSelectSepecific";
import { Edit, Delete } from "@material-ui/icons/";
import ModalAdd from "./ModalAdd";
import {toMoneyFormat} from "utils/formatFunctions";
import {
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  TypographyMui as Typography,
} from "utils/componentsStyle";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import TableComponent from "views/Widgets/Common/TableComponent";
import EditInTableButton from "views/Widgets/Common/EditInTableButton";
import allActions from 'actions';


// eslint-disable-next-line react/prop-types
export default function BenefitTable({
  dataArray,
  setdataArray,
  listBenefit,
  benefitData,
  selectedPackage,
  deleteArray,
  setdeleteArray,
  updateArray,
  setupdateArray,
  typeRequest,
  stateRequest,
  title,
  type,
}) {
  const dispatch = useDispatch();
  const [tempArray, setTempArray] = useState(dataArray);
  const [openModalSelectSpecific, setopenModalSelectSpecific] = useState(null);
  const [loading, setloading] = useState(null);
  const [change, setchange] = useState(true);
  const [openModal, setopenModal] = useState(null);
  const [editableDiscount, seteditableDiscount] = useState(null);
  const [openDeleteModal, setopenDeleteModal] = useState(null);
  const [tempQuotation, settempQuotation] = useState(null);
  const [tempTypeQuotation, settempTypeQuotation] = useState(null);
  const [updateDict, setupdateDict] = useState(null);
  const [editableValueItem, seteditableValueItem] = useState(null);

  const tempType = `_${type}`;

  const transient = selectedPackage?.tipo_permanencia == "Transitorio" &&  title == "Profesional"

  const dictKitSpecific = useSelector(state => state.currentList.dictKitSpecific);
  const dictEquipGenericSpecific = useSelector(state => state.currentList.dictEquipGenericSpecific);
  const listFrecuency = useSelector(state => state.currentList.LISTA_TIPO_FRECUENCIA_PRESTACION);
  const dictFrecuency = useSelector(state => state.currentList.DICCIONARIO_TIPO_FRECUENCIA_PRESTACION);
  const dictDiscountQuotation = useSelector(state => state.currentList.dictDiscountQuotation);
  const quotationDiscount = useSelector(state => state.currentGlobalVar.quotationDiscount);
  const typeQuotationDiscount = useSelector(state => state.currentGlobalVar.typeQuotationDiscount);

  const headerTable = [
    {label:  type !== "equipo" ? "Item" : "Genérico"},
    {eval:false, label: "Especifico", style: type !== "equipo" ? null : {minWidth: "400px"}, hidding: type !== "equipo"},
    {label: "SKU", hidding: type !== "insumos_medicamentos"},
    {label: "Stock", hidding: type !== "insumos_medicamentos"},
    {label: "Cantidad Mensual", hidding: type !== "insumos_medicamentos"},
    {label: "Tipo Cantidad", hidding: type === "insumos_medicamentos"},
    {label: "Cantidad", hidding: type === "insumos_medicamentos", style:{ minWidth: "200px" }},
    {label: "Cada", hidding: type === "insumos_medicamentos", style: type === "insumos_medicamentos" ? null : {minWidth: "200px"}},
    {label: "Tiempo", hidding: type === "insumos_medicamentos", style: type === "insumos_medicamentos" ? null : {minWidth: "200px"}},
    {label: "horario", style: transient ? {minWidth: "200px"} : null, hidding: !transient},
    {label: "Total", style:{minWidth: "65px"}},
    {label: "Total con descuento o recargo", style:{minWidth: "200px"}},
    {label: "Total con descuento o recargo general", style:{minWidth: "200px"}},
    {eval:false, label: "Acción"}
  ]

  const editableItem = typeRequest === "Cotizacion" && !["Completado", "Finalizado"].includes(stateRequest)

  const componentUpdateValue = (value, handleFunction, index, column, min="1") => {
    const validateEditable = (updateDict?.index === index && updateDict?.column === column)
    const disabledEdit = (updateDict && (updateDict?.index !== index || updateDict?.column !== column))
    return <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
    <Grid item container xs={8} direction="row" justify="center" alignItems="center">

    {validateEditable ?
      <DetailNumber
        value={editableValueItem}
        onchange={e => {
          if (/^[0-9\b]+$/.test(e.target.value) && min !== null ? e.target.value >= min : true) {
            seteditableValueItem(e.target.value);
          }
        }}
        min={min}
      />:
      value
    }
    </Grid>
    <Grid item container xs={4} direction="row" justify="flex-end" alignItems="center">
      <EditInTableButton disabledEdit={disabledEdit} selectEditable={validateEditable} onClickSave={() =>handleUpdateValue(index, column, handleFunction, null)} onClickEdit={() =>handleUpdateValue(index,column,handleFunction, value)}/>
    </Grid>
  </Grid>
  };

  const checkDiscountItem = (row) => {
    const key = `${row.id_prestaciones?.id || row.id_equipo?.id || row.id_insumo_medicamento?.id}${tempType}_${row.fuera_paquete}`
    return dictDiscountQuotation[key] || false;
  }

  const calculateIndividualDiscount = (amount, value, discount, typeDiscount) => {
    if (!value){
      return 0;
    }
    const total = amount*value;
    const totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
    return parseInt(totalDiscount == 0 ? total : total + (total*(totalDiscount/100)));
  };

  const calculateGeneralDiscount = (amount, value, discount, typeDiscount) => {
    let totalDiscount = 0;
    const total = amount*value;
    if (!value){
      return 0;
    } else if(discount && quotationDiscount){
      const tempDiscount1 =  typeDiscount === "Descuento" ? discount*-1 : discount;
      const tempDiscount2 =  typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      totalDiscount = total + (total*(tempDiscount1/100))
      return totalDiscount + (totalDiscount*(tempDiscount2/100))
    } else if(discount){
      totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
      return total + (total*(totalDiscount/100))
    } else if(quotationDiscount){
      totalDiscount = typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      return total + (total*(totalDiscount/100))
    } else {
      return total;
    }
  };

  const handleDeleteRow = () => {
    const row = openDeleteModal.row;
    const index = openDeleteModal.index;
    const tempDeleteArray = deleteArray;

    let tempData = dataArray
    if (row.id){
      tempDeleteArray.push(row.id)
      setdeleteArray(tempDeleteArray);
    }
    tempData = tempData.filter((temprow, tempIndex) => index !== tempIndex )

    setdataArray(tempData);
    setTempArray(tempData);
    setchange(true);
    setopenDeleteModal(null);
  };

  const handleAddItem = (value, table) => {
    const auxArray = dataArray;
    const tempData = benefitData[value.value];
    const tempInfo = tempArray.filter(
      row => row.id_prestaciones?.id === tempData.id
    );

    const default_key =`${tempData.id}_habil`;
    const tempValue = tempData.valor_con_iva || tempData.valor_boleta || selectedPackage?.prestaciones_diferenciadas[default_key]?.valor
    
    const element = {
      frecuencia: 1,
      tipo_frecuencia: "Meses",
      cantidad: 1,
      fuera_paquete: table,
      valor: tempValue ? tempValue: 0,
      otros_datos: "-",
      id_prestaciones: null,
      id_insumo_medicamento: null,
      id_equipo: null,
      id_paquete_cotizacion: selectedPackage,
      sku_especifico: " ",
      detalle_especifico: " ",
      otros_datos: " ",
      frecuencia_original: 1,
      tipo_frecuencia_original:  "Meses",
      cantidad_original: 1,
      horario_inhabil: transient ? false : null, 
    };

    if (type === "prestacion"){
      element.id_prestaciones = tempData;
    }

    if (type === "equipo"){
      element.id_equipo = tempData;
    }

    if (type === "insumos_medicamentos"){
      element.id_insumo_medicamento = tempData;
    }

    if (tempInfo.length > 0) {
      element["frecuencia"] = tempInfo[0].frecuencia;
      element["tipo_frecuencia"] = tempInfo[0].tipo_frecuencia;
    }
    auxArray.push(element);
    setdataArray(auxArray);
    setTempArray(auxArray);
    setchange(true);
    setloading(true);
  };

  const handleChangeQuantity = (value, index) => {
    const tempUpdateArray = updateArray;
    const tempData = dataArray
    let row = {}

    tempData[index].cantidad = parseInt(value);
    row = tempData[index];

    if (row.id){
      tempUpdateArray.push(row);
    }
    setupdateArray(tempUpdateArray);
    setdataArray(tempData);
    setTempArray(tempData);
    setchange(true);
    setloading(true);
  };

  const handleChangeTypeFrecuncy = (value, index) => {
    const tempUpdateArray = updateArray;
    const tempData = dataArray

    let row = {}
    tempData[index].tipo_frecuencia = value.value;
    row = tempData[index];

    if (row.id){
      tempUpdateArray.push(row);
    }
    setdataArray(tempData);
    setTempArray(tempData);
    setchange(true);
    setloading(true);
  };

  const handleChangeShedule = (value, index) => {
    const tempUpdateArray = updateArray;
    const tempData = dataArray
    if (transient){
      const schedule = value.value ?  "inhabil" : "habil"
      const key_proff = `${tempData[index].id_prestaciones_id}_${schedule}`;
      const default_key = `${tempData[index].id_prestaciones_id}_habil`;
      const tempValue = selectedPackage?.prestaciones_diferenciadas[key_proff]?.valor || selectedPackage?.prestaciones_diferenciadas[default_key]?.valor;
      tempData[index].valor = tempValue;
    }
    tempData[index].horario_inhabil = value.value;

    let row = {}
    row = tempData[index];

    if (row.id){
      tempUpdateArray.push(row);
    }
    setdataArray(tempData);
    setTempArray(tempData);
    setchange(true);
    setloading(true);
  };

  const handleChangeFrequency = (value, index) => {
    setloading(true);
    const tempUpdateArray = updateArray;
    const tempData = dataArray
    let row = {}

    tempData[index].frecuencia = parseInt(value);
    row = tempData[index];

    if (row.id){
      tempUpdateArray.push(row);
    }

    setdataArray(tempData);
    setTempArray(tempData);
    setloading(true);
    setchange(true);
  };

  const handleSelectSpecificItem = (dictValues, index, table) => {
    setloading(true);
    const tempUpdateArray = updateArray;
    const tempData = dataArray;
    let row = {}

    row = tempData[index];

    row.items = []
    Object.keys(dictValues).map((key) => {
      if (dictValues[key]?.value){
        if (key === row.id_equipo.SKU){
          row.sku_especifico = dictValues[key].value || "Sin especificos";
          row.detalle_especifico = dictValues[key].label || "Sin especificos";
          row.id_paquete_cotizacion = selectedPackage
        } else {
          const tempData = benefitData[dictValues[key]?.id];
          const element = {
            frecuencia: row.frecuencia,
            tipo_frecuencia: row.tipo_frecuencia,
            cantidad: row.cantidad,
            fuera_paquete: row.fuera_paquete,
            valor: tempData.valor_boleta,
            otros_datos: "-",
            id_prestaciones: null,
            id_equipo: tempData,
            id_insumo_medicamento: null,
            id_paquete_cotizacion: selectedPackage,
            sku_especifico: dictValues[key].value || "Sin especificos",
            detalle_especifico: dictValues[key].label || "Sin especificos",
            horario_inhabil: null,
          }
          row.items.push(element)
        }
      }
    });

    tempData[index] = row;
    setdataArray(tempData);
    setTempArray(tempData);
    if (row.id){
      tempUpdateArray.push(row);
    }
    setopenModalSelectSpecific(null);
    setloading(true);
  };

  const handleSaveDiscount = (typeDiscount, valuePercentage)  => {
    const key = `${editableDiscount.id_prestaciones?.id || editableDiscount.id_equipo?.id || editableDiscount.id_insumo_medicamento?.id}${tempType}_${editableDiscount.fuera_paquete}`
    const tempDictDiscountQuotation = {...dictDiscountQuotation}
    tempDictDiscountQuotation[key] = {
      tipo_porcentaje: typeDiscount,
      porcentaje_item: valuePercentage,
      fuera_paquete: editableDiscount.fuera_paquete,
      id_paquete_cotizacion: editableDiscount.id_paquete_cotizacion?.id,
      id_prestaciones: editableDiscount.id_prestaciones?.id,
      id_insumo_medicamento: editableDiscount.id_insumo_medicamento?.id,
      id_equipo: editableDiscount.id_equipo?.id,
    };
    dispatch({
      type: "DICCIONARIO_DESCUENTOS_COTIZACION",
      payload: tempDictDiscountQuotation
    });
    seteditableDiscount(null);
    setloading(true);
  };

  const handleUpdateValue = (index, column, handleFunction,  value=null) => {
    setupdateDict(updateDict ? null : { index, column });
    if (value){
      seteditableValueItem(value);
    } else {
      handleFunction(editableValueItem, index);
      seteditableValueItem(null);
    }
    setchange(true);
  };

  const dictValues = selectedPackage.prestaciones_diferenciadas;

  const listSchedule = [
    {value: null, label: "-"}, 
    {value: false, label: "Habil"}, 
    {value: true, label: "Inhabil"}
  ]

  const dictSchedule = listSchedule.reduce((accum, obj) => {
    return {...accum, [obj.value]: obj}
  }, {})

  const dataTable = tempArray.map((row, index) => {
    const keyDictValue = `${row?.id_prestaciones?.id}_${row.horario_inhabil ? "inhabil" : "habil" }`
    const tempValue = dictValues[keyDictValue]?.valor || row.valor

    const _quantity_ = editableItem ?
      componentUpdateValue(
        row.cantidad,
        handleChangeQuantity,
        index,
        "cantidad",
        0
      ) : row.cantidad;

    const _frecuency_ = editableItem ?
      componentUpdateValue(
        row.frecuencia,
        handleChangeFrequency,
        index,
        "frecuencia",
      ) : row.frecuencia;

    const _typeFrecuency_ = editableItem ?
      <DetailSelect
        value={dictFrecuency[row.tipo_frecuencia]}
        onchange={(value) => { handleChangeTypeFrecuncy(value, index)}}
        list={listFrecuency}
      /> : row.tipo_frecuencia;


    const tempDiscount = calculateIndividualDiscount(
      row.cantidad,
      tempValue,
      checkDiscountItem(row).porcentaje_item,
      checkDiscountItem(row).tipo_porcentaje
    )

    const _discount_ = editableItem ? (!checkDiscountItem(row) && !row.editar_descuento ) ?
      <Button variant="outlined"
        style={{
          color: indigo[500],
          borderColor: indigo[500],
        }}
        onClick={() => seteditableDiscount(row)}
      >
        Agregar %
    </Button> :
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={8}
      >
        <Typography variant="body2" style={{color: "#000000ad"}}>
          {`(${checkDiscountItem(row).tipo_porcentaje === "Descuento" ? "-" : "+"}${checkDiscountItem(row).porcentaje_item}%)`}
        </Typography>
        <IconButton size="small"
          style={{
            border:`1px ${indigo[500]} solid`,
            margin: "0 0 0 12px"
          }}
          onClick={() => seteditableDiscount(row)}
        >
          <Edit style={{color: indigo[500]}} />
        </IconButton>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={4}
      >
        <Typography variant="body2">
          {toMoneyFormat(tempDiscount)}
        </Typography>
      </Grid>
    </Grid> : "Sin descuento";

    const tempTotalDiscount = calculateGeneralDiscount(
      row.cantidad,
      tempValue,
      checkDiscountItem(row)?.porcentaje_item,
      checkDiscountItem(row)?.tipo_porcentaje
    )

    const _totalDiscount_ = <Typography variant="body2"> {toMoneyFormat(tempTotalDiscount)} </Typography>

    const _buttonDelete_ = editableItem ? <Button
      variant="outlined"
      style={{
        color: red[500],
        borderColor: red[500],
      }}
      onClick={() => setopenDeleteModal({row, index: index})}
      startIcon={<Delete style={{color: red[500]}}/>}
    >
      Eliminar
    </Button> : null;

    const name = type === "equipo" ?  `(${row.id_equipo?.SKU}) ${row.id_equipo?.descripcion}` : type === "insumos_medicamentos" ?  `${row.id_insumo_medicamento?.descripcion_producto}` :   row.id_prestaciones.nombre;

    const _specific_ = type !== "equipo" ? null : row.sku_especifico != " " ?
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        item
        xs={12}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={10}
        >
          <Typography
            variant='subtitle2'
            >
            {`${row.detalle_especifico}`}
          </Typography>

        </Grid>
        {editableItem &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={2}
          >
            <Button
              variant="contained" color="primary"
              style={{background: indigo[500]}}
              onClick={() => setopenModalSelectSpecific({row, index})}
            >
              Editar
            </Button>

        </Grid>
        }
        {(row.items && row.items.length > 0) &&
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item
          xs={2}
        >
          <Typography
            variant='caption'
            >
            {`Este kit incluye:`}
          </Typography>
        </Grid>
        }
        {row.items &&
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item
          xs={10}
        >
          {row.items.map((subRow) => (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={12}
          >
          <Typography
            variant='caption'
          >
            {`(${subRow.id_equipo.SKU}) - ${subRow.detalle_especifico}`}
          </Typography>
        </Grid>
        ))}
        </Grid>
        }
      </Grid> :
      ((dictKitSpecific[row.id_equipo.SKU] || dictEquipGenericSpecific[row.id_equipo.SKU]) && editableItem) ?
      <Button
        variant="contained" color="primary"
        style={{background: indigo[500]}}
        onClick={() => setopenModalSelectSpecific({row, index:index, in:true})}
      >
        Seleccionar especifico
      </Button> : null

    const _shedule_ =  editableItem ? <DetailSelect
      value={dictSchedule[row.horario_inhabil]}
      onchange={(value) => { handleChangeShedule(value, index)}}
      list={listSchedule}
    /> : row.horario_inhabil ? "Inhabil" : "Habil" 

    return {
      fuera_paquete: row.fuera_paquete,
      data: [
        {value: name},
        {_value_: _specific_, hidding: type !== "equipo"},
        {value: row.id_insumo_medicamento?.SKU, hidding: type !== "insumos_medicamentos"},
        {value: type !== "equipo" ? row.id_prestaciones?.tipo_cantidad : "Unidad", hidding: type === "insumos_medicamentos"},
        {value: row.id_insumo_medicamento?.stock_producto, hidding: type !== "insumos_medicamentos" },
        {_value_: _quantity_, value: row.cantidad},
        {_value_: _frecuency_, value: row.frecuencia, hidding: type === "insumos_medicamentos"},
        {_value_: _typeFrecuency_, value: row.tipo_frecuencia, hidding: type === "insumos_medicamentos"},
        {_value_: _shedule_, value: row.horario_inhabil ? "Inhabil" : "Habil" , hidding: !transient},
        {value: toMoneyFormat(row.cantidad*tempValue)},
        {_value_: _discount_, value: tempDiscount},
        {_value_: _totalDiscount_, value: tempTotalDiscount},
        {_value_: _buttonDelete_},
      ]
    }
  })

  const dataPackage = dataTable.filter((row) => row.fuera_paquete === false);
  const dataExtra = dataTable.filter((row) => row.fuera_paquete === true);

  const totalPackage = dataPackage.reduce((accum, obj) => accum + (obj.data[11]?.value || 0), 0);
  const totalextra = dataExtra.reduce((accum, obj) => accum + (obj.data[11]?.value || 0), 0);

  useEffect(() => {
    if (loading){
      setloading(false);
    }
    if(change){
      calculateAmount();
    }
    if(tempQuotation !== quotationDiscount || tempTypeQuotation !== typeQuotationDiscount){
      settempQuotation(quotationDiscount);
      settempTypeQuotation(typeQuotationDiscount);
      setloading(true);
    }

  });

  const calculateAmount = () => {
    setchange(false);
    if (type === "insumos_medicamentos"){
      if (title === "Insumos") dispatch(allActions.setGlobalVar.setammountResourcePackage(totalPackage))
      if (title === "Medicamentos") dispatch(allActions.setGlobalVar.setammountMedicineValuePackage(totalPackage))
    }
    if (title === "Profesional") dispatch(allActions.setGlobalVar.setammountProfesionalValuePackage(totalPackage))
    setloading(true)
  };

  return (
    <Card>
      <CardBody>
        <GridContainer>
          {loading  && <div></div> }
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "68px 0 32px 0"}}
          >
            <Typography >
              {`En paquete: ${selectedPackage?.zona} ${selectedPackage?.complejidad} ${selectedPackage?.descripcion ? `- ${selectedPackage?.descripcion}` : ""}`}
            </Typography>
          </Grid>
          {editableItem &&
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModal(false)}
            >
              {`Agregar ${title}`}
            </Button>
          </Grid>
          }
          <Grid item xs={12} style={{margin: "30px 0 0 0"}}>
            <TableComponent
              headers={headerTable}
              data={dataPackage.map((row) => row.data)}
              size={'small'}
              pageSize={100}
              styleContainer={{overflowX: 'auto'}}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            item
            xs={12}
          >
            <Typography>
              {`${toMoneyFormat(totalPackage)}`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "68px 0 32px 0"}}
          >
            <Typography >
              Extra a permanencia
            </Typography>
          </Grid>
          { editableItem &&
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModal(true)}
            >
              {`Agregar ${title}`}
            </Button>
          </Grid>
          }
          <Grid item xs={12} style={{margin: "30px 0 0 0"}}>
            <TableComponent
              headers={headerTable}
              data={dataExtra.map((row) => row.data)}
              size={'small'}
              pageSize={100}
              styleContainer={{overflowX: 'auto'}}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            item
            xs={12}
          >
            <Typography>
              {`${toMoneyFormat(totalextra)}`}
            </Typography>
          </Grid>
        </GridContainer>
      </CardBody>
      {openModal !== null && (
        <ModalAdd
          open={openModal !== null}
          table={openModal}
          handleClose={() => setopenModal(null)}
          listItem={listBenefit}
          dataInfo={benefitData}
          dataArray={transient ? [] : dataArray}
          setdataArray={setdataArray}
          handleAddItem={handleAddItem}
          tempArray={tempArray}
        />
      )}
      {editableDiscount && (
        <ModalEditDiscountItem
          open={!!editableDiscount}
          handleClose={() => seteditableDiscount(null)}
          row={editableDiscount}
          handleSaveDiscount={handleSaveDiscount}
        />
      )}
        {openDeleteModal && (
        <ModalDeleteItemTable
          open={!!openDeleteModal}
          handleClose={() => setopenDeleteModal(null)}
          handleDelete={handleDeleteRow}
          title={`Eliminar ${title}`.toUpperCase()}
        />
      )}
      {openModalSelectSpecific &&
        <ModalSelectSepecific
          open={openModalSelectSpecific !== null}
          data={openModalSelectSpecific}
          handleClose={() => setopenModalSelectSpecific(null)}
          handleSelectSpecificItem={handleSelectSpecificItem}
        />
      }
    </Card>
  );
}
