import React, {useState, useEffect, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Button as ButtonMUI,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
} from '@material-ui/core';
import {
    Info,
} from '@material-ui/icons';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {ButtonTextTemplate as Button} from "utils/componentsStyle";
import {deepOrange, blue, indigo, teal, red, orange, grey} from "@material-ui/core/colors";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {dictMonth, daysWeek} from "utils/validationFunction";
import {setmessageGeneral} from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function MonthTable({
    dailyArray,
    arrayWorkers,
    dictAvailability,
    selectDate,
    selectPatient,
    listSelectedRemove,
    listType,
    listAbsence,
    multipleSelectMode,
    checkExistSelectedItem,
    handleNavigation,
    handleDownloadData,
    handleSelectDateWorker,
    handleEditDateWorker,
    handleSelectWorkerOther,
    handleSelectDeletedItem,
    handleCheckPatientWorker,
    seteditWork,
    showTabs,
    dictReservation,
    dictWorkOther,
    dictRegisterVar,
    changeDate=true,
    changeButtons=false,
}) {
    const dispatch = useDispatch();

    const classes = useStyles();
    const [initialCharge, setinitialCharge] = useState(true);
    const [dictFilter, setdictFilter] = useState([]);

    const listSelectableFilterItems = [...(listType ? listType : []), ...listAbsence]

    useEffect(() => {
        if (initialCharge){
          initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
    };

    const handleChange = (value, key) => {
        const tempDictFilter = {...dictFilter};
        if (value == '') delete tempDictFilter[key]
        else tempDictFilter[key] = value;
        setdictFilter(tempDictFilter);
    };

    const defineBackGroundColor = (type, availability=null, rowWork=null) => {
        let color = "white";

        if (availability?.tipo_registro === "Inasistencias") {
            color = red[500];
        } else if (availability?.tipo_registro === "Vacaciones"){
            color = orange[500];
        } else if (type === "HabilitacionDomicilio"){
            color = blue[100];
        } else if (type === "ExamenAsignarTens"){
            color = deepOrange[100];
        } else if (type === "AsignarEspecialistasTENS"){
            color = indigo[100];
        } else if (type === "TerapiaAntibioticoAsignarTens"){
            color = teal[100];
        }
        if (availability){
            const check = checkExistSelectedItem(availability)
            if (check.length > 0){
                color =   grey[500];
            }
        } else if (rowWork){
            const check = checkExistSelectedItem(rowWork)
            if (check.length > 0){
                color =   grey[900];
            } else {
                color =   indigo[500];
            }
        }
        return color;
    };

    const selectModeEditDateWorker = (row, rowWorker) => {
        if (multipleSelectMode){
            handleSelectDeletedItem(true, row)
        } else {
            handleEditDateWorker(row, rowWorker)
        }
    }

    const selectDateWorker = (row, rowWorker) => {
        if (multipleSelectMode){
            setmessageGeneral(dispatch, "No se peude agendar turnos en el modo de selección multiple", "warning", "¡Advertencia!");
        } else {
            handleSelectDateWorker(row, rowWorker)
        }
    }

    const AddingButtonWork = (row, RowWorker, index, Workerindex) => {
        let tempArray = row.filter((subRow) => subRow.tipo_turno);
        let checkRegisterVar = row.filter((subRow) => subRow.id_registro_variable_dinamica);
        checkRegisterVar = checkRegisterVar[0]
        checkRegisterVar = dictRegisterVar[checkRegisterVar?.id_registro_variable_dinamica]
        tempArray = tempArray[0]
        const color = defineBackGroundColor(tempArray?.tipo_turno, null, tempArray);
        const styleButton = {
            backgroundColor: color,
            color: indigo[50],
            fontSize: "large",
            padding: "4px 8px",
        }
        if (checkRegisterVar){
            styleButton["border"] = "3px solid red"
        }
        return (
            <TableCell
                key={`${index}-${Workerindex}-work`}
                id={`${index}-${Workerindex}-work`}
                className="text-center align-middle"
                style={{position: "relative", width: "95px", height: "73px"}}
            >
                {(tempArray?.tipo_turno && tempArray?.id_ficha === selectPatient.id_ficha_paciente.id) ?
                <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => selectModeEditDateWorker(tempArray, RowWorker)}
                    style={styleButton}
                >
                    {tempArray?.tipo_turno}
                </IconButton>:
                <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => handleSelectWorkerOther(row, RowWorker)}
                    style={{padding: "0"}}
                >
                    <Info
                        style={{
                            // backgroundColor: indigo[50],
                            color: indigo[500],
                        }}
                        fontSize="large"
                    />
                </IconButton>
                }
            </TableCell>
        )
    };

    const cellBackgroundColor = (rowWork, RowWorker, rowAvailability, index, Workerindex) => {
        let color = defineBackGroundColor(rowWork?.tipo_tarea, rowAvailability, rowWork)
        return  rowAvailability ?
            <TableCell
                key={`${index}-${Workerindex}-work`}
                id={`${index}-${Workerindex}-work`}
                className="text-center align-middle"
                style={{width: "95px", height: "73px", background: color}}
                onClick={() => selectModeEditDateWorker(rowAvailability, RowWorker)}
            >
                {" "}
            </TableCell> :
            <TableCell
                key={`${index}-${Workerindex}-work`}
                id={`${index}-${Workerindex}-work`}
                className="text-center align-middle"
                style={{width: "95px", height: "73px", background: color}}
                onClick={() => seteditWork([rowWork, RowWorker])}
            >
                {" "}
            </TableCell>
    }

    const cellButtonBackgroundColor = (rowWork, row, RowWorker, rowAvailability, index, Workerindex) => {
        let tempArray = row.filter((subRow) => subRow.tipo_turno);
        tempArray = tempArray[0]
        let color = defineBackGroundColor(rowWork?.tipo_tarea, rowAvailability, rowWork)
        const colorBtn = defineBackGroundColor(tempArray?.tipo_tarea, null, tempArray);

        return (
            <TableCell
                key={`${index}-${Workerindex}-work`}
                id={`${index}-${Workerindex}-work`}
                className="text-center align-middle"
                style={{width: "95px", height: "73px", background: color}}
            >
                {tempArray.tipo_turno && tempArray.id_ficha === selectPatient.id_ficha_paciente.id?
                <IconButton
                    className={classes.margin}
                    onClick={() => selectModeEditDateWorker(tempArray, RowWorker)}
                    style={{
                        backgroundColor: colorBtn,
                        color: indigo[50],
                        fontSize: "large",
                        padding: "4px 8px",
                    }}
                >
                    {tempArray.tipo_turno}
                </IconButton>:
                <IconButton
                    className={classes.margin}
                    onClick={() => handleSelectWorkerOther(row, RowWorker, rowWork)}
                    style={{padding: "0"}}
                >
                    <Info
                        style={{
                            // backgroundColor: indigo[50],
                            color: indigo[500],
                        }}
                        fontSize="large"
                    />
                </IconButton>
                }
            </TableCell>
        )
    }

    const ContentCell = (RowWorker, rowDay, index, Workerindex) => {
        const tempWorkOther = dictWorkOther[rowDay.value];
        const tempReservation = dictReservation[rowDay.value];
        const tempValueReservation = tempReservation ? tempReservation[RowWorker.id] : null;
        const tempAvailabilityList = dictAvailability[RowWorker.id]

        const tempDay = moment(rowDay.value).add(1, "hours")
        let tempAvailability = tempAvailabilityList?.filter((row) => {
            const tempEnd = moment(`${moment(row.fecha_hora_fin).format("YYYY-MM-DD")}T23:59:59`)
            const tempStart = moment(`${moment(row.fecha_hora_inicio).format("YYYY-MM-DD")}T00:00:00`)   
            return tempDay.isBetween(tempStart, tempEnd)
        })

        if (tempAvailability) tempAvailability = tempAvailability[0];

        if ((tempWorkOther || tempAvailability) && tempValueReservation){
            return  cellButtonBackgroundColor(tempWorkOther, tempValueReservation, RowWorker, tempAvailability, index, Workerindex)
        } else if (tempValueReservation) {
            return AddingButtonWork(tempValueReservation, RowWorker, index, Workerindex)
        } else if (tempWorkOther || tempAvailability) {
            return cellBackgroundColor(tempWorkOther, RowWorker, tempAvailability, index, Workerindex)
        }

    return <TableCell
        key={`${index}-${Workerindex}-work`}
        id={`${index}-${Workerindex}-work`}
        onClick={() => selectDateWorker(rowDay, RowWorker)}
        className="text-center align-middle"
        style={{width: "95px", height: "73px"}}
    >
        {" "}
    </TableCell>
    }

    const auxarrayWorkers = arrayWorkers.filter((rowWorker) => {
        const tempAvailabilityList = dictAvailability[rowWorker.id]
        if (Object.keys(dictFilter).length === 0 ) return true
        const checkList = Object.keys(dictFilter).map((rowDay) => {
            const tempFilter = dictFilter[rowDay].toUpperCase()
            const tempReservation = dictReservation[rowDay];
            if (tempAvailabilityList) {
                const checkAvailability = tempAvailabilityList.filter((rowList) => {
                    const tempStartDate = moment(rowList.fecha_hora_inicio).format("YYYY-MM-DD")
                    const tempEndDate = moment(rowList.fecha_hora_fin).format("YYYY-MM-DD")
                    return  moment(rowDay).isBetween(tempStartDate, tempEndDate, undefined, '[]') & (rowList.tipo_registro.toUpperCase() == tempFilter)
                })
                if (checkAvailability.length > 0) {
                    return true;
                }
            }
            if (tempReservation && tempReservation[rowWorker.id]) {
                const checkReservation = tempReservation[rowWorker.id].filter((rowList) => rowList.tipo_turno.toUpperCase() == tempFilter);
                if (checkReservation.length > 0) {
                    return true;
                }
            }
            return false
        })
        return checkList.some((rowCheck) => rowCheck)
    })

    const dailyFilter = (rowDay) => {
        return listSelectableFilterItems.filter((item) => {
            let tempListFilter = []
            const tempReservation = dictReservation[rowDay]
            if (tempReservation && !tempReservation[null]){
                Object.values(tempReservation).map((tempRow)=> {
                    const auxRows = tempRow.reduce((accum, obj) => ([
                        ...accum,
                        obj.tipo_turno?.toUpperCase()
                    ]), [])
                    if (auxRows.length > 0){
                        tempListFilter = [...tempListFilter, ...auxRows]
                    }
                })
            }
            if(dictAvailability){
                Object.values(dictAvailability).map((tempRow)=> {
                    const auxRows = tempRow.filter((rowList) => {
                        const tempStartDate = `${moment(rowList.fecha_hora_inicio).format("YYYY-MM-DD")}`
                        const tempEndDate = moment(rowList.fecha_hora_fin).format("YYYY-MM-DD")
                        return  moment(rowDay).isBetween(tempStartDate, tempEndDate, undefined, '[]')
                    }).reduce((accum, obj) => ([
                        ...accum,
                        obj.tipo_registro?.toUpperCase()
                    ]), [])

                    if (auxRows.length > 0){
                        tempListFilter = [...tempListFilter, ...auxRows]
                    }

                })
            }
            return tempListFilter.includes(item.value.toUpperCase())
        })
    }

    return (
        <Card style={{margin: "0 0 0 0"}}>
            <CardBody stats className={classes.cardFooter} style={{padding: "2rem 1rem", margin: "0"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <h5 style={{margin: "0 0 0px 95px"}}>
                        {`${dictMonth[parseInt(moment(selectDate).format("MM"))]} del ${moment(selectDate).format("YYYY")}`}
                    </h5>
                </Grid>
                <Grid item xs={12}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    style={{margin: "-35px 0 10px 0"}}
                >
                    <Grid item xs={6}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        {changeDate &&
                        <Button
                            round
                            color={"primary"}
                            size="sm"
                            style={{zIndex: 10}}
                            className={classes.marginRight}
                            onClick={() => handleNavigation(moment(selectDate).subtract(1, "month"))}
                        >
                            Anterior
                        </Button>
                        }
                        {changeDate &&
                        <Button
                            round
                            color={"primary"}
                            size="sm"
                            style={{zIndex: 10}}
                            className={classes.marginRight}
                            onClick={() => handleNavigation(moment())}
                        >

                            Hoy
                        </Button>
                        }
                        {changeDate &&
                        <Button
                            round
                            color={"primary"}
                            size="sm"
                            className={classes.marginRight}
                            style={{zIndex: 10}}
                            onClick={() => handleNavigation(moment(selectDate).add(1, "month"))}
                        >
                        Siguiente
                        </Button>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{margin: "0 0 10px 0"}}
                >
                    <Grid item xs={3}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <ButtonMUI
                            color="primary"
                            style={{
                                zIndex: 10,
                                backgroundColor: indigo[500],
                                color: indigo[50]
                            }}
                            onClick={handleDownloadData}
                        >
                        {"Descargar Info Vista"}
                        </ButtonMUI>
                    </Grid>
                    <Grid item xs={3}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <ButtonMUI
                            color="primary"
                            style={{
                                zIndex: 10,
                                backgroundColor: multipleSelectMode ? indigo[50] : indigo[500],
                                color: multipleSelectMode ? indigo[500] : indigo[50],
                            }}
                            onClick={() => handleSelectDeletedItem(!multipleSelectMode)}
                        >
                        {multipleSelectMode ?  "Salir de Selección multiple" : "Selección multiple"}
                        </ButtonMUI>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                >
                    <Grid item xs={2} >
                        <TableContainer style={{}}>
                            <Table size={'small'} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{width: "95px", height: "72px"}}
                                            className="text-center align-top" >TENS
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {auxarrayWorkers.map((RowWorker, index) => (
                                    <TableRow key={`${index}-workRows`}>
                                        <TableCell
                                            className="text-center align-middle"
                                            style={{width: "95px", height: "73px", cursor:"pointer"}}
                                            onClick={() => handleCheckPatientWorker(RowWorker)}
                                        >
                                            {`${RowWorker.first_name} ${RowWorker.last_name}`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={10} >
                        <TableContainer style={{}}>
                        <Table size={'small'} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                {dailyArray.map((row, index) =>(
                                    <Tooltip
                                        placement="top"
                                        title={
                                        <React.Fragment>
                                            <Grid
                                                container
                                                direction="column"
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <Typography variant="body2" gutterBottom>{`Filtrar por ${row.label}`}</Typography>
                                            </Grid>
                                        </React.Fragment>
                                        }
                                    >
                                        <TableCell
                                            key={`header-${index}`}
                                            className="text-center align-top"
                                            align={"flex-start"}
                                            style={{
                                                minWidth: "100px",
                                                background: row.colorHeader,
                                            }}
                                        >
                                            <Grid container direction="row" justify="center" alignItems="center" item xs={12}  style={{maxHeight: "85px"}}>
                                                <Grid container direction="row" justify="center" alignItems="center" item xs={12}>
                                                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>{row.label}</Typography>
                                                </Grid>
                                                <Grid container direction="row" justify="center" alignItems="center" item xs={12}>
                                                    <FormControl>
                                                        <Select
                                                            labelId="demo-customized-select-label"
                                                            id="demo-customized-select"
                                                            value={dictFilter[row.value] || ""}
                                                            onChange={(event) => handleChange(event.target.value, row.value)}
                                                            >
                                                            <MenuItem value=""><em>Sin filtro</em></MenuItem>
                                                            {dailyFilter(row.value).map((item, indexMenu) => (
                                                                <MenuItem   key={`header-${index}-${indexMenu}`}value={item.value}>{item.label}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </Tooltip>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {auxarrayWorkers.map((RowWorker, index) => (
                                <TableRow key={`${index}-workRows`}>
                                    {dailyArray.map((rowDay, Workerindex) =>(
                                        ContentCell(RowWorker, rowDay, index, Workerindex)
                                    ))}
                                </TableRow>
                            ))}
                            {changeButtons &&
                            <TableRow></TableRow>
                            }
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
  );
}