import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
    Close,
} from "@material-ui/icons/";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailNumber2 from "views/Widgets/FichaPaciente/detailNumber2";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {dictMonth, daysList as days} from "utils/validationFunction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import ExpansionPanelCommon from "views/Widgets/Common/ExpansionPanelCommon";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function DefineVisit({
    open,
    handleClose,
    selectPatient,
    scheduleVisit,
    selectDate,
    dictChargeId,
    dictReservation,
    dictPatient,
    dictPatientFileWorker,
    listPatient,
    dailyHeaderArray,
    handleSaveReservation,
    dictPatientTransient={}
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const data =scheduleVisit[0];
    const [initialCharge, setinitialCharge] = useState(true);
    const [patient, setpatient] = useState(listPatient[0])
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [startDate, setstartDate] = useState(moment());
    const [endDate, setendDate] = useState(moment().add(1, "days"));
    const [listHour, setlistHour] = useState([]);
    const [worker, setworker] = useState(scheduleVisit[1]);
    const [selectWorker, setselectWorker] = useState({});
    const [hourType, sethourType] = useState("normal");
    const [changeHour, setchangeHour] = useState(false);
    const [checkPatient, setcheckPatient] = useState(false);
    const [extraLunch, setextraLunch] = useState(false);
    const [shiftMobilization, setshiftMobilization] = useState(false);
    const [specialViatic, setspecialViatic] = useState(false);
    const [extraLunchAmount, setextraLunchAmount] = useState("0");
    const [shiftMobilizationAmount, setshiftMobilizationAmount] = useState("0");
    const [specialViaticAmount, setspecialViaticAmount] = useState("0");
    const [extraTurnValue, setextraTurnValue] = useState("0");
    const [comment, setcomment] = useState("");
    const [messageWarning, setmessageWarning] = useState("");
    const [messageWorkerWarning, setmessageWorkerWarning] = useState("");
    const [alertVetoe, setAlertVetoe] = useState(false);
    const [expandedPanel, setexpandedPanel] = useState(false);
    const [selectFrequency, setselectFrequency] = useState({});
    const [selectDays, setselectDays] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [numberDays, setnumberDays] = useState(1);
    const [numberDayMonth, setnumberDayMonth] = useState(1);
    const [consecutiveDays, setconsecutiveDays] = useState(1);
    const [pauseDays, setpauseDays] = useState(0);
    const [changeButtons, setchangeButtons] = useState(true);
    const listFrequency = [
        {value: "diario", label: "Diario"},
        {value: "semanal", label: "Semanal"},
        {value: "mensual", label: "Mensual"},
        {value: "otra", label: "Otra"}
    ];

    const dictTransient = dictPatientTransient[patient?.value] || {}

    console.log(dictTransient)

    let flagDiff=true;
    let flagFullDays=false;
    let flagCheckDay=true;
    if (dictTransient?.fecha_inicio_actividades){
      flagDiff = moment(dictTransient?.fecha_inicio_actividades).diff(selectDate, "minutes") <= 0
      flagFullDays = dictTransient?.dias_periodo <= dictTransient?.repeticiones;
    }

    if (flagFullDays){
      flagCheckDay = dictTransient?.fechas.includes(moment(selectDate).format("YYYY-MM-DD"))
    }

    let message = ""
    if (!flagDiff){
        message = `El paciente tiene como fecha de inicio de actividades: ${dictTransient?.fecha_inicio_actividades}`;
    } else if (!flagCheckDay){
        const stringDays = dictTransient?.fechas.join(", ")
        message = `El paciente ya ha completado todos los días del tratamiento: ${stringDays}. Si lo desea, puede seguir asignando profesionales para estos días`
    }

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changeHour){
            getTimeList();
        }
        if (checkPatient){
            getWarningMessage();
            initialVetoes();
        }
        if (changeButtons){
            setchangeButtons(false);
        }
    });

    const initialVetoes = () => {
        const getVetoes = api.get(
            `api/rrhh/variablesdinamicas/personal/${worker.id_ficha.id}/tipo/vetos/`
        );
        Promise.all([getVetoes])
        .then(response => {
            const checkVetoe = response[0].data.valores.filter(
            filtered =>
                filtered.info_veto.id_ficha_paciente_id.id_paciente_id.id ===
                patient.value
            );
            setAlertVetoe(checkVetoe.length > 0);

        })
    };

    const initialData = () => {
        setinitialCharge(false);
        if(selectPatient){
            const tempPatient = listPatient.filter((row) => row.value === selectPatient.id)
            if (tempPatient.length > 0){
                setpatient(tempPatient[0]);
            }
        }

        let tempStart = null
        let tempEnd = null

        if (scheduleVisit[2]){
            tempStart =  data.start
            tempEnd = moment(`${moment().format("YYYY-MM-DD")} ${data.start}:00`).add(1, "hours").format("HH:mm");
        } else {
            tempStart = moment(data.start).format("HH:mm")
            tempEnd = moment(data.end).format("HH:mm")

        }
        setstart({value: tempStart, label: tempStart});
        setend({value: tempEnd, label: tempEnd});
        setstartDate(moment())
        setendDate(moment().add(1, "days"));
        setselectWorker({value: worker.id_ficha.id, label: `(${worker.id_ficha.rut}) ${worker.id_ficha?.nombres} ${worker.id_ficha?.apellido_paterno} - ${dictChargeId[worker.id_ficha?.id_cargo_id]?.nombre_corto}]}`});

        setchangeHour(true);
        setcheckPatient(true);
    };

    const getWarningMessage = () => {
        setcheckPatient(false);
        const tempPatientFileId = dictPatient[patient.value];
        const tempPatientWork = dictPatientFileWorker[tempPatientFileId];
        const dateEvent = moment(selectDate).format("YYYY-MM-DD");
        const tempStartDate = moment(`${dateEvent} ${start.value}:00`)
        const tempEndDate = moment(`${dateEvent} ${end.value}:00`)
        const scheduleArray = []
        const schdeuleWorkerArray = []
        if(tempPatientWork){
            tempPatientWork.map((row) => {
                if (moment(row.fecha_hora).format("YYYY-MM-DD") === dateEvent){
                    if (
                        (moment(tempStartDate).diff(row.fecha_hora, "minutes") >= 0 &&
                        moment(row.fecha_final).diff(tempStartDate, "minutes") >= 0 ) ||
                        (moment(tempEndDate).diff(row.fecha_hora, "minutes") >= 0 &&
                        moment(row.fecha_final).diff(tempEndDate, "minutes") >= 0 )
                    ){
                        scheduleArray.push(`${moment(row.fecha_hora).format("HH:mm")} - ${moment(row.fecha_final).format("HH:mm")}`);
                    }
                }
            });
        }

        const arrayDateKey = Object.keys(dictReservation).filter((row) =>   {
            const dateKey = moment(row)
            if (dateKey.format("YYYY-MM-DD") === dateEvent){
                return moment(tempEndDate).diff(dateKey, "minutes") >= 0 && moment(dateKey).diff(tempStartDate, "minutes") <= 0
            }
            return false
        });
        arrayDateKey.map((row) => {
            const tempRow = dictReservation[row][selectWorker.value]
            if (tempRow){
                schdeuleWorkerArray.push(`${moment(tempRow.fecha_hora).format("HH:mm")} - ${moment(tempRow.fecha_final).format("HH:mm")}`)
            }
        })
        if (scheduleArray.length > 0 ){
            const tempScheduleString = scheduleArray.join(", ");
            setmessageWarning(`El paciente tiene una consulta agendada en el horario: ${tempScheduleString}`)
        } else {
            setmessageWarning("");
        }
        if (schdeuleWorkerArray.length > 0 ){
            const tempScheduleString = schdeuleWorkerArray.join(", ");
            setmessageWorkerWarning(`El Profesional tiene una consulta agendada en el horario: ${tempScheduleString}`)
        } else {
            setmessageWorkerWarning("");
        }
    };

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHour = [];

        let tempDate =  moment(selectDate).format("YYYY-MM-DD");
        let countDate = moment(tempDate);
        let tempDateEnd = moment(tempDate).add(24, "hours")

        let startDateLimit = moment(countDate);
        let endDateLimit = moment(tempDateEnd);

        let dateStart = "";
        let dateEnd = "";
        if (scheduleVisit[2]){
            dateStart = `${tempDate} ${data.start}:0`
            dateEnd = `${tempDate} ${data.end}:0`
        } else {
            dateStart = `${moment(data.start).format("YYYY-MM-DD HH:mm")}:00`
            dateEnd = `${moment(data.end).format("YYYY-MM-DD HH:mm")}:00`
        }

        Object.keys(dictReservation).map((key) => {
            if (dictReservation[key]){
                if (dictReservation[key][worker.id]){
                    const tempValue = dictReservation[key][worker.id];
                    let tempStart = null;
                    let tempEnd = null;
                    if (moment(dateStart).diff(tempValue.fecha_final, "minutes") >= 0 ){
                        tempStart = moment(tempValue.fecha_final);
                    } else if (moment(dateStart).diff(tempValue.fecha_hora, "minutes") >= 0 ){
                        tempStart = moment(tempValue.fecha_hora);
                    }
                    if (tempStart){
                        if (moment(tempStart).diff(startDateLimit, "minutes") >= 0 ){
                            startDateLimit = moment(tempStart);
                        }
                    }

                    if (moment(dateEnd).diff(tempValue.fecha_hora, "minutes") <= 0 ){
                        tempEnd = moment(tempValue.fecha_hora);
                    } else if (moment(dateEnd).diff(tempValue.fecha_final, "minutes") <= 0 ){
                        tempEnd = moment(tempValue.fecha_final);
                    }
                    if (tempEnd){
                        if (moment(tempEnd).diff(endDateLimit, "minutes") <= 0 ){
                            endDateLimit = moment(tempEnd);
                        }
                    }
                }
            }
        });

        while (moment(tempDateEnd).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHour.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHour);
        setcheckPatient(true);
    };

    const handleSelectHour= (value, argDate, set) => {
        const tempDate =  moment(selectDate).format("YYYY-MM-DD");
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        if (argDate === "start"){
            compareDate = moment(`${tempDate} ${end.value}:0`);
            if (moment(compareDate).diff(auxDate, "minutes") < 0 ){
                let auxEndTime = moment(auxDate).add(30, "minutes");
                auxEndTime = moment(auxEndTime).format("HH:mm");
                setend({value:auxEndTime, label: auxEndTime});
            }
        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            if (moment(auxDate).diff(compareDate, "minutes") < 0){
                let auxStartTime = moment(auxDate).subtract(30, "minutes");
                auxStartTime = moment(auxStartTime).format("HH:mm");
                setstart({value:auxStartTime, label: auxStartTime});
            }
        }
        const valueFormat = moment(auxDate).format("HH:mm")
        set({value:valueFormat, label: valueFormat});
        setcheckPatient(true);
    };

    const handlePatient = (value) => {
        setpatient(value);
        setcheckPatient(true);
    }

    const handleWorker = (value) => {
        const tempWorwer = value.item
        setworker(tempWorwer);
        setselectWorker(value);
        setcheckPatient(true);
    }

    const handleHourType = event => {
        sethourType(event.target.value);
        setextraTurnValue("0");
    };

    const handleAddItems = (value, set, setvalue) => {
        set(!value);
        setvalue("0");
    };

    const handleOnChange = (value, set) => {
        let tempValue = isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleSelectDay = (button) => {
        let tempArray = selectDays;
        if (selectDays.includes(button)) {
          tempArray = selectDays.filter((row) => row != button);
        } else {
          tempArray.push(button);
        }
        setselectDays(tempArray)
        setchangeButtons(true);
    };

    const handleFrequency = (value) => {
        setselectFrequency(value)
        if (value.value === "diario"){
            setendDate(moment(startDate).add(1, "days"));
        } else if (value.value === "semanal"){
            setendDate(moment().add(1, "weeks"));
        } else if (value.value === "mensual"){
            setendDate(moment(startDate).add(1, "months"));
        }
    };

    const handleCaculateRows = () => {
        let tempDateArray = [];
        if (expandedPanel){
            const tempEndDate = moment(endDate);
            let dateCondition = moment(startDate);
            if (selectFrequency.value === "diario"){
                let countDay = 0;
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (countDay% parseInt(numberDays) === 0){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    countDay += 1;
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "semanal"){
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (selectDays.includes(dateCondition.day())){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "mensual"){
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (parseInt(moment(dateCondition).format("DD")) === parseInt(numberDayMonth)){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "otra"){
                let checkConsecutiveDay = 0;
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    checkConsecutiveDay += 1;
                    dateCondition = dateCondition.add(1, "days");
                    if (checkConsecutiveDay >= parseInt(consecutiveDays)){
                        checkConsecutiveDay = 0
                        dateCondition = dateCondition.add(pauseDays, "days");
                    }
                }
            }
        } else {
            tempDateArray.push(moment(selectDate).format("YYYY-MM-DD"));
        }

        handleSaveReservation(
            patient,
            start,
            end,
            extraLunchAmount,
            shiftMobilizationAmount,
            specialViaticAmount,
            extraTurnValue,
            comment,
            hourType,
            tempDateArray,
            worker
        )
    };


    const _expandContent_ = <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        item
    >
        {changeButtons === false &&
            <div></div>
        }
        <DetailSelect
            label="Frecuencia:"
            value={selectFrequency}
            onchange={(value) => handleFrequency(value)}
            list={listFrequency}
        />
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Inicio"}
                placeholder="Fecha Inicio"
                onChange={setstartDate}
                maxDate={moment(endDate)}
                value={startDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Término"}
                placeholder="Fecha Término"
                minDate={moment(startDate)}
                onChange={setendDate}
                value={endDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        {selectFrequency.value === "diario" &&
        <DetailNumber2
            label={"Cada cuantos días"}
            value={numberDays}
            onchange={e => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
                setnumberDays(e.target.value);
            }
            }}
        />
        }
        {selectFrequency.value === "semanal" &&
        <Grid item xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            {days.map((row, index) => (
                <Button
                    key={`${index}-daybuttons`}
                    justIcon
                    round
                    size="sm"
                    style={{
                        background: selectDays.includes(row.value) ?  indigo[500] :  indigo[50],
                        color: selectDays.includes(row.value) ?  indigo[50] :  indigo[500],
                    }}
                    onClick={() => handleSelectDay(row.value)}
                >
                    {row.label}
                </Button>
            ))}
            {selectDays.length === 0 &&
                <Typography variant="caption" style={{color: red[500]}} gutterBottom>
                    A lo menos 1 día seleccionado
                </Typography>
            }
        </Grid>
        }
        {selectFrequency.value === "mensual" &&
        <DetailNumber2
            label={"Dia de cada mes"}
            value={numberDayMonth}
            max={"31"}
            onchange={e => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
                setnumberDayMonth(e.target.value);
            }
            }}
        />
        }
        {selectFrequency.value === "otra" &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Grid item xs={5} style={{margin: "10px 0"}} >
                <DetailNumber2
                    label={"Días seguidos"}
                    min={"1"}
                    value={consecutiveDays}
                    onchange={e => {
                        if (/^[0-9\b]+$/.test(e.target.value)) {
                            setconsecutiveDays(e.target.value);
                        }
                    }}
                />
            </Grid>
            <Grid  item xs={2} style={{margin: "10px 0"}} >
                <Button justIcon round color="primary">
                    <Close className={classes.icons} />
                </Button>
            </Grid>
            <Grid item xs={5} style={{margin: "10px 0"}}
            >
                <DetailNumber2
                    label={"Días de descanso"}
                    min={"1"}
                    value={pauseDays}
                    onchange={e => {
                        if (/^[0-9\b]+$/.test(e.target.value)) {
                            setpauseDays(e.target.value);
                        }
                    }}
                />
            </Grid>
        </Grid>
        }
    </Grid>

    const tempWorkerArray = dailyHeaderArray.reduce((accum, obj) => [
        ...accum,
        {
            value: obj.id,
            label: `(${obj.id_ficha.rut}) ${obj.id_ficha?.nombres} ${obj.id_ficha?.apellido_paterno} - ${dictChargeId[obj.id_ficha?.id_cargo_id]?.nombre_corto}]}`,
            item: {...obj}
        }
    ], []);

    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailSelect
                    label="Profesional:"
                    value={selectWorker}
                    onchange={(value) => handleWorker(value)}
                    list={tempWorkerArray}
                />
                <DetailSelect
                    label="Paciente:"
                    value={patient}
                    onchange={(value) => handlePatient(value)}
                    list={listPatient}
                />
                {message !== "" &&
                <SnackbarContent
                    message={message}
                    color="danger"
                />
                }
                <DetailSelect
                    label="Horario inicio:"
                    value={start}
                    onchange={(value) => handleSelectHour(value, "start", setstart)}
                    list={listHour.slice(0, listHour.length - 1)}
                />
                <DetailSelect
                    label="Horario fin:"
                    value={end}
                    onchange={(value) => handleSelectHour(value, "end", setend)}
                    list={listHour.slice(1, listHour.length)}
                />
                {alertVetoe && (
                <SnackbarContent
                    message={"No se puede guardar este registro, existe un veto para este profesional por parte de este paciente."}
                    color="danger"
                />
                )}
                {messageWarning != "" &&
                <SnackbarContent
                    message={messageWarning}
                    color="danger"
                />
                }
                {messageWorkerWarning != "" &&
                <SnackbarContent
                    message={messageWorkerWarning}
                    color="danger"
                />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Radio
                            checked={hourType === "normal"}
                            onChange={handleHourType}
                            value="normal"
                            name="radio button enabled"
                            aria-label="normal"
                            icon={
                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                            }
                            checkedIcon={
                                <RadioButtonChecked style={{fill: indigo[500]}}/>
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Considerar como atención normal"
                />
                <FormControlLabel
                    control={
                        <Radio
                            checked={hourType === "extra"}
                            onChange={handleHourType}
                            value="extra"
                            name="radio button enabled"
                            aria-label="extra"
                            icon={
                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                            }
                            checkedIcon={
                                <RadioButtonChecked style={{fill: indigo[500]}} />
                            }

                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Considerar como atención extra"
                />
                { hourType === "extra" &&
                    <DetailNumber
                        label={`Valor atención extra`}
                        value={extraTurnValue}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraTurnValue)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>
                {`¿Cuál de los siguientes items desea agregar?`}
                </Typography>

            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(extraLunch, setextraLunch, setextraLunchAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Colación atención extra"
                />
                { extraLunch &&
                    <DetailNumber
                        label={`Valor colación atención extra`}
                        value={extraLunchAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraLunchAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(shiftMobilization, setshiftMobilization, setshiftMobilizationAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Movilizacíon atención extra"
                />
                { shiftMobilization &&
                    <DetailNumber
                        label={`Valor movilizacíon atención extra`}
                        value={shiftMobilizationAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setshiftMobilizationAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(specialViatic, setspecialViatic, setspecialViaticAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Viatico especial"
                />
                { specialViatic &&
                    <DetailNumber
                        label={`Valor viatico especial`}
                        value={specialViaticAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setspecialViaticAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}

                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailText
                    label={`Comentario`}
                    placeholder={``}
                    value={comment}
                    validation={false}
                    onchange={(e) => { handleOnChange(e.target.value, setcomment)}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 0 0"}}
            >
                <ExpansionPanelCommon
                    title= "Definir recurrencia"
                    content={_expandContent_}
                    handleChange={(expand) => setexpandedPanel(expand)}
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        {alertVetoe || !selectWorker.value || message !== ""?
        <Button
            disabled
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button> :
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500], marginRight: "3px"}}
            onClick={handleCaculateRows}
        >
            Guardar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px", marginLeft: "3px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Definir visita - ${moment(selectDate).format("D")} de ${dictMonth[parseInt(moment(selectDate).format("MM"))]}`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}