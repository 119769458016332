import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Paper,
    IconButton,
    TextField,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import { Edit, DeleteForever } from "@material-ui/icons/";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import {firstChartUpperString, toMoneyFormat} from "utils/formatFunctions"
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalDeleteItemTable from "components/PatientFile/ModalDeleteItemTable";
import {setmessageGeneral } from "actions/getGlobalAction";
import {
    MuiPickersUtilsProvider,
    DateTimePicker
  } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        width: "100%"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    // avatar: {
    //   backgroundColor: red[500],
    // },
}));

const detectType = (value, key) => {
    if (key.toLowerCase().includes("fecha")) {
        return moment(value).format("YYYY-MM-DD")
    } else if (key.toLowerCase().includes("valor")) {
        return toMoneyFormat(value)
    } else if (typeof(value) === "number"){
        return value
    } else if ( typeof(value) === "boolean"){
        return value ? "Verdadero" : "Falseo"
    }
    return firstChartUpperString(value)
}

function CardObject({obj, title}){
    const classes = useStyles();

    const listKey = Object.keys(obj).map((key) => {
        let tempKeyName = key.split("_").join(" ")
        return {"key": key, "label": firstChartUpperString(tempKeyName)}
    })

    return (
    <Grid item container direction="row" justify="center" alignItems="center" xs={12} style={{margin: "10px 0"}}>
        <Card  className={classes.root} >
            <CardHeader title={`${title} - Información`} />
            <CardContent>
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={12}>
                {listKey.map((key) => (
                    <Grid item container direction="row" justify="flex-start" alignItems="flex-start"  xs={6}>
                        <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={6}>
                            <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}}>
                                {`${key["label"]}:`}
                            </Typography>
                        </Grid>
                        <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={6}>
                            <Typography component="body2" variant="body2">
                                {detectType(obj[key["key"]] || "-", key["key"])}
                            </Typography>

                        </Grid>
                    </Grid>
                ))}
            </Grid>
            </CardContent>
        </Card>
    </Grid>
    )
}

function TableList({list, title, listDeleteItem, dictEditItem, handleEdit, handleDelete, editable}){
    const classes = useStyles();

    const headers = Object.keys(list[0]).map((key) => {
        if ( key == "id") return {label: "ID"}
        let tempKeyName = key
        if (key.includes("id_")){
            tempKeyName = key.split("id_").join(" ")
        }
        tempKeyName = tempKeyName.split("_").join(" ")

        return {label: firstChartUpperString(tempKeyName)}
    })
    headers.push({eval: false, label: "Acción"})

    const data = list.filter((row) => !listDeleteItem.includes(row.id)).map((row) => {
        const valueEdit = dictEditItem[row.id]
        const edit = valueEdit && valueEdit["editado"] === false
        const listData = Object.keys(row).map((key) => {
            const tempRow =  {value: row[key] || "-"}
            if (edit && key === "cantidad" &&  valueEdit && !valueEdit["editado"]){
                tempRow["_value_"] = <TextField
                    variant="outlined"
                    type="number"
                    size={"small"}
                    style={{ width: "100%" }}
                    inputProps={{ min: "1" }}
                    label={"Cantidad"}
                    value={valueEdit["cantidad"]}
                    onChange={(e) => handleEdit(row, e.target.value)}
                />
                tempRow["value"] = valueEdit["cantidad"]
            } else if (valueEdit &&  key === "cantidad" && valueEdit["editado"]) {
                tempRow["value"] = valueEdit["cantidad"]
            }
            return tempRow
        } )

        const _editButton_ = (!edit && editable) ? <IconButton variant={"outlined"} style={{ background: orange[500], padding: "5px"}} onClick={() => handleEdit(row, null, false)} >
            <CreateIcon style={{color: '#FFFFFF'}} />
        </IconButton> : null
        const _checkEditButton = (edit && editable) ? <IconButton variant={"outlined"} style={{ background: indigo[500], padding: "5px"}} onClick={() => handleEdit(row, null, true)} >
            <CheckIcon style={{color: '#FFFFFF'}} />
      </IconButton> : null
        const _cancelEditButton_ = (edit && editable) ? <IconButton variant={"outlined"} style={{ background: indigo[500], padding: "5px"}} onClick={() => handleEdit(row)} >
            <ClearIcon style={{color: '#FFFFFF'}} />
        </IconButton> : null
        const _deleteButton_ = editable ?<IconButton variant={"outlined"} style={{ background: red[500], padding: "5px" }} onClick={() => handleDelete(row)} >
            <DeleteForever style={{color: '#FFFFFF'}} />
        </IconButton> : null

        const _buttons_ = <Grid item container alignItems="center" justify="center" > {_editButton_}{_checkEditButton}{_cancelEditButton_}{_deleteButton_}</Grid>

        listData.push({_value_:_buttons_})

        return listData
    })


    return (
        <Grid item container direction="row" justify="center" alignItems="center" xs={12}  style={{margin: "10px 0"}}>
            <Card  className={classes.root} >
            <CardHeader title={`${title} - Detalle`} />
            <CardContent>
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={12}>
            <TableComponent
                headers={headers}
                data={data}
            />
            </Grid>
            </CardContent>
        </Card>

        </Grid>
    )
}

const CardItem = ({title, content, textAction, action}) => <Grid item container xs={4}>
    <Paper style={{ padding: "1em",  marginTop: "2em 0 0 1em", backgroundColor: "#fff", borderRadius: "5px"}}>
        <Grid item container xs={12}  direction="row" alignContent="flex-start">
            <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
                <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
                {title}
                </Typography>
            </Grid>
            <Grid item container xs={12}>
                <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 32px 0" }}>
                    {content}
                </Typography>
                <Button variant="contained" color="primary" style={{ width: "313px", height: "36px"}} onClick={action}>{textAction}</Button>
            </Grid>
        </Grid>
    </Paper>
</Grid>

export default function ModalProgressExtra({
    open,
    closeModal,
    rowInfo,
    setinitialCharge,
}) {
    const dispatch = useDispatch();
    const [listItem, setlistItem] = useState([]);
    const [listFile, setlistFile] = useState([]);
    const [openDeleteModal, setopenDeleteModal] = useState(null);
    const [listDeleteItem, setlistDeleteItem] = useState([]);
    const [dictEditItem, setdictEditItem] = useState({});
    const [observation, setobservation] = useState("");
    const [oldObservation, setoldObservation] = useState("");
    const [reasonRejected, setreasonRejected] = useState("");
    const [openModalReject, setopenModalReject] = useState(false);
    const [openModalAccept, setopenModalAccept] = useState(false);
    const [requestDate, setrequestDate] = useState("");
    const [isEmergency, setisEmergency] = useState({});

    const limitObservation  = 400;
    const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PACIENTE);

    const endStateArray = [
        "Completado",
        "Realizada",
        "RealizadaAtraso",
        "NoRealizada",
        "CanceladaPaciente",
        "Cancelada",
        "Finalizado"
    ];

    

    useEffect(() => {
        api.get(`api/clinica/solicitudes/extras/${rowInfo.id}`).then((response) => {
            let tempItems = response.data.item
            tempItems = tempItems.filter((row) => (row?.objeto?.length > 0 || row?.sin_objeto === true))
            tempItems?.map((row) => {
                row.objeto.map((subrow) => {
                    if (subrow.tipo_solicitud == "Urgente" || subrow.tipo_solicitud == "Adicional") {
                        setoldObservation(subrow.observacion)
                        setrequestDate(subrow.fecha_tentativa_llegada)
                        if (subrow.tipo_solicitud == "Adicional") {
                            setisEmergency({value: false, label: "No"})
                        } else if (subrow.tipo_solicitud == "Urgente"){
                            setisEmergency({value: true, label: "Sí"})
                        }
                    }
                })
            })
            setlistItem(tempItems)
        })
        api.get(`api/documentospaciente/?tarea_paciente=${rowInfo.id}`).then(response => {
            setlistFile(response.data)
        });

      
    
    }, []);

    const handleNextStep = (state) => {
        const body = {
            estado: state,
            observacion_rechazo: reasonRejected,
            actualizar_observacion: `${observation}`,
            lista_eliminar: listDeleteItem,
            lista_actualizar: Object.values(dictEditItem).map((row) => {return {id: row.id, cantidad: row.cantidad}}),

        }
        if (isEmergency.value !== undefined){
            body["es_urgente"] = isEmergency.value
            body["fecha_entrega"] = moment(requestDate).format("YYYY-MM-DDTHH:mm:ss")

        }
        api.patch(`api/clinica/solicitudes/extras/${rowInfo.id}`, body ).then((response) => {
            setmessageGeneral(dispatch, response.data.mensaje, "success", "¡Exito!");
            setinitialCharge(true)
            closeModal()
        })
    }

    const handleDownloadFile = (link) => {
        window.open(link)
    }

    const handleDeleteRow = () => {
        const tempList = [...listDeleteItem ]
        tempList.push(openDeleteModal.id)
        setlistDeleteItem(tempList)
        setopenDeleteModal(null)
    }

    const handleEditRow = (row, value=null, status=null) => {
        const tempRow = {...dictEditItem}
        if (tempRow[row.id] && status !== null){
            tempRow[row.id]["editado"] = status
        } else if (tempRow[row.id] && value){
            tempRow[row.id]["cantidad"] = value
        } else if (tempRow[row.id]){
            delete tempRow[row.id]
        } else {
            tempRow[row.id] = {"id": row.id, "cantidad": row.cantidad, "editado": false}
        }
        setdictEditItem(tempRow)
    }

    const patientData = rowInfo.id_ficha.id_paciente

    const patientName = `${patientData.nombre} ${patientData.apellido_paterno} ${patientData.apellido_materno}`

    let nameUserRequest = ""
    let coordinatorName = ""
    const userRequest = rowInfo?.id_solicitud_paciente?.id_persona_solicitante
    const coordinadorStatus = rowInfo?.id_solicitud_paciente?.id_coordinador_autoriza

    if (userRequest){
        nameUserRequest = `${userRequest["first_name"]} ${userRequest["last_name"]}`
    }
    if (coordinadorStatus){
        coordinatorName = `${coordinadorStatus["first_name"]} ${coordinadorStatus["last_name"]}`
    }

    const _requestInfoBox_ = <div className="col-xl-8 mb-3">
        <div className="card">
        <div className="card-header">
            <div className="row">
            <div className="col" />
                <div className="col-12 text-center">Datos Solicitud Paciente</div>
            </div>
        </div>
        <div className="card-body">
            <div className="row">
            <div className="col-sm text-left ml-5">
                <span className="font-weight-bold">iD tarea: </span>
                {rowInfo.id}
                <br />
                <span className="font-weight-bold">Paciente: </span>
                {patientName}
            </div>

            <div className="col-sm text-left">
                <span className="font-weight-bold">ID solicitud paciente: </span>
                {rowInfo.id_solicitud_paciente.id}
                <br />
                <span className="font-weight-bold">Tipo de solicitud: </span>
                {dictTypeRequest[rowInfo?.id_solicitud_paciente?.Tipo_Solicitud_Paciente]?.label}
                <br />
            </div>
            <div className="col-sm text-left">
                <span className="font-weight-bold">Solicitante: </span>
                {nameUserRequest}
                <br />
                <span className="font-weight-bold">coordinador: </span>
                {coordinatorName}
                <br />
            </div>
            </div>
            <div className="row">
            <div className="col-sm text-center" style={{padding: "20px"}}>
            </div>
            </div>
        </div>
        </div>
    </div>

    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            {_requestInfoBox_}
        </Grid>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            {listItem.map((row) => (
                <div style={{width: "100%"}}>
                    {row.objeto.map((obj) => (
                        <CardObject obj={obj} title={row.titulo}/>
                    ))
                    }
                    {row.lista_insumo.length > 0 &&
                        <TableList
                            list={row.lista_insumo}
                            title={row.titulo}
                            handleDelete={setopenDeleteModal}
                            handleEdit={handleEditRow}
                            listDeleteItem={listDeleteItem}
                            dictEditItem={dictEditItem}
                            editable={rowInfo.estado !=="Finalizado"}
                        />
                    }
                </div>
            ))}

            {listFile.map((row) => (
                <CardItem title={`Archivo: ${row.tipo_documento} - Area: ${row.area}`}
                    content={`${row.observacion}`}
                    textAction={`Descargar ${row.nombre_archivo}`} action={() => handleDownloadFile(row.archivo)}
                />
            ))}



        </Grid>
        {openDeleteModal && (
        <ModalDeleteItemTable
            open={!!openDeleteModal}
            handleClose={() => setopenDeleteModal(null)}
            handleDelete={handleDeleteRow}
            title={`Eliminar ${openDeleteModal.id_equipo__SKU || openDeleteModal.id_insumo_medicamento__SKU || openDeleteModal.id_cargo_asignado__nombre_corto}`}
        />)}
    </div>

    const _actions_ = <>
        {!endStateArray.includes(rowInfo.estado) &&
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={() =>setopenModalAccept(true)}
        >
            Aceptar
        </Button>
        }
        {!endStateArray.includes(rowInfo.estado) &&
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={() => setopenModalReject(true)}
        >
            Rechazar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={closeModal}
        >
            Cerrar
        </Button>
    </>

    const _contentAccept_ = <div >
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
        >
        <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} >  ¿Está seguro de que desea aprobar la solicitud del paciente?</Typography>
        </Grid>
        {(rowInfo?.id_solicitud_paciente?.Tipo_Solicitud_Paciente == "InsumosExtra") && 
        <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <TextField
                label={"Agregar Observación"}
                multiline
                rows={8}
                value={observation}
                variant="outlined"
                onChange={(event) => setobservation(event.target.value)}
                style={{width: "100%"}}
            />
        </Grid>
        }
        {(rowInfo?.id_solicitud_paciente?.Tipo_Solicitud_Paciente == "InsumosExtra") &&
        <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
            {`${observation?.length || 0 }/${limitObservation}`}
        </Typography>
        }
        {(rowInfo?.id_solicitud_paciente?.Tipo_Solicitud_Paciente == "InsumosExtra") && 
        <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} > ¿Es urgente?</Typography>
        </Grid>
        }
        {(rowInfo?.id_solicitud_paciente?.Tipo_Solicitud_Paciente == "InsumosExtra") && 
        <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{padding: "5px 0"}}
        >
            <DetailSelect
                value={isEmergency}
                onchange={value => {setisEmergency(value)}}

                list={[{value: false, label: "No"}, {value: true, label: "Sí"}]}
            />
        </Grid>
        }
        {(rowInfo?.id_solicitud_paciente?.Tipo_Solicitud_Paciente == "InsumosExtra" ) && 

        <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{padding: "5px 0"}}
        >
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    variant="inline"
                    format="DD/MM/YYYY HH:mm"
                    margin="normal"
                    label={"Fecha entrega en domiciolio"}
                    placeholder=""
                    minDate={moment()}
                    minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
                    cancelLabel={"Cancelar"}
                    okLabel={"Aceptar"}
                    onChange={(value) => setrequestDate(value)}
                    value={requestDate}
                    inputVariant={"outlined"}
                    invalidDateMessage={"Debe ingresar fecha de entrega en domicilio"}
                />
            </MuiPickersUtilsProvider>
        </Grid>
        }
        </Grid>
    </div>

    const _actionsAccept_ = <>
        {!endStateArray.includes(rowInfo.estado) &&
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={() =>  handleNextStep("Aceptar")}
        >
            Aceptar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={() => setopenModalReject(false)}
        >
            Cerrar
        </Button>
    </>

    const _contentReject_ = <div >
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
        >
        <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <TextField
                label={"Motivo rechazo"}
                multiline
                rows={8}
                value={reasonRejected}
                variant="outlined"
                onChange={(event) => setreasonRejected(event.target.value)}
                style={{width: "100%"}}
            />
        </Grid>
        <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
            {`${reasonRejected?.length || 0 }/${limitObservation}`}
        </Typography>
        </Grid>
    </div>

    const _actionsReject_ = <>
        {!endStateArray.includes(rowInfo.estado) &&
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={() => handleNextStep("Rechazar")}
        >
            Rechazar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={() => setopenModalReject(false)}
        >
            Cerrar
        </Button>
    </>

    return (
        <>
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Resumen Solicitud`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
        />
        {openModalReject &&
        <ModalDialog
            open={openModalReject}
            onClose={() => setopenModalReject(false)}
            title={`Rechazo`}
            _content_={_contentReject_}
            _actions_ = {_actionsReject_}
            maxWidth="sm"
        />
        }
        {openModalAccept &&
            <ModalDialog
                open={openModalAccept}
                onClose={() => setopenModalAccept(false)}
                title={`Aceptar`}
                _content_={_contentAccept_}
                _actions_ = {_actionsAccept_}
                maxWidth="sm"
            />
        }
        </>

    );
}