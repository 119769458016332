import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  Divider,
  Button,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import BreadScrumbs from 'views/Widgets/Common/BreadScrumbs';
import {green, indigo} from "@material-ui/core/colors";
import { DoneAll, Info, CheckCircle, Edit } from "@material-ui/icons/";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  Root,
  CustomRadio,
  TypographyMui as Typography,
} from "utils/componentsStyle"
import CardStatistics from "views/Widgets/FichaPaciente/CardStatistics";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import QuotationTabs from "./QuotationTabs";
import HomeAuthorization from "./HomeAuthorization";
import DocumentQuotation from "./DocumentQuotation";
import ModalEditPackageItemAmount from "./ModalEditPackageItemAmount";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import api from "utils/API";
import allActions from 'actions';
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import {
  setdictEquipRelSpecific,
  setDiscountQuotation,
} from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default function ViewQuotation({
  dataPatient,
  viewQuotation,
  setopenModalQuotation,
  setselectPatient,
  setviewQuotation,
  settypeRequest,
  handleReloadFile,
}) {

  const dispatch = useDispatch();
  const [resourceTotal, setresourceTotal] = useState(0);
  const [medicineTotal, setmedicineTotal] = useState(0);
  const [dayBed, setdayBed] = useState(0);
  const [listProfessional, setlistProfessional] = useState([]);
  const [listEquip, setlistEquip] = useState([]);
  const [listEmergency, setlistEmergency] = useState([]);
  const [listOther, setlistOther] = useState([]);
  const [equipData, setequipData] = useState({});
  const [workersData, setworkersData] = useState({});
  const [emergencyData, setemergencyData] = useState({});
  const [otherData, setotherData] = useState({});
  const [initialCharge, setinitialCharge] = useState(true);
  const [resumeQuotation, setresumeQuotation] = useState(null);
  const [forecast, setforecast] = useState(null);
  const [loading, setloading] = useState(false);
  const [typeDiscount, settypeDiscount] = useState(null);
  const [valuePercentage, setvaluePercentage] = useState(0);
  const [editableDiscount, seteditableDiscount] = useState(false);
  const [openModalEditPackageItem, setopenModalEditPackageItem] = useState(false);
  const [infoRow, setinfoRow] = useState(null);
  const [packageBalanced, setpackageBalanced] = useState(null);
  const [packageUnbalanced, setpackageUnbalanced] = useState(null);
  const [selectedPackage, setselectedPackage] = useState(null);
  const [updateValues, setupdateValues] = useState(false);
  const [initialChargePackages, setinitialChargePackages] = useState(true);
  const [daysPeriod, setdaysPeriod] = useState(null)
  const [editablePeriod, seteditablePeriod] = useState(false)

  const ammountResourcePackage = useSelector(state => state.currentGlobalVar.ammountResourcePackage);
  const ammountMedicinePackage = useSelector(state => state.currentGlobalVar.ammountMedicinePackage);
  const amountProfesionalPAckage = useSelector(state => state.currentGlobalVar.amountProfesionalPAckage);

  const medicalResourseBase =  ammountResourcePackage + ammountMedicinePackage

  const dictEquipGeneric = useSelector(state => state.currentList.dictEquipGeneric);
  const listMedical = useSelector(state => state.currentList.listMedicines);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const listForecast = useSelector(state => state.currentList.listForecast);

  const medicalResourseTotal = resourceTotal + medicineTotal;
  const exemptTotal = dayBed*(daysPeriod || 30);
  const monthTotal = exemptTotal + medicalResourseTotal;

  useEffect(() => {
    if (packageBalanced?.tipo_permanencia == "Transitorio"){
      setdayBed(parseInt(amountProfesionalPAckage/(daysPeriod || 30)))
    }
  }, [amountProfesionalPAckage])


  useEffect(() => {
    if(listMedical.length === 0) {
      getListSuppliesMedicines(dispatch, chargeMedicine);
    }
    if (Object.keys(dictEquipGeneric).length === 0){
    setdictEquipRelSpecific(dispatch);
    }
    setDiscountQuotation(0,null,dispatch);
  }, []);

  useEffect(() => {
    if (packageBalanced?.tipo_permanencia == "Transitorio" && daysPeriod == null){
      setdaysPeriod(packageBalanced?.dias_periodo || 30)
    }
  }, [packageBalanced]);

  useEffect(() => {
    if (initialCharge && Object.keys(medicalResourceData).length > 0 && listForecast.length > 0) {
      initialData();
    }

    if (initialChargePackages && loading && packageBalanced){
      chargeDataPackage(packageUnbalanced);
      chargeDataPackage(packageBalanced);
      setinitialChargePackages(false);
    }
    if ( packageBalanced && !selectedPackage && !initialChargePackages){
      processPackage(packageBalanced);
    }
    if (updateValues){
      updatePackageSelected();
    }
  });

  const chargeDataPackage = (packageData) => {
    const tempPackage = {...packageData}
    
    if (packageData?.prestaciones){
      const tempDictItem = {}
      const packageItem = packageData.prestaciones.sort((a,b) => {
        if (a.id_prestacion_cotizacion_padre_id){
          return -1
        }
        if (b.id_prestacion_cotizacion_padre_id)
        if (a.fuera_paquete){
          return 1;
        } else if(b.fuera_paquete){
          return -1;
        }
        return 0;
      }).map((item) => {
        const tempRowPrest = workersData[item.id_prestaciones_id] || emergencyData[item.id_prestaciones_id] || otherData[item.id_prestaciones_id];
        const tempRowSource = medicalResourceData[item.id_insumo_medicamento_id];
        const tempRowEquip = equipData[item.id_equipo_id];

        let tempItem = {...item}

        if ( tempRowPrest?.grupo === "Profesionales") {
          
          let tempValor = tempItem.valor;
          const schedule = tempValor.horario_inhabil == true ?  "inhabil" : "habil"
          const key_value = `${tempItem.id_prestaciones_id}_${schedule}`
          const default_key =`${tempItem.id_prestaciones_id}_habil`
          tempValor = packageData.prestaciones_diferenciadas[key_value]?.valor ||  packageData.prestaciones_diferenciadas[default_key]?.valor || tempItem.valor || 0;
          tempItem = {...tempItem, valor: tempValor, id_prestaciones: tempRowPrest}
          if (packageData?.tipo_permanencia == "Transitorio"){
            tempItem = {...tempItem, horario_inhabil: tempItem?.horario_inhabil}
          }
         
        } else if ( tempRowPrest?.grupo === "Emergencias" || tempRowPrest?.grupo === "Traslados" || tempRowPrest?.grupo === "Otros" ) {
          tempItem = {...tempItem, id_prestaciones: tempRowPrest}
        }

        if (["Insumos", "Articulos", "Alimentos", "Medicamentos"].includes(tempRowSource?.grupo_producto)) {
          tempItem = {...tempItem, id_insumo_medicamento: tempRowSource}
        }

        if ( tempRowEquip?.tipo_producto === "EquiposMedicos") {
          let tempValor = tempItem.valor
          if(!tempItem.valor){
            tempValor = tempRowEquip.valor_boleta || 0;
          }
          if (tempItem.id_prestacion_cotizacion_padre_id){
            if (tempDictItem[tempItem.id_prestacion_cotizacion_padre_id]){
              tempDictItem[tempItem.id_prestacion_cotizacion_padre_id].push({...tempItem, id_equipo: tempRowEquip })
            } else {
              tempDictItem[tempItem.id_prestacion_cotizacion_padre_id] = ([{...tempItem, sku_especifico: tempItem.sku_especifico || "", id_equipo: tempRowEquip }])
            }
          } else{
            const items = tempDictItem[tempItem.id] ? tempDictItem[tempItem.id] : [];
            tempItem = {
              ...tempItem,
              valor: tempValor,
              sku_especifico: tempItem.sku_especifico || " ",
              id_equipo: tempRowEquip,
              items: items
            }
          }
        }
        return tempItem;
      });

      tempPackage.prestaciones = packageItem;
      if (tempPackage.descompensacion){
        setpackageUnbalanced({...tempPackage});
      } else {
        setpackageBalanced({...tempPackage});
      }
    }
    return tempPackage
  }

  const updatePackageSelected = (packageData=null) => {
    setupdateValues(false);
    const tempPackage = {...packageData ? packageData : selectedPackage}
    tempPackage.tipo_porcentaje = typeDiscount;
    tempPackage.porcentaje_item = valuePercentage;
    tempPackage.dia_cama = dayBed;
    tempPackage.insumos = resourceTotal;
    tempPackage.medicamentos = medicineTotal;
    if (packageData?.tipo_permanencia == "Transitorio"){
      tempPackage.dias_periodo = daysPeriod
    }
    if (tempPackage.descompensacion){
      setpackageUnbalanced({...tempPackage});
    } else {
      setpackageBalanced({...tempPackage});
    }
  };

  const processPackage = (packageArg=null) => {
    let packageData = {};
    if(packageArg){
      packageData = {...packageArg};
    } else if (selectedPackage?.descompensacion){
      packageData = {...packageBalanced};
    } else {
      packageData = {...packageUnbalanced};
    }

    const packageItem = packageData.prestaciones;

    const tempForecast = listForecast.filter(
      (row) => row.value === packageData.id_convenio_isapre_id
    );

    let tempTotal = 0;
    packageItem.filter((row) => row.id_insumo_medicamento ).map((row) => {
        tempTotal += row.cantidad * row.valor;
    });
    dispatch(allActions.setGlobalVar.setammountValuePackage(tempTotal));
    setforecast(tempForecast.length > 0 ? tempForecast[0] : null);

    const tempValuePercentage = packageData.porcentaje_item || 0;
    const tempTypeDiscount = packageData.tipo_porcentaje || null;
    settypeDiscount(tempTypeDiscount);
    setvaluePercentage(tempValuePercentage);
    setselectedPackage(packageData);
    setresourceTotal(packageData.insumos || 0);
    setmedicineTotal(packageData.medicamentos || 0);
    setdayBed(packageData.dia_cama || 0);

    setDiscountQuotation(tempValuePercentage, tempTypeDiscount, dispatch);
  }

  const initialData = () => {
    setinitialCharge(false);
    dispatch(allActions.setGlobalVar.setammountValuePackage(0));

    api.get(`/api/clinica/cotizacion/completa/?solicitud=${viewQuotation.requestPatientInfo.id}`).then((response) => {
      let tempPackageUnbalanced = response.data.filter((item) => (item.descompensacion))
      let tempPackageBalanced = response.data.filter((item) => (!item.descompensacion))

      tempPackageUnbalanced = tempPackageUnbalanced[0];
      tempPackageBalanced = tempPackageBalanced[0];

      let tempDiscount = {};

      [tempPackageUnbalanced, tempPackageBalanced].filter((row) => (row?.id)).forEach((row) =>{
        const tempDict = row.descuentos.reduce((accum,obj) => ({
          ...accum,
          [
            obj.id_prestaciones ? `${obj.id_prestaciones}_prestacion_${obj.fuera_paquete}`:
            obj.id_insumo_medicamento ? `${obj.id_insumo_medicamento}_insumos_medicamentos_${obj.fuera_paquete}`:
            obj.id_equipo ? `${obj.id_equipo}_equipo_${obj.fuera_paquete}` : null
          ]: obj
        }), {});
        tempDiscount = {...tempDiscount, ...tempDict}
      });

      dispatch({
        type: "DICCIONARIO_DESCUENTOS_COTIZACION",
        payload: tempDiscount
      });
      setpackageUnbalanced(tempPackageUnbalanced);
      setpackageBalanced(tempPackageBalanced);
    });

    const requestCharges = api.get(`api/prestacionesclinica/`);
    const requestEquipo = api.get(`api/equipos/tipos/`);
    Promise.all([
      requestCharges,
      requestEquipo,
    ]).then((request) => {
      const auxCharges = request[0].data;
      const auxEquipList = [];
      const auxProfessionalList = [];
      const auxEmergencyList = [];
      const auxOtherList = []
      const auxWorkersData = {};
      const auxEquipData = {};
      const auxEmergencyData = {};
      const auxOtherData = {};

      auxCharges.map((row) => {
        if (row.grupo === "Profesionales") {
          auxProfessionalList.push({ value: row.id, label: `${row.nombre}` });
          auxWorkersData[row.id] = row;
        }
        if (row.grupo === "Emergencias") {
          auxEmergencyList.push({ value: row.id, label: `${row.nombre}` });
          auxEmergencyData[row.id] = row;
        }
        if (row.grupo === "Traslados" || row.grupo === "Otros") {
          auxOtherList.push({ value: row.id, label: `${row.nombre}` });
          auxOtherData[row.id] = row;
        }
      });

      request[1].data.map((row) => {
        auxEquipList.push({
          value: row.id,
          label: `(${row.SKU}) ${row.descripcion}`,
        });
        auxEquipData[row.id] = row;
      });

      setlistEquip(auxEquipList);
      setlistEmergency(auxEmergencyList);
      setlistOther(auxOtherList)
      setlistProfessional(auxProfessionalList);

      setworkersData(auxWorkersData);
      setequipData(auxEquipData);
      setemergencyData(auxEmergencyData);
      setotherData(auxOtherData);
      setloading(true);
    });
  };

  const handleBack = (row) => {
    row.map((auxRow) => {
      auxRow(null);
    });
  };

  const updatePackage = () => {
    setloading(false);
    setpackageUnbalanced(null);
    setpackageBalanced(null);
    setselectedPackage(null);
    
    api.get(`/api/clinica/cotizacion/completa/?solicitud=${viewQuotation.requestPatientInfo.id}`).then((response) => {
      let tempPackageUnbalanced = response.data.filter((item) => (item.descompensacion))
      let tempPackageBalanced = response.data.filter((item) => (!item.descompensacion))

      tempPackageUnbalanced = tempPackageUnbalanced[0];
      tempPackageBalanced = tempPackageBalanced[0];

      let tempDiscount = {};

      [tempPackageUnbalanced, tempPackageBalanced].filter((row) => (row?.id)).forEach((row) =>{
        const tempDict = row.descuentos.reduce((accum,obj) => ({
          ...accum,
          [
            obj.id_prestaciones ? `${obj.id_prestaciones}_prestacion_${obj.fuera_paquete}`:
            obj.id_insumo_medicamento ? `${obj.id_insumo_medicamento}_insumos_medicamentos_${obj.fuera_paquete}`:
            obj.id_equipo ? `${obj.id_equipo}_equipo_${obj.fuera_paquete}` : null
          ]: obj
        }), {});
        tempDiscount = {...tempDiscount, ...tempDict}
      });

      dispatch({
        type: "DICCIONARIO_DESCUENTOS_COTIZACION",
        payload: tempDiscount
      });

      chargeDataPackage(tempPackageUnbalanced);
      const tempSelectPackage = chargeDataPackage(tempPackageBalanced);
      processPackage(tempSelectPackage);
      setloading(true);

    });
  };

  const updateStock = () => {
    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine)
    })
  };

  const handleApplyDiscount = () => {
    // TODO: revisar
    seteditableDiscount(false);
    setDiscountQuotation(valuePercentage, typeDiscount, dispatch);
    setupdateValues(true);
  };

  const handleOpenModalEditPackage = (info) => {
    setinfoRow(info);
    setopenModalEditPackageItem(true);
  };

  const handleSaveInfo = (info) => {
    info.map((row) => {
      row.set(row.tempValue);
    });
    setopenModalEditPackageItem(false);
    setinfoRow(null);
    setupdateValues(true);
  };

  const handleSwitch = () => {
    processPackage();
  }

  const validateAmountMedicalResourse = (["ParticularServicios", "ParticularHD"].includes(packageBalanced?.tipo_paciente) )
  // packageBalanced?.id_convenio_isapre?.nombre == "Fondo Nacional de Salud")


  const listCard = [
    {editable: true, onClickEdit: () => handleOpenModalEditPackage([{value: dayBed, tempValue:dayBed, set: setdayBed, label: "Día cama"}]), amountTotal:dayBed, title:"Día cama", subTitle:"Recursos asignados", IconTitle:Info, IconSub:Info},
    {editable: false, amountTotal:exemptTotal, title:"Total exento", subTitle:"Recursos asignados", IconTitle:Info, IconSub:Info},
    {editable: false, amountTotal:monthTotal, title:"Total mes", subTitle:"Recursos asignados", IconTitle:Info, IconSub:Info},
    {
      editable: validateAmountMedicalResourse  ? false : true,
      onClickEdit: () => handleOpenModalEditPackage([{value: resourceTotal, tempValue:resourceTotal, set: setresourceTotal, label: "Insumos"}, {value: medicineTotal, tempValue:medicineTotal, set: setmedicineTotal, label: "Medicamentos"}]),
      amountTotal: validateAmountMedicalResourse ? medicalResourseBase : medicalResourseTotal,
      amountBase: validateAmountMedicalResourse ? 0 : medicalResourseBase,
      title:"Insumos / Medicamentos", subTitle:"Recursos por asignar", subTitleError:"Recursos excedidos", IconTitle:DoneAll, IconSub:Info}
  ]

  const listLink = [
    {label: "Ficha Paciente", link:{onClick: () =>  handleBack([setselectPatient,setopenModalQuotation,settypeRequest,setviewQuotation,])}},
    {label: `${dataPatient.nombre} ${dataPatient.apellido_paterno}`, link:{onClick: () =>  handleBack([setopenModalQuotation,settypeRequest,setviewQuotation])}},
    {label: `Solicitudes`, link:{onClick: () =>  handleBack([setviewQuotation, settypeRequest])}},
    (viewQuotation.requestPatientInfo.estado === "Progreso" || viewQuotation.estado === "RecienCreada") ? {label: `Cotización`, link:{onClick: () =>  handleBack([setviewQuotation])}} : null,
    {label: viewQuotation.requestPatientInfo.estado === "EsperandoHabilitacion" ? "Habilitación de domicilio" : viewQuotation.requestPatientInfo.estado === "EsperaIsapre" ? "Adjuntar Confirmación Isapre" : "Asignación de recursos"},
  ]

  return (
    <div>
      {resumeQuotation ? (
        <div>
          {resumeQuotation.View === "cotizacion" ? (
            <DocumentQuotation
              dataPatient={dataPatient}
              viewQuotation={viewQuotation}
              resumeQuotation={resumeQuotation}
              setopenModalQuotation={setopenModalQuotation}
              setselectPatient={setselectPatient}
              setviewQuotation={setviewQuotation}
              settypeRequest={settypeRequest}
              setresumeQuotation={setresumeQuotation}
              handleReloadFile={handleReloadFile}
              forecast={forecast}
            />
          ) : (
            resumeQuotation.View === "habilitacion" && (
              <HomeAuthorization
                resumeQuotation={resumeQuotation}
                setresumeQuotation={setresumeQuotation}
                dataPatient={dataPatient}
                viewQuotation={viewQuotation}
                forecast={forecast}
                handleReloadFile={handleReloadFile}
                handleBack={() =>
                  handleBack([
                    setopenModalQuotation,
                    settypeRequest,
                    setviewQuotation,
                  ])
                }
              />
            )
          )}
        </div>
      ) : (
        <Grid container>
          {(loading && selectedPackage?.id) ? (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <BreadScrumbs listLink={listLink}/>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardBody>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                      >
                        {listCard.map((row, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          style={{ padding: "1%" }}
                          key={`card-quotation-${index}`}
                        >
                          <CardStatistics
                           {...row}
                          />
                        </Grid>

                        ))}
                      </Grid>
                      {packageBalanced?.tipo_permanencia == "Transitorio" &&
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={10}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          xs={12}
                        >
                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={3}
                          >
                            <Typography variant="h6" style={{color: indigo[500]}} >
                              Dias tratamiento
                            </Typography>

                          </Grid>

                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={1}
                          >

                            {!editablePeriod ?
                            <Typography variant="h6" style={{color: indigo[500]}} >
                              {daysPeriod}
                            </Typography>
                            :
                            <TextField
                              size="small"
                              variant="outlined"
                              type="number"
                              value={daysPeriod}
                              InputProps={{ inputProps: { min: 0} }}
                              onChange={(event) => setdaysPeriod(event.target.value)}
                              style={{width: "100%"}}
                            />
                            }

                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={2}
                          >
                            {!editablePeriod ?
                            <Button variant="outlined"
                              style={{
                                color: indigo[500],
                                borderColor: indigo[500]
                              }}
                              onClick={() => seteditablePeriod(true)}
                              endIcon={<Edit style={{color: indigo[500]}}/>}
                            >
                              {daysPeriod !== null ? "Modificar" : "Editar"}
                            </Button> :
                            <Button variant="outlined"
                              style={{
                                color: green[500],
                                borderColor: green[500]
                              }}
                              onClick={handleApplyDiscount}
                              endIcon={<CheckCircle style={{color: green[500]}}/>}
                            >
                              Aplicar
                            </Button>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      }
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={10}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          xs={12}
                          style={{margin: "50px 0"}}
                        >
                          <Typography variant="h6" style={{color: indigo[500]}} >
                            Descuento o recargo general
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          xs={12}
                        >
                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={6}
                          >
                            <Typography variant="body1" style={{color: indigo[500]}} >
                              Seleccione
                            </Typography>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                              item
                              xs={12}
                              style={{margin: "12px 0 0 0"}}
                            >
                              <FormControlLabel
                                control={
                                  <CustomRadio
                                      disabled={!editableDiscount}
                                      value="Descuento"
                                      checked={typeDiscount === "Descuento"}
                                      onChange={(event) => settypeDiscount(event.target.value)}
                                  />
                                }
                                label="Descuento" style={{textTransform: "none", color: editableDiscount ? "#000000" : "#0000005e" }}
                              />
                              <FormControlLabel
                                control={
                                  <CustomRadio
                                      disabled={!editableDiscount}
                                      value="Recargo"
                                      checked={typeDiscount === "Recargo"}
                                      onChange={(event) => settypeDiscount(event.target.value)}
                                  />
                                }
                                label="Recargo" style={{textTransform: "none", color: editableDiscount ? "#000000" : "#0000005e" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={1}
                          >
                            <Typography variant="body1" style={{color: indigo[500]}} >
                              Porcentaje
                            </Typography>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                              item
                              xs={12}
                              style={{margin: "12px 0 0 0"}}
                            >
                              {!editableDiscount ?
                              <Typography variant="body1" style={{color: indigo[500]}} >
                                 {`${typeDiscount === "Descuento" ? "-" : "+"} ${valuePercentage} %`}
                              </Typography>
                              :
                              <TextField
                                size="small"
                                variant="outlined"
                                type="number"
                                value={valuePercentage}
                                InputProps={{ inputProps: { min: 0} }}
                                onChange={(event) => setvaluePercentage(event.target.value)}
                                style={{width: "100%"}}
                              />
                              }
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={2}
                          >
                            {!editableDiscount ?
                            <Button variant="outlined"
                              style={{
                                color: indigo[500],
                                borderColor: indigo[500]
                              }}
                              onClick={() => seteditableDiscount(true)}
                              endIcon={<Edit style={{color: indigo[500]}}/>}
                            >
                              {typeDiscount && valuePercentage > 0 ? "Modificar" : "Agregar"}
                            </Button> :
                            <Button variant="outlined"
                              style={{
                                color: green[500],
                                borderColor: green[500]
                              }}
                              onClick={handleApplyDiscount}
                              endIcon={<CheckCircle style={{color: green[500]}}/>}
                            >
                              Aplicar
                            </Button>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                      >
                      <Root>
                      <Divider
                        style={{
                        border: "1px solid #303F9F",
                        margin: "40px 0"
                      }}/>
                      </Root>
                      </Grid>
                      {packageUnbalanced &&
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item
                        xs={12}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                                checked={selectedPackage?.descompensacion}
                                onChange={handleSwitch}
                            />
                          }
                          label={`Paquete con descompensación ${selectedPackage?.descompensacion ? "activado" : "desactivado"}`}
                        />
                      </Grid>
                      }
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{ margin: "0 0 20px 0" }}
                      >
                        <QuotationTabs
                          dataPatient={dataPatient}
                          selectedPackage={selectedPackage}
                          packageBalanced={packageBalanced}
                          packageUnbalanced={packageUnbalanced}
                          viewQuotation={viewQuotation}
                          setviewQuotation={setviewQuotation}
                          resourceTotal={resourceTotal}
                          medicineTotal={medicineTotal}
                          listProfessional={listProfessional}
                          listEmergency={listEmergency}
                          listEquip={listEquip}
                          listOther={listOther}
                          workersData={workersData}
                          emergencyData={emergencyData}
                          otherData={otherData}
                          equipData={equipData}
                          setresumeQuotation={setresumeQuotation}
                          updatePackage={updatePackage}
                          updatePackageSelected={updatePackageSelected}
                          handleReloadFile={handleReloadFile}
                          handleBack={() =>
                            handleBack([
                              setopenModalQuotation,
                              settypeRequest,
                              setviewQuotation,
                            ])
                          }
                        />
                      </Grid>

                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
              <UpdateStockButton
                updateStock={updateStock}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <LoadInfo title="Cargando información ..." />
            </Grid>
          )}
          {openModalEditPackageItem && (
            <ModalEditPackageItemAmount
              open={openModalEditPackageItem}
              handleClose={() => setopenModalEditPackageItem(false)}
              infoRowPackage={infoRow}
              handleSave={handleSaveInfo}
            />
          )}


        </Grid>
      )}
    </div>
  );
}
