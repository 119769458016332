import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import UpdateStatistcs from "components/PatientFile/UpdateStatistics";
import FormPersonalData from "components/PatientFile/FormPersonalData";
import RequestFile from "./RequestFile";
import ModalNewWork from "components/PatientFile/ModalNewWork";
import ModalComment from "components/PatientFile/ModalComment";
import ModalRemoveWorkRow from "components/PatientFile/ModalRemoveWorkRow";
import ModalEditWork from "components/PatientFile/ModalEditWork";
import ModalEndVisit from "components/PatientFile/ModalEndVisit";
import ModalDecompensation from "components/PatientFile/ModalDecompensation";
import ModalUpdateStateWork from "components/PatientFile/ModalUpdateStateWork";
import ModalCheckOut from "components/PatientFile/ModalCheckOut";
import FormMedicalPreventiveData from "components/PatientFile/FormMedicalPreventiveData";
import FormAttorneyData from "components/PatientFile/FormAttorneyData";
import WorkTabs from "components/PatientFile/WorkTabs";
import IconStatistics from "views/Widgets/FichaPaciente/IconStatistics";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import Snackbar from "components/Snackbar/Snackbar.js";
import {
  Typography,
  IconButton,
  Breadcrumbs,
  Link,
  withWidth,
  Grid,
  Box
} from "@material-ui/core";
import {
  red,
  brown,
  yellow,
  blue,
  green,
  grey,
  orange,
  indigo
} from "@material-ui/core/colors";
import { Notifications } from "@material-ui/icons/";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import moment from "moment-timezone";
import api from "utils/API";
import ButtonsRegistros from "./RegistrosVistasYParametros/ButtonsRegistros";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import "moment/locale/es";
import WorkTeam from "components/PatientFile/WorkTeam/WorkTeam";
import Exams from "components/PatientFile/Exams/Exams";
import Memorandum from "components/PatientFile/Memorandum/Memorandum";
import HomeStock from "components/PatientFile/HomeStock/HomeStock";
import ExpansionPanelCommon from "views/Widgets/Common/ExpansionPanelCommon";

moment.locale("es");

const useStyles = makeStyles(styles);

const statePatientColor = {
  Prospecto: red[500],
  Visitado: brown[500],
  Cotizado: yellow[500],
  Aprobado: blue[500],
  Habilitado: green[500],
  Particular: green[900],
  Perdido: grey[900],
};

const stateColor = state => {
  return (
    <Typography
      style={{ color: statePatientColor[state] }}
    >{` ${state}`}</Typography>
  );
};

const PatientFile = ({
  selectPatient,
  setselectPatient,
  listPercentage,
  listPeriod,
  listassigned,
  listProfDecompensation,
  width,
  listCoordinators,
  chargeColor
}) => {
  const classes = useStyles();
  const [packagePatient, setpackagePatient] = useState({});
  const [packageUnbalancedPatient, setpackageUnbalancedPatient] = useState({});
  const [dataPatient, setdataPatient] = useState({ ...selectPatient });
  const [dataFile, setdataFile] = useState({});
  const [selectForecast, setselectForecast] = useState({});
  const [selectPackage, setselectPackage] = useState({});
  const [selectpackageUnbalancedPatient, setselectpackageUnbalancedPatient] = useState({});
  const [dataTutor, setdataTutor] = useState({});
  const [selectWorkRow, setselectWorkRow] = useState(null);
  const [selectRemoveWorkRow, setselectRemoveWorkRow] = useState(null);
  const [selectUpdateState, setselectUpdateState] = useState(null);
  const [iconSelect, seticonSelect] = useState(null);
  const [openModalQuotation, setopenModalQuotation] = useState(false);
  const [openModalDecompensation, setopenModalDecompensation] = useState(false);
  const [initialCharge, setinitialCharge] = useState(true);
  const [modalNewWork, setmodalNewWork] = useState(false);
  const [clinic, setclinic] = useState(false);


  const [sizePatient, setsizePatient] = useState({
    value: null,
    date: moment()
      .subtract(1, "days")
      .format("DD-MM-YYYY")
  });
  const [workArray, setworkArray] = useState([]);
  const [weight, setweight] = useState({ value: null, date: null });
  const [temperature, settemperature] = useState({ value: null, date: null });
  const [heartRate, setheartRate] = useState({ value: null, date: null });
  const [breathingFrequency, setbreathingFrequency] = useState({
    value: null,
    date: null
  });
  const [oxygenSaturation, setoxygenSaturation] = useState({
    value: null,
    date: null
  });
  const [bloodPressureSystolic, setbloodPressureSystolic] = useState({
    value: null,
    date: null
  });
  const [bloodPressureDiastolic, setbloodPressureDiastolic] = useState({
    value: null,
    date: null
  });
  const [bloodPressureMean, setbloodPressureMean] = useState({
    value: null,
    date: null
  });
  const [pain, setpain] = useState({ value: null, date: null });
  const [diagnosis, setdiagnosis] = useState({ value: null, date: null });
  const [allergy, setallergy] = useState({ value: null, date: null });
  const [editWork, seteditWork] = useState(null);
  const [endVisit, setendVisit] = useState(null);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState(null);

  const [updatePage, setUpdatePage] = useState(false);
  const [activateAlert, setActivateAlert] = useState(false);
  const [alertSigns, setAlertSigns] = useState([]);
  const [openModalcheckOut, setopenModalcheckOut] = useState(false);
  const [tensAssigned, setTensAssigned] = useState([]);

  const [workTeamData, setworkTeamData] = useState({
    file: null,
    coordinador: null,
    ficha_personal: [],
    tareas: []
  });
  const [examsData, setExamsData] = useState({
    file: null,
    examList: []
  })
  const [memorandumsData, setMemorandumsData] = useState({
    file: null,
    memorandumsList: []
  })
  const [stockData, setStockData] = useState({
    file: null,
    stockList: []
  })

  const type_user = localStorage.getItem("tipo_perfil");

  const statistics1 = [
    ["sizePatient", "Talla", sizePatient, "cm", "/altura.svg", setsizePatient, "int"],
    ["weight", "Peso", weight, "kg", "/peso.svg", setweight, "int"],
    ["temperature", "Temperatura", temperature, "ºC", "/temperatura.svg", settemperature, "int"],
    ["heartRate","Frecuencia cardíaca",heartRate,"/min","/frecuencia_cardiaca.svg",setheartRate,"int"],
    ["breathingFrequency","Frecuencia respiratoria",breathingFrequency,"/min","/frecuencia_respiratoria.svg",setbreathingFrequency,"int"]
  ];

  const statistics2 = [
    ["oxygenSaturation","Saturación de oxígeno",oxygenSaturation,"%","/saturacion_oxigeno.svg",setoxygenSaturation,"int"],
    [
      "bloodPressure",
      "Presión arterial",
      {
        value:
          bloodPressureSystolic.value || bloodPressureDiastolic.value
            ? `${bloodPressureSystolic.value
              ? `P.S: ${bloodPressureSystolic.value}`
              : ""
            } ${bloodPressureDiastolic.value
              ? `P.D: ${bloodPressureDiastolic.value}`
              : ""
            }`
            : null,
        date: bloodPressureSystolic.date
          ? bloodPressureSystolic.date
          : bloodPressureDiastolic.date
            ? bloodPressureDiastolic.date
            : null
      },
      "mmhg",
      "/presion_arterial.svg",
      () => { },
      "int"
    ],
    ["pain", "Dolor (1-10)", pain, "", "/dolor.svg", setpain, "int"],
    ["allergy", "Alergia", allergy, "", "/alergia.svg", setallergy, "str"],
    ["diagnosis", "Diagnostico", diagnosis, "", "/diagnostico.svg", value => handleDiagnosis(value), "str"
    ]
  ];

  const statistics3 = [
    ["sizePatient", "Talla", sizePatient, "cm", "/altura.svg", setsizePatient, "int"],
    ["weight", "Peso", weight, "kg", "/peso.svg", setweight, "int"],
    ["temperature", "Temperatura", temperature, "ºC", "/temperatura.svg", settemperature, "int"],
    ["heartRate", "Frecuencia cardíaca", heartRate, "/min", "/frecuencia_cardiaca.svg", setheartRate, "int"],
    ["breathingFrequency", "Frecuencia respiratoria", breathingFrequency, "/min", "/frecuencia_respiratoria.svg", setbreathingFrequency, "int"],
    ["oxygenSaturation", "Saturación de oxígeno", oxygenSaturation, "%", "/saturacion_oxigeno.svg", setoxygenSaturation, "int"],
    [
      "bloodPressure",
      "Presión arterial",
      {
        value:
          bloodPressureSystolic.value || bloodPressureDiastolic.value
            ? `${bloodPressureSystolic.value
              ? `P.S: ${bloodPressureSystolic.value}`
              : ""
            } ${bloodPressureDiastolic.value
              ? `P.D: ${bloodPressureDiastolic.value}`
              : ""
            }`
            : null,
        date: bloodPressureSystolic.date
          ? bloodPressureSystolic.date
          : bloodPressureDiastolic.date
            ? bloodPressureDiastolic.date
            : null
      },
      "mmhg",
      "/presion_arterial.svg",
      () => { },
      "int"
    ],
    ["pain", "Dolor (1-10)", pain, "", "/dolor.svg", setpain, "int"],
    ["allergy", "Alergia", allergy, "", "/alergia.svg", setallergy, "str"],
    ["diagnosis", "Diagnostico", diagnosis, "", "/diagnostico.svg", value => handleDiagnosis(value), "str"]
  ];

  useEffect(() => {
    if (initialCharge) {
      initialData();
    }
    if (localStorage.getItem("open_solicitud") === "true") {
      setopenModalQuotation(true);
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    let patientFile = ""
    let packageData = ""
    let packageUnbalancedData = ""
    let tempSelectForecast = null
    let tempSelectPackage = null
    let tempSelectPackageUnbalanced = null
    if (localStorage.getItem('patientFile') !== '') {
      patientFile = JSON.parse(localStorage.getItem('patientFile'))
      updatePatientInfo(patientFile);
    }
    if (localStorage.getItem('packageData') !== '') {
      packageData = JSON.parse(localStorage.getItem('packagePatient'))
      updatePackagePatient(packageData);
    }
    if (localStorage.getItem('packageUnbalanced') !== '') {
      packageUnbalancedData = JSON.parse(localStorage.getItem('packageUnbalanced'))
      updatePackageUnbalancedPatient(packageUnbalancedData);
    }
    if (localStorage.getItem('selectForecast') !== '') {
      tempSelectForecast = JSON.parse(localStorage.getItem('selectForecast'))
      updateForecastPatient(tempSelectForecast);
    }
    if (localStorage.getItem('selectPackagePatient') !== '') {
      tempSelectPackage = JSON.parse(localStorage.getItem('selectPackagePatient'))
      updateSelectPackagePatient(tempSelectPackage);
    }
    if (localStorage.getItem('selectPackageUnbalancedPatient') !== '') {
      tempSelectPackageUnbalanced = JSON.parse(localStorage.getItem('selectPackageUnbalancedPatient'))
      updateSelectPackageUnbalancedPatient(tempSelectPackageUnbalanced);
    }

    const requestFileData = api.get(`api/paciente/${dataPatient.id}/ficha/`);


    api.get(`api/paciente/${dataPatient.id}/tutor/`).then((response) => {
      setdataTutor(response.data);
    })

    Promise.all([requestFileData]).then(request => {
      const alertas = request[0].data["alertas"];
      const dataRequest = request[0].data;
      setclinic(dataRequest["clinica"])

      const file = dataRequest["ficha"];
      packageData = dataRequest["paquete"];
      packageUnbalancedData = dataRequest["paquete_descompensacion"];

      const forecast = packageData["id_convenio_isapre"];

      const tempDictEvent = {
        ["#FFECB3"]: "medic",
        ["#FFCDD2"]: "nurse",
        ["#C8E6C9"]: "rehab",
        ["#B3E5FC"]: "tens",
      }
      const tempDictPersonalFile = dataRequest["ficha_personal"].reduce((accum, obj) => ({
        ...accum,
        [obj.id_usuario]: obj
      }),{});
      const workTeamStructure = {
        file,
        coordinador: dataRequest["coordinador"],
        ficha_personal: dataRequest["ficha_personal"],
        tareas: dataRequest["tareas"].filter((row) => (row.fecha_final && tempDictPersonalFile[row.id_usuarios]) ).map(tarea => {
          const rowColor = chargeColor.filter((row) => row.traslate.includes(tarea.id_cargo_asignado__tipo_perfil))
          const endDate = moment(tarea.fecha_final)
          if (tarea.fecha_hora === tarea.fecha_final){
            endDate.add(1, "day")
          }
          const personalFileRow = tempDictPersonalFile[tarea["id_usuarios"]]
          const nameProf = `${personalFileRow?.nombres} ${personalFileRow?.apellido_paterno}`
          return {
            title: `${personalFileRow.id_cargo__nombre_corto} - ${nameProf}`,
            start: moment(tarea.fecha_hora).toDate(),
            end: endDate.toDate(),
            color: tempDictEvent[rowColor[0]?.color_row],
            chargeName: tarea.id_cargo_asignado__tipo_perfil
          };
        })
      };
   

      if (dataRequest["diagnostico"]) {
        const tempDiagnosis = dataRequest["diagnostico"];
        handleDiagnosis({
          value: tempDiagnosis["diagnostico"],
          date: moment(tempDiagnosis["fecha_diagnostico"]).format(
            "DD-MM-YYYY HH:mm:ss"
          )
        });
      }
      request[0].data["signos"].map(row => {
        if (alertas[`${row.tipo_signos}`] != undefined) {
          if (
            parseFloat(row.medicion) <
            alertas[`${row.tipo_signos}`].limite_inferior ||
            parseFloat(row.medicion) >
            alertas[`${row.tipo_signos}`].limite_superior
          ) {
            setActivateAlert(true);
            const tempArray = alertSigns;
            tempArray.push({
              [row.tipo_signos]: true
            });
            setAlertSigns(tempArray);
          }
        }
      });
      dataRequest["signos"].map(row => {
        const temRow = {
          value: row.medicion,
          date: moment(row.fecha_hora).format("DD-MM-YYYY HH:mm:ss")
        };
        if (row.tipo_signos === "sizePatient") {
          setsizePatient(temRow);
        } else if (row.tipo_signos === "temperature") {
          settemperature(temRow);
        } else if (row.tipo_signos === "weight") {
          setweight(temRow);
        } else if (row.tipo_signos === "heartRate") {
          setheartRate(temRow);
        } else if (row.tipo_signos === "breathingFrequency") {
          setbreathingFrequency(temRow);
        } else if (row.tipo_signos === "oxygenSaturation") {
          setoxygenSaturation(temRow);
        } else if (row.tipo_signos === "pain") {
          setpain(temRow);
        } else if (row.tipo_signos === "allergy") {
          setallergy(temRow);
        } else if (row.tipo_signos === "bloodPressureSystolic") {
          setbloodPressureSystolic(temRow);
        } else if (row.tipo_signos === "bloodPressureDiastolic") {
          setbloodPressureDiastolic(temRow);
        } else if (row.tipo_signos === "bloodPressureMean") {
          setbloodPressureMean(temRow);
        }
      });
      setworkArray(dataRequest["tareas"]); ////---->>> lista de tareas
      const tensassigned = dataRequest["tareas_tens"].map((task) => {
        const tempTask = {...task};
        tempTask.fecha_hora = moment(task.fecha_hora)
          .format("YYYY-MM-DD hh:mm:ss")
          .toString();
        if (task.fecha_final == task.fecha_hora){
          tempTask.fecha_final = moment(task.fecha_final).add(1, "day");
        }
        tempTask.fecha_final = moment(tempTask.fecha_final)
          .format("YYYY-MM-DD hh:mm:ss")
          .toString();
        return tempTask
      });
      setTensAssigned(tensassigned);


      tempSelectForecast = {
        value: forecast ? forecast["id"] : null,
        label: forecast ? forecast["nombre"] : null
      };
      tempSelectPackage = {
        value: packageData["id_paquete_basado"] || null,
        label: packageData["id_paquete_basado"]
          ? `${packageData["zona"]} ${packageData["complejidad"]} - ${packageData["descripcion"] || ""}`
          : null
      };
      tempSelectPackageUnbalanced = {
        value: packageUnbalancedData["id_paquete_basado"] || null,
        label: packageUnbalancedData["id_paquete_basado"]
          ? `${packageUnbalancedData["zona"]} ${packageUnbalancedData["complejidad"]} - ${packageUnbalancedData["descripcion"] || ""}`
          : null
      };

      updatePackagePatient(packageData);
      updatePackageUnbalancedPatient(packageUnbalancedData);
      updateForecastPatient(tempSelectForecast);
      updateSelectPackagePatient(tempSelectPackage);
      updateSelectPackageUnbalancedPatient(tempSelectPackageUnbalanced)
      setdataFile(file);
      setloading(true);
      setworkTeamData(workTeamStructure);
    });
  };

  const updatePatientInfo= (data) => {
    localStorage.setItem("patientFile",JSON.stringify(data))
    setdataPatient(data);
  }

  const updatePackagePatient = (data) => {
    localStorage.setItem("packagePatient",JSON.stringify(data));
    setpackagePatient(data);
  }

  const updatePackageUnbalancedPatient = (data) => {
    localStorage.setItem("packageUnbalanced",JSON.stringify(data));
    setpackageUnbalancedPatient(data);
  }

  const updateSelectPackagePatient = (data) => {
    localStorage.setItem("selectPackagePatient",JSON.stringify(data));
    setselectPackage(data)
  }

  const updateSelectPackageUnbalancedPatient = (data) => {
    localStorage.setItem("selectPackageUnbalancedPatient",JSON.stringify(data));
    setselectpackageUnbalancedPatient(data)
  }

  const updateForecastPatient = (data) => {
    localStorage.setItem("selectForecast",JSON.stringify(data));
    setselectForecast(data);
  }

  const handleReloadFile = () => {
    setinitialCharge(true);
  };

  const handleDiagnosis = value => {
    const auxDataPatient = { ...dataPatient };
    auxDataPatient.diagnostico = value.value;
    setdiagnosis(value);
    updatePatientInfo(auxDataPatient);
  };

  const handleIconSelect = row => {
    if (row[0] === "diagnosis") {
      seticonSelect(row);
    }
  };

  const handleOpenModalQuotation = row => {
    setopenModalQuotation(true);
    localStorage.setItem("open_solicitud", "true");
  };

  const handleExam = (expand) => {
    if(expand){
      api.get(`api/examenes/info/${selectPatient.id}`).then((response) => {
        const examsListRequest = response.data;
        const file = {...dataFile}
        const examsStructure = {
          file,
          examList: examsListRequest,
        };
        setExamsData(examsStructure);
      })
    }
  }

  const handleStock = (expand) => {
    if(expand){
      const requestStock = api.get(`api/stock/paciente/id/${selectPatient.id}/domicilio/`);
      const requestStokCalendar = api.get(`api/solicitudespedidos/paciente/${selectPatient.id}/`);
      Promise.all([requestStock, requestStokCalendar]).then((request) => {
        const file = {...dataFile}
        const stockListRequest = request[0].data;
        const stockCalendarListRequest = request[1].data;
        const stockStructure = {
          file,
          dataPatient,
          stockList: stockListRequest,
          stockCalendarList: stockCalendarListRequest.map(item => {
            return {
              title: `Solicitud Nº ${item.id}`,
              start: moment(item.fecha_despacho).toDate(),
              end: moment(item.fecha_despacho).toDate(),
              id: item.id,
              requestType: item.tipo_solicitud,
              requestState: item.estado_solicitud_insumo,
              pharmacyRequestState: item.estado_solicitud_farmacia,
            };
          })
        };
        setStockData(stockStructure);
      });
    }
  }

  const handleMemorandum = (expand) => {
    if(expand){
      api.get(`api/minutas/paciente/${selectPatient.id}`).then((resonse) => {
        const file = {...dataFile}
        const memorandumsStructure = {
          file,
          dataPatient,
          memorandumsList: resonse.data,
        };
        setMemorandumsData(memorandumsStructure);
      })
    }

  }

  const _expandContentPersonalData_ = <>
    <FormPersonalData
      data={dataPatient}
      setdata={updatePatientInfo}
      setmessage={setmessage}

    />
  </>

const _expandContentMedicData_ = <>
    {localStorage.getItem("tipo_perfil") !== "CLITecEnfBas" &&
      <FormMedicalPreventiveData
        dataFile={dataFile}
        dataPatient={dataPatient}
        listPercentage={listPercentage}
        selectForecast={selectForecast}
        selectPackage={selectPackage}
        setdataFile={setdataFile}

        setdataPatient={updatePatientInfo}
        setmessage={setmessage}
      />
    }
  </>
  const _expandContentTutorData_ = <>
    <FormAttorneyData
      data={dataTutor}
      setdata={setdataTutor}
      id_patient={dataPatient.id}

      setmessage={setmessage}
    />
  </>

  const _expandContentPatientInfo_ = <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <Grid item xs={12} style={{ margin: "15px 0" }}>
        <ExpansionPanelCommon
          title= "Datos personales"
          content={_expandContentPersonalData_}
          // handleExpand={handleExpand}
        />
      </Grid>
      <Grid item xs={12} style={{ margin: "15px 0" }}>
      <ExpansionPanelCommon
          title= "Datos médicos y previsionales"
          content={_expandContentMedicData_}
          // handleExpand={handleExpand}
        />
      </Grid>
      <Grid item xs={12} style={{ margin: "15px 0" }}>
        <ExpansionPanelCommon
          title= "Datos del apoderado"
          content={_expandContentTutorData_}
          // handleExpand={handleExpand}
        />
      </Grid>
    </Grid>

  const _expandContentWork_ = <> <WorkTabs
    dataArray={workArray}
    setdata={setworkArray}
    openModal={setmodalNewWork}
    setselectWorkRow={setselectWorkRow}
    setselectRemoveWorkRow={setselectRemoveWorkRow}
    seteditWork={seteditWork}
    setendVisit={setendVisit}
    setselectUpdateState={setselectUpdateState}
    setsizePatient={setsizePatient}
    settemperature={settemperature}
    temperature={temperature}
    setweight={setweight}
    setheartRate={setheartRate}
    setbreathingFrequency={setbreathingFrequency}
    setoxygenSaturation={setoxygenSaturation}
    setbloodPressureSystolic={setbloodPressureSystolic}
    setbloodPressureDiastolic={setbloodPressureDiastolic}
    setbloodPressureMean={setbloodPressureMean}
    setpain={setpain}
    setallergy={setallergy}
    handleDiagnosis={handleDiagnosis}
    setUpdatePage={setUpdatePage}
    width={width}
  />
  </>

  const _expandContentExam_ =   <Box width="100%"><Exams examsData={examsData} /></Box>

  const _expandContentWorkTeam_ =   <Box width="100%">
    <WorkTeam
      workTeamData={workTeamData}
      listCoordinators={listCoordinators}
      chargeColor={chargeColor}
    />
  </Box>

  const _expandContentStock_ = <><HomeStock
    stockData={stockData}
    dataFile={dataFile}
  />
  </>

  const _expandContentMemorandum_ = <Box width="100%">
      <Memorandum memorandumsData={memorandumsData} />
  </Box>


  const notfoundImg = `https://www.ing.uc.cl/transporte-y-logistica/wp-content/uploads/2018/04/foto-incognito.jpg`;
  return (
    <div>
      {openModalQuotation ? (
        <RequestFile
          dataPatient={dataPatient}
          dataTutor={dataTutor}
          dataFile={dataFile}
          setdataFile={setdataFile}
          setselectPatient={setselectPatient}
          setopenModalQuotation={setopenModalQuotation}
          selectForecast={selectForecast}
          selectPackage={selectPackage}
          selectpackageUnbalancedPatient={selectpackageUnbalancedPatient}
          packagePatient={packagePatient}
          handleReloadFile={handleReloadFile}
          clinic={clinic}
        />
      ) : (
        <GridContainer>
          {loading ? (
            <GridItem xs={12}>
              <GridItem xs={12}>
                <CardBody className="rounded shadow border border-primary">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      color="inherit"
                      onClick={() => setselectPatient(null)}
                      style={{ cursor: "pointer" }}
                    >
                      Ficha Paciente
                    </Link>
                    <Typography color="textPrimary">{`${dataPatient.nombre} ${dataPatient.apellido_paterno}`}</Typography>
                  </Breadcrumbs>
                </CardBody>
              </GridItem>
              <Card>
                <CardBody>
                  <GridItem
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    xs={12}
                  >
                    <GridItem
                      container
                      xs={12}
                      sm={4}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ margin: "5px 0" }}
                    >
                      <Typography>
                        {`Fecha: ${dataPatient
                          ? moment().format("DD-MM-YYYY")
                          : moment().format("DD-MM-YYYY")
                          }`}
                      </Typography>
                    </GridItem>
                    <GridItem
                      container
                      xs={12}
                      sm={4}
                      direction="column"
                      justify="center"
                      alignItems="center"
                      style={{ margin: "5px 0" }}
                    >
                      <Typography>
                        {`Días en domicilio: ${dataPatient.domicilio || 0}`}
                      </Typography>
                      {type_user !== "CLITecEnfBas" && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setopenModalcheckOut(true)}
                          style={{ margin: "10px 0" }}
                        >
                          Registrar salida/hospitalización
                        </Button>
                      )}
                    </GridItem>
                    <GridItem
                      container
                      xs={12}
                      sm={4}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ margin: "5px 0" }}
                    >
                      <Typography
                        style={{ margin: "0 5px 0 0" }}
                      >{`Estado: `}</Typography>
                      {stateColor(!dataPatient.activo ? "Perdido" :
                        dataFile.estado_ficha
                          ? dataFile.estado_ficha
                          : "Prospecto"
                      )}
                    </GridItem>
                  </GridItem>
                  <Card
                    style={
                      width === "xs" || width === "sm"
                        ? { padding: "5px 0px", margin: "0px" }
                        : { padding: "15px 0" }
                    }
                  >
                    {width === "xs" || width === "sm" ? (
                      <GridItem
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        xs={12}
                      >
                        <GridItem
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          xs={12}
                        >
                          <GridItem
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            xs={12}
                            sm={6}
                            style={{ margin: "5px 0" }}
                          >
                            <img
                              src={
                                dataPatient.foto_paciente
                                  ? dataPatient.foto_paciente
                                  : notfoundImg
                              }
                              alt="..."
                              style={{
                                display: "inline-block",
                                marginBottom: "10px",
                                overflow: "hidden",
                                textAlign: "center",
                                verticalAlign: "middle",
                                width: "150px",
                                height: "150px",
                                boxShadow:
                                  "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                                borderRadius: "50%"
                              }}
                            />
                          </GridItem>
                          <GridItem
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            xs={12}
                            sm={6}
                            style={{ margin: "5px 0" }}
                          >
                              <Button
                                disabled={!dataPatient.activo}
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModalQuotation}
                                style={{ margin: "10px 0" }}
                              >
                                Solicitar
                              </Button>
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                              style={{ padding: "0" }}
                              onClick={() => setopenModalDecompensation(true)}
                            >
                              <Notifications
                                style={{
                                  // backgroundColor: indigo[50],
                                  color: red[500]
                                }}
                                fontSize="large"
                              />
                            </IconButton>
                          </GridItem>
                          <GridItem
                            container
                            direction="column"
                            justify="center"
                            alignItems={
                              width === "xs" ? "center" : "flex-start"
                            }
                            style={{ margin: "5px 0" }}
                            xs={12}
                            sm={6}
                          >
                            <Typography>
                              {`Edad: ${dataPatient.nacimiento
                                ? moment().diff(
                                  dataPatient.nacimiento,
                                  "years"
                                )
                                : "Sin actualizar"
                                }`}
                            </Typography>
                            <Typography>
                              {`Diagnóstico: ${dataPatient.diagnostico
                                ? dataPatient.diagnostico
                                : "Sin actualizar"
                                }`}
                            </Typography>
                          </GridItem>
                          <GridItem
                            container
                            direction="column"
                            justify="center"
                            alignItems={
                              width === "xs" ? "center" : "flex-start"
                            }
                            style={{ margin: "5px 0" }}
                            xs={12}
                            sm={6}
                          >
                            <Typography>
                              {`Previsión: ${selectForecast
                                ? selectForecast.label
                                  ? selectForecast.label
                                  : "Sin actualizar"
                                : "Sin actualizar"
                                }`}
                            </Typography>
                            <Typography>
                              {`Paquete: ${selectPackage
                                ? selectPackage.label
                                  ? selectPackage.label
                                  : "Sin actualizar"
                                : "Sin actualizar"
                                }`}
                            </Typography>
                          </GridItem>
                          <GridItem
                            container
                            direction="row"
                            justify={width === "xs" ? "center" : "flex-start"}
                            alignItems="center"
                            style={{ margin: "5px 0" }}
                            xs={12}
                          >
                            <Typography>
                              {`Centro Hospitalario: ${dataFile.centro_hospitalario_referencia
                                ? dataFile.centro_hospitalario_referencia
                                : "Sin actualizar"
                                }
                        `}
                            </Typography>
                          </GridItem>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <GridItem
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        xs={12}
                      >
                        <GridItem
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          xs={9}
                          sm={10}
                        >
                          <GridItem
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={3}
                          >
                            <img
                              src={
                                dataPatient.foto_paciente
                                  ? dataPatient.foto_paciente
                                  : notfoundImg
                              }
                              alt="..."
                              style={{
                                display: "inline-block",
                                marginBottom: "10px",
                                overflow: "hidden",
                                textAlign: "center",
                                verticalAlign: "middle",
                                width: "150px",
                                height: "150px",
                                boxShadow:
                                  "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                                borderRadius: "50%"
                              }}
                            />
                          </GridItem>
                          <GridItem
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            xs={9}
                          >
                            <GridItem
                              container
                              direction="column"
                              justify="center"
                              alignItems="flex-start"
                              style={{ padding: "1%" }}
                              xs={6}
                            >
                              <Typography>
                                {`Edad: ${dataPatient.nacimiento
                                  ? moment().diff(
                                    dataPatient.nacimiento,
                                    "years"
                                  )
                                  : "Sin actualizar"
                                  }`}
                              </Typography>
                              <Typography>
                                {`Diagnóstico: ${dataPatient.diagnostico
                                  ? dataPatient.diagnostico
                                  : "Sin actualizar"
                                  }`}
                              </Typography>
                            </GridItem>
                            <GridItem
                              container
                              direction="column"
                              justify="center"
                              alignItems="flex-start"
                              style={{ padding: "1%" }}
                              xs={6}
                            >
                              <Typography>
                                {`Previsión: ${selectForecast
                                  ? selectForecast.label
                                    ? selectForecast.label
                                    : "Sin actualizar"
                                  : "Sin actualizar"
                                  }`}
                              </Typography>
                              <Typography>
                                {`Paquete: ${selectPackage
                                  ? selectPackage.label
                                    ? selectPackage.label
                                    : "Sin actualizar"
                                  : "Sin actualizar"
                                  }`}
                              </Typography>
                            </GridItem>
                            <GridItem
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                              style={{ padding: "1%" }}
                              xs={12}
                            >
                              <Typography>
                                {`Centro Hospitalario: ${dataFile.centro_hospitalario_referencia
                                  ? dataFile.centro_hospitalario_referencia
                                  : "Sin actualizar"
                                  }
                          `}
                              </Typography>
                            </GridItem>
                          </GridItem>
                        </GridItem>
                        <GridItem
                          container
                          direction="column"
                          justify="center"
                          alignItems="center"
                          xs={3}
                          sm={2}
                        >
                            <Button
                              disabled={!dataPatient.activo}
                              variant="contained"
                              color="primary"
                              onClick={handleOpenModalQuotation}
                              style={{ margin: "10px 0" }}
                            >
                              Solicitar
                            </Button>
                          <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            style={{ padding: "0" }}
                            onClick={() => setopenModalDecompensation(true)}
                          >
                            <Notifications
                              style={{
                                // backgroundColor: indigo[50],
                                color: red[500]
                              }}
                              fontSize="large"
                            />
                          </IconButton>
                        </GridItem>
                      </GridItem>
                    )}
                  </Card>
                  <ButtonsRegistros
                    dataPatient={dataPatient}
                    dataFile={dataFile}
                    tensAssigned={tensAssigned}
                    alertSigns={alertSigns}
                    activateAlert={activateAlert}
                    sizePatientProp={sizePatient}
                    weightProp={weight}
                    temperatureProp={temperature}
                    heartRateProp={heartRate}
                    breathingFrequencyProp={breathingFrequency}
                    oxygenSaturationProp={oxygenSaturation}
                    bloodPressureSystolicProp={bloodPressureSystolic}
                    bloodPressureDiastolicProp={bloodPressureDiastolic}
                    bloodPressureMeanProp={bloodPressureMean}
                    painProp={pain}
                    listassigned={listassigned}
                    setActivateAlert={setActivateAlert}
                    setAlertSigns={setAlertSigns}
                    setdataPatient={updatePatientInfo}
                    width={width}
                    clinic={clinic}
                  />
                  <Card style={{ margin: "0x 0", padding: "15px 0" }}>
                    <GridItem
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      xs={12}
                    >
                      {width !== "xs" && width !== "sm" && (
                        <GridItem
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-start"
                          xs={12}
                        >
                          {statistics1.map((row, index) => (
                            <GridItem key={`${index}-statistics`} item xs>
                              <IconStatistics
                                icon={row[4]}
                                data={row[2]}
                                measurement={row[3]}
                                title={row[1]}
                              />
                            </GridItem>
                          ))}
                        </GridItem>
                      )}
                      {width !== "xs" && width !== "sm" && (
                        <GridItem
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-start"
                          xs={12}
                        >
                          {statistics2.map((row, index) => (
                            <GridItem key={`${index + 5}-statistics`} item xs>
                              <IconStatistics
                                icon={row[4]}
                                data={row[2]}
                                measurement={row[3]}
                                title={row[1]}
                                onclick={() => handleIconSelect(row)}
                              />
                            </GridItem>
                          ))}
                        </GridItem>
                      )}
                      {(width === "xs" || width === "sm") && (
                        <GridItem
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-start"
                          xs={12}
                        >
                          {statistics3.map((row, index) => (
                            <GridItem key={`${index}-statistics`} xs={6} sm={2}>
                              <IconStatistics
                                icon={row[4]}
                                data={row[2]}
                                measurement={row[3]}
                                title={row[1]}
                                onclick={() => handleIconSelect(row)}
                              />
                            </GridItem>
                          ))}
                          {width === "sm" && <GridItem sm={4}></GridItem>}
                        </GridItem>
                      )}
                    </GridItem>
                  </Card>
                  <Grid item xs={12} style={{ margin: "15px 0" }}>
                    <ExpansionPanelCommon
                      title= "1. Datos paciente"
                      content={_expandContentPatientInfo_}
                      // handleExpand={handleExpand}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "15px 0" }}>
                    <ExpansionPanelCommon
                      title= "2. Tareas"
                      content={_expandContentWork_}
                      // handleExpand={handleExpand}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "15px 0" }}>
                    <ExpansionPanelCommon
                        title= "3. Exámenes"
                        content={_expandContentExam_}
                        handleChange={handleExam}
                      />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "15px 0" }}>
                      <ExpansionPanelCommon
                        title= "4. Equipo de trabajo"
                        content={_expandContentWorkTeam_}
                        // handleExpand={handleExpand}
                      />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "15px 0" }}>
                    <ExpansionPanelCommon
                      title= "5. Stock en domicilio, despacho y traslados"
                      content={_expandContentStock_}
                      handleChange={handleStock}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "15px 0" }}>
                    <ExpansionPanelCommon
                      title= "6. Minutas"
                      content={_expandContentMemorandum_}
                      handleChange={handleMemorandum}
                    />
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            <GridItem xs={12}>
              <LoadInfo title={"Cargando información ..."} />
            </GridItem>
          )}
        </GridContainer>
      )}
      {iconSelect && (
        <UpdateStatistcs
          open={!!iconSelect}
          handleClose={() => seticonSelect(null)}
          iconSelect={iconSelect}
          dataFile={dataFile}
        />
      )}
      {modalNewWork &&
          <ModalNewWork
            open={modalNewWork}
            handleClose={() => setmodalNewWork(false)}
            listPeriod={listPeriod}
            listassigned={listassigned}
            array={workArray}
            setArray={setworkArray}
            dataFile={dataFile}
          />
        }
      {editWork && (
        <ModalEditWork
          open={!!editWork}
          handleClose={() => seteditWork(null)}
          listassigned={listassigned}
          index={editWork[0]}
          array={workArray}
          setArray={setworkArray}
          dataFile={dataFile}

        />
      )}
      {endVisit && (
        <ModalEndVisit
          open={!!endVisit}
          handleClose={() => setendVisit(null)}
          row={endVisit}
          array={workArray}
          setArray={setworkArray}
        />
      )}
      {selectWorkRow && (
        <ModalComment
          open={!!selectWorkRow}
          handleClose={() => setselectWorkRow(null)}
          index={selectWorkRow}
          array={workArray}
          setArray={setworkArray}
        />
      )}
      {selectRemoveWorkRow && (
        <ModalRemoveWorkRow
          open={!!selectRemoveWorkRow}
          handleClose={() => setselectRemoveWorkRow(null)}
          row={selectRemoveWorkRow}
          array={workArray}
          setArray={setworkArray}

        />
      )}
      {selectUpdateState && (
        <ModalUpdateStateWork
          open={!!selectUpdateState}
          handleClose={() => setselectUpdateState(null)}
          row={selectUpdateState}
          array={workArray}
          setArray={setworkArray}

          setmessage={setmessage}
          dataFile={dataFile}
          setdataFile={setdataFile}
        />
      )}
      {(openModalDecompensation && listProfDecompensation.length > 0) &&
        <ModalDecompensation
          open={openModalDecompensation}
          handleClose={() => setopenModalDecompensation(false)}
          patient={dataPatient}
          listPeriod={listPeriod}
          listassigned={listassigned}
          listProfDecompensation={listProfDecompensation}
          dataFile={dataFile}
          switchProps={false}
        />
      }
      {openModalcheckOut && (
        <ModalCheckOut
          open={openModalcheckOut}
          handleClose={() => setopenModalcheckOut(false)}
          dataPatient={dataPatient}
          dataFile={dataFile}
        />
      )}
      {message && (
        <Snackbar
          place="tc"
          close
          color="success"
          message={message}
          open={!!message}
          closeNotification={() => setmessage(null)}
        />
      )}
      {updatePage && <div></div>}
    </div>
  );
};

export default withWidth()(PatientFile);
