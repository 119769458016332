import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// material-ui icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import SvgIcon from "@material-ui/core/SvgIcon";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
// core components
import api from "utils/API";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import Webcam from "react-webcam";
import apiform from "../../utils/APIForm";

// Material components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BreadScrumbs from 'views/Widgets/Common/BreadScrumbs';
// Otros componentes
import Select from "react-select";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import AlertDialog from "components/Attendance/AlertDialog";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  root: {
    flexGrow: 1
  },
  cardComponent: {
    minWidth: 420,
    minHeight: 220,
    maxWidth: 520,
    maxHeight: 820,
  },
  textField: {
    width: "45ch",
    padding: "5px"
  }
};

const useStyles = makeStyles(styles);

const videoConstraints = {
  width: 350,
  height: 420,
  facingMode: "user"
};
function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export default function Attendance() {
  const theme = useTheme();
  const [screenshot, setScreenshot] = React.useState(null);
  const [catchFoto, setcatchFoto] = React.useState(null);
  const [paciente, setPaciente] = React.useState({});
  const [direccionPaciente, setDireccionPaciente] = React.useState("");
  const [nombreAsistente, setNombreAsistente] = React.useState("");
  const [direccionUrl, setDireccionUrl] = React.useState("");
  const [abrirAlerta, setAbrirAlerta] = React.useState(false);
  const [titulo, setTitulo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [tipoAlerta, setTipoAlerta] = React.useState("");
  const [posicion, setPosicion] = React.useState("");
  const [updateView, setUpdateView] = React.useState(true);
  const [sendAttendance, setSendAttendance] = React.useState(false);
  const [listaPacientes, setListaPacientes] = React.useState([]);
  const [dictCharge, setdictCharge] = useState({});
  const [possessionTablet, setpossessionTablet] = useState(null)
  const webcamRef = React.useRef(null);
  const classes = useStyles();

  const usuario_id = localStorage.getItem("user_id");
  const usuario_nombre = localStorage.getItem("first_name");
  const usuario_apellido = localStorage.getItem("last_name");
  const usuario_completo = usuario_nombre + " " + usuario_apellido;
  const usuario_tipo = localStorage.getItem("tipo_usuario");
  const usuario_cargo_id = localStorage.getItem("id_cargo");


  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const isSM = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMD = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLG = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  let size = isXS ? "smartphone" : (isSM || isMD) ? "tablet" : (isLG || isXL) ? "pc" : "NA"
  let platform = navigator.userAgent;
  let os = /Mac/.test(platform) ? "Mac OS" : /Win/.test(platform) ? "Windows" : /Linux/.test(platform) ? "Linux" : "NA"



  // Por medio de esta funcion, capturamos al foto y la geolozalizacion
  const capture = (ArgCatchFoto=false) => {
    setSendAttendance(true);

    setcatchFoto(ArgCatchFoto)

    if (ArgCatchFoto){
      const imageSrc = webcamRef.current.getScreenshot();
      setScreenshot(imageSrc);
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        setPosicion(position);
      },
      () => {
        // En caso de que la geolocalizacion no se encuentre disponible, desplegara un mensaje de alerta de la no disponibilidad de esta funcion.
        setMensaje("¡La geolocalizacion no se encuentra disponible.!");
        setTipoAlerta("warning");
        setTitulo("¡Atencion!");
        setAbrirAlerta(true);
        setDireccionUrl("");
        setSendAttendance(false);
      }
    );

  };
    // Esta funcion se encarga de enviar los datos al back para registrar asistencia, verificando previamente los datos necesarios.
    // en caso de faltar uno solo se desplegara un mensaje de alerta indicando que falta uno de estos (localizacion, nombre persona, dirrecion, paciente(sucursal oficina))
    // ademas indicara el tipo de asistencia, el cual se obntiene desde la url al ser redirigido por los flujos de los botones de asistencia
    // terminado el registro de asistencia, se envia una solicitud al endpoint enviar email, el cual enviara un email al personal.

  
  const handleRegistrar = () => {
    setSendAttendance(false);
    const arrayURL = window.location.href.split("/");
    const tipoAsistencia = arrayURL[arrayURL.length - 1];
    const usuario_id = localStorage.getItem("user_id");
    if (
      (screenshot === null && catchFoto === true) ||
      nombreAsistente === "" ||
      direccionPaciente === "" ||
      paciente === ""
    ) {
      setMensaje("¡Falta completar algunos datos, asegurese de seleccionar el paciente/oficina y capturar la foto.!");
      setTitulo("¡Atencion!");
      setTipoAlerta("warning");
      setAbrirAlerta(true);;
    } else if (posicion?.coords?.latitude == undefined || posicion?.coords?.longitude == undefined) {
      setMensaje("El dispositivo no pudo entregar coordenadas válidas. Por favor, intenta otorgar permisos de geolocalización a Siclin y recarga el sitio.");
      setTitulo("¡Atencion!");
      setTipoAlerta("warning");
      setAbrirAlerta(true);
    } else {
      const formData = new FormData();
      formData.append("asistente", nombreAsistente);
      formData.append("asistente_id", usuario_id);
      if(catchFoto){
      formData.append("foto", screenshot);
      }
      formData.append("direccion", direccionPaciente);
      formData.append("paciente", paciente.label);
      formData.append("paciente_id", paciente.value);
      formData.append("latitud", posicion.coords.latitude);
      formData.append("longitud", posicion.coords.longitude);
      formData.append("tipo_asistencia", tipoAsistencia);
      if (possessionTablet?.value !== undefined){
      
        formData.append("recepcion_tablet", possessionTablet.value);
        formData.append("tam_dispositivo", size);
        formData.append("os", os);
      }
      api.post("api/registroasistencia/registrar/", formData).then(response => {
          if (response?.data?.exito) {
            setTitulo("¡Exito!");
            setTipoAlerta("success");
            setMensaje(response.data.exito);
            const arrayURL = window.location.href.split("/");
            const tipoAsistencia = arrayURL[arrayURL.length - 1];
            if (tipoAsistencia === "logout") {
              setAbrirAlerta(true);
              const formDataLogout = new FormData();
              formDataLogout.append(
                "refresh_token",
                localStorage.getItem("refresh")
              );
              apiform.post("appauth/token/blacklist/", formDataLogout).then(() => {
                window.location.href = `/`;
              })
            } else {
              setDireccionUrl("/admin/dashboard");
              setAbrirAlerta(true);
            }
          }
        }).catch(error => {
          if (error?.response?.data?.error) {
            setTitulo("¡Atencion!");
            setTipoAlerta("error");
            setMensaje(error.response.data.error);
            setDireccionUrl("");
            setAbrirAlerta(true);
          }
        });
    }
  };

  const handlePaciente = data => {
    setPaciente(data);
    setDireccionPaciente(data.direccion);
  };
  const handleNombreAsistente = event => {
    setNombreAsistente(event.target.value);
  };
  const handleDireccionPaciente = event => {
    setDireccionPaciente(event.target.value);
  };
  const handleCerrarAlerta = () => {
    setAbrirAlerta(false);
  };

  //  esta funcion se encaga de obtener los pacientes/oficina de salida o entrada, en donde el usuario puede marcar asistencia,
  //  siendo las oficinas entregas por defecto y los pacientes consultados al sistema del back
  const getPacientes = () => {

    setNombreAsistente(usuario_completo);
    const arrayURL = window.location.href.split("/");
    const tipoAsistencia = arrayURL[arrayURL.length - 1];
    if (tipoAsistencia === "login") {
      api
        .post("api/registroasistencia/pacientes/", {
          user_id: usuario_id,
          cargo_id: usuario_cargo_id,
          user_type: usuario_tipo
        })
        .then(response => {
          var lista_pacientes = response.data.map(item => ({
            value: item.id,
            direccion: item.direccion,
            label: item.nombre_completo
          }));
          if (lista_pacientes.length > 0) {
            setListaPacientes(lista_pacientes);
          }
          setUpdateView(false);
        })
    } else {
      api
        .post("api/registroasistencia/pacientes/salida", {
          user_id: usuario_id,
          cargo_id: usuario_cargo_id,
          user_type: usuario_tipo
        })
        .then(response => {
          var lista_pacientes = response.data.map(item => ({
            value: item.id,
            direccion: item.direccion,
            label: item.nombre_completo
          }));
          setListaPacientes(lista_pacientes);
          setUpdateView(false);
        })
    }
  };
  React.useEffect(() => {
    if (updateView) {
      getPacientes();
      setUpdateView(false);
    }
    // debido a que la fotografia y la geolocalizacion toman un tiempo en estar disponibles para su uso
    // al hacer click en registrar asistencia, solo se ejecutara cuando los datos se encuentren disponible, mientras tanto aparecera con el backdrop.
    if (sendAttendance && (screenshot !== null || !catchFoto)  && posicion) {
      handleRegistrar();
    }
  });

  React.useEffect(() => {
    api.get("api/cargos/?profecionales_visita=True").then((request) => {
        setdictCharge(request.data.reduce((accum, obj) => ({
          ...accum,
          [obj.id]: obj
        }), {}));
    });
  }, [])

  const listLink = [
    {label: "Dashboard", link:{to:"/admin/dashboard/"}},
    {label: "Asistencia"},
  ]

  const checkPatienAdress = ["CMH", "Casa"].some((word) => {
    const evalWord = paciente?.label?.includes(word)
    return evalWord
  })
  const validation = (dictCharge[usuario_cargo_id] && paciente?.direccion && !checkPatienAdress)

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item container xs={12} sm={6} md={12} spacing={5}>
        <ViewTitle
          title="Asistencia"
          message=" Regista tu asistencia, recuerda deslogearte solo cuando termines tu turno!. "
        />
        <AlertDialog
          openDialog={abrirAlerta}
          handleClose={handleCerrarAlerta}
          message={mensaje}
          title={titulo}
          severity={tipoAlerta}
          url={direccionUrl}
        />
      </Grid>
      <Grid item container xs={12} sm={6} md={12} spacing={5} style={{margin: "10px 0"}} >
        <BreadScrumbs listLink={listLink}/>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        md={12}
        align="center"
        justify="center"
        spacing={5}
        style={{ padding: 30 }}
      >
        <Box
          bgcolor={"white"}
          boxShadow={1}
          borderRadius={5}
          className={classes.cardComponent}
        >
          <div style={{ padding: "10px" }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ justifyContent: "flex-start" }}
            >
              <Grid item md={12} style={{ textTransform: "none" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Paso 1: seleccionar el paciente / oficina
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ justifyContent: "flex-start" }}
            >
              <Grid item>
                <LocalHospitalIcon />
              </Grid>
              <Grid item md={2}>
                <TextField
                  required
                  className={classes.textField}
                  onChange={handleNombreAsistente}
                  value={nombreAsistente}
                  id="input-with-icon-grid"
                  placeholder="ej- Pedro lemebel quiteros"
                  label="Nombre asistente"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ justifyContent: "flex-start" }}
            >
              <Grid item>
                <AccountCircle />
              </Grid>
              <Grid item md={2}>
                {!updateView && (
                  <Select
                    className={classes.textField}
                    placeholder="Seleccione paciente / oficina *"
                    closeMenuOnSelect={true}
                    options={listaPacientes}
                    onChange={handlePaciente}
                    value={paciente}
                    isSearchable
                    menuShouldScrollIntoView={false}
                    noOptionsMessage={() => "Debe selecionar un paciente / oficina"}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      menu: provided => ({
                        ...provided,
                        zIndex: "9999 !important"
                      }),
                      position: "fixed !important"
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ justifyContent: "flex-start" }}
            >
              <Grid item>
                <HomeIcon />
              </Grid>
              <Grid item md={2}>
                <TextField
                  InputProps={{ readOnly: true }}
                  required
                  className={classes.textField}
                  onChange={handleDireccionPaciente}
                  value={direccionPaciente}
                  id="input-with-icon-grid"
                  placeholder="ej- av 23 de febrero 8586, La reina"
                  label="Dirección paciente / oficina"
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        md={12}
        align="center"
        justify="center"
        spacing={5}
        style={{ padding: 30 }}
      >
        <Card className={classes.cardComponent}>
          <CardContent>
            <Grid style={{ justifyContent: "flex-start" }}>
              <Grid item md={12} style={{ textTransform: "none" }}>
                {/* <Typography variant="subtitle2" gutterBottom>
                  Paso 2: Capturar la imagen
                </Typography> */}
                <Typography variant="subtitle2" gutterBottom>
                  Paso 2: Clic en el botón para registrar asistencia.
                </Typography>
              </Grid>
              {/* <Grid item md={12} style={{ textTransform: "none" }}>
                <Webcam
                  videoConstraints={videoConstraints}
                  width={350}
                  height={350}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
              </Grid> */}
            </Grid>
            <Grid item sm={12} style={{padding: 5}}>


              { validation &&
              <>
                <Typography variant="caption" gutterBottom>
                    Posecion de Tablet en domicilio del paciente?
                </Typography>
                <Select
                className={classes.textField}
                placeholder="Posesión  de Tablet en domicilio del paciente?"
                closeMenuOnSelect={true}
                options={[{value: false, label: "No"}, {value: true, label: "Sí"}]}
                onChange={setpossessionTablet}
                value={possessionTablet}
                noOptionsMessage={() => "Debe indicar si tiene posesión de la tablet al momento de marcar asistencia"}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  menu: provided => ({
                    ...provided,
                    zIndex: "9999 !important"
                  }),
                  position: "fixed !important"
                }}
              />
              </>
              }
              
              </Grid>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justify="center"
            >
              
              <Grid item sm={12} md={12} lg={12}>
                {/* <Button
                  size="medium"
                  variant="contained"
                  style={{
                    border: "none",
                    outline: "none",
                    textTransform: "none",
                    padding: "5px"
                  }}
                  color="primary"
                  onClick={() => capture(true)}
                >
                  Capturar foto y registrar asistencia
                </Button> */}
                <Grid item sm={12} style={{padding: 5}}>
                  {/* <Typography variant="body2" gutterBottom>
                    ¿Problemas para registrar asistencia?
                  </Typography> */}
                  {/* <Typography variant="body2" gutterBottom>
                    Click en el siguiente boton
                  </Typography> */}
                  <Button
                    disabled={paciente?.value ? (validation ? (possessionTablet?.value === undefined ? true : false) : false ) : true }
                    size="medium"
                    variant="contained"
                    style={{
                      border: "none",
                      outline: "none",
                      textTransform: "none",
                      padding: "5px"
                    }}
                    color="primary"
                    onClick={() => capture(false)}
                >
                 registrar asistencia sin foto
                </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
