import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Apps from "@material-ui/icons/Apps";
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ChangePassword from "views/ChangePassword/ChangePassword";
import Dashboard from "views/Dashboard/Dashboard";
import Options from "views/Options/Options";
import Downloads from "views/Downloads/Downloads";
import Attendance from "views/Attendance/Attendance";
import Recovery from "views/Password/Recovery";
import ManagementRequest from "views/Request/ManagementRequest/ManagementRequest";
import Request from "views/Request/Request";
import {
  requestHumanResources,
  view_personal_file,
  manage_personal_file,
  edit_personal_file,
  delete_personal_file,
  remunerations,
  endowments
} from "./HumanResources/HumanResourcesRoutes";
import {
  clinicRequests,
  clinicEndowments,
  clinicOrders,
  clinicEndowmentsDetail,
  covenants,
  benefits,
  contracts,
  covenantsdetail,
  documents,
  packages,
  patientFile,
  patientFileDetails,
  clinicRequest,
  assignments,
  billing_tasks,
  billing,
} from "./BillingAndCollection/BillingAndCollectionRoutes";
import {
  warehouseStorage,
  warehousePharmacy,
  outcomeStorage,
  outcomePharmacy,
  incomeStorage,
  incomePharmacy,
  purchases,
  transport_all,
  transport_only_boss,
  transport_only_driver,
  boss,
} from "./Operations/OperationsRoutes";
import {
  rotary,
} from "./PeopleManagment/PeopleManagementRoutes";

export const attendance_login = {
  path: "/attendance/login",
  component: Attendance,
  layout: "/admin",
  invisible: true
};

export const password_recovery = {
  path: "/password/recovery/:id",
  component: Recovery,
  layout: "/admin",
  invisible: true
};

export const attendance_logout = {
  path: "/attendance/logout",
  component: Attendance,
  layout: "/admin",
  invisible: true
};

export const change_password = {
  path: "/changepassword/",
  component: ChangePassword,
  layout: "/admin",
  invisible: true
};

export const dashboard = {
  path: "/dashboard",
  name: "Dashboard",
  rtlName: "لوحة القيادة",
  icon: DashboardIcon,
  component: Dashboard,
  layout: "/admin"
};

export const options = {
  path: "/options",
  name: "Opciones",
  rtlName: "لوحة القيادة",
  icon: SettingsIcon,
  component: Options,
  layout: "/admin"
};

export const download = {
  path: "/downloads",
  name: "Descargas",
  rtlName: "لوحة القيادة",
  icon: CloudDownloadIcon,
  component: Downloads,
  layout: "/admin"
};

export const request = {
  path: "/request/",
  name: "Solicitudes",
  icon: FolderOpenIcon,
  component: Request,
  layout: "/admin"
}

export const request_ = {
  path: "/request/",
  name: "Mis tareas",
  rtlName: "رد فعل الطاولة",
  mini: ".",
  rtlMini: "در",
  component: Request,
  layout: "/admin"
};

export const management_request = {
  path: "/managementrequest/",
  name: "Solicitudes",
  icon: FolderOpenIcon,
  component: ManagementRequest,
  layout: "/admin"
};

export const management = {
  collapse: true,
  name: "Gerencia",
  icon: BlurLinearIcon,
  state: "GerenciaCollapse",
  views: []
};

export const human_resources_all = {
  collapse: true,
  name: "Recursos Humanos",
  rtlName: "الجداول",
  icon: GridOn,
  state: "RRHHCollapse",
  views: [
    requestHumanResources,
    view_personal_file,
    manage_personal_file,
    edit_personal_file,
    delete_personal_file,
    // contract_request,
    remunerations,
    request_,
    endowments,
    // personal_file,
    clinicEndowmentsDetail
  ]
};

export const human_resources_no_remunerations = {
  collapse: true,
  name: "Recursos Humanos",
  rtlName: "الجداول",
  icon: GridOn,
  state: "RRHHCollapse",
  views: [
    requestHumanResources,
    view_personal_file,
    manage_personal_file,
    edit_personal_file,
    delete_personal_file,
    // contract_request,
    request_,
    endowments,
    // personal_file,
    clinicEndowmentsDetail
  ]
};

export const billing_and_collection_all = {
  collapse: true,
  name: "Facturación y Cobranzas",
  rtlName: "إستمارات",
  icon: "content_paste",
  state: "facturacionCollapse",
  views: [billing_tasks, patientFile, patientFileDetails, contracts, billing]
};

export const collection_all = {
  collapse: true,
  name: "Facturación y Cobranzas",
  rtlName: "إستمارات",
  icon: "content_paste",
  state: "facturacionCollapse",
  views: [billing_tasks, patientFile, patientFileDetails, contracts]
};

export const clinic_all = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
    assignments,
    rotary,
    clinicOrders
  ]
};

export const clinic_except_endowments = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
    assignments,
    rotary,
    clinicOrders
  ]
};

export const clinic_bennefit_clinicOrders = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
    clinicOrders
  ]
};

export const clinic_all_except_rotary = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
    clinicOrders,
    assignments,
  ]
};

export const clinic_bennefit = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
  ]
};

export const clinic_collocation = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    patientFile,
    patientFileDetails,
    documents,
    rotary,
  ]
};

export const clinic_except_clinicOrders = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
    assignments,
    rotary,
  ]
};

export const clinic_except_clinicOrders_rotary = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    covenants,
    benefits,
    patientFile,
    patientFileDetails,
    covenantsdetail,
    documents,
    packages,
    assignments,
  ]
};


export const clinic_except_assinments = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    patientFile,
    patientFileDetails,
    documents,
    rotary,
  ]
};

export const clinic_coordinators = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequests,
    clinicEndowments,
    clinicEndowmentsDetail,
    clinicRequest,
    patientFile,
    patientFileDetails,
    documents,
    assignments,
  ]
};

export const clinic_rotary_file_patient = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicEndowments,
    patientFile,
    patientFileDetails,
    documents,
    packages,
    rotary,
  ]
};

export const clinic_only_work_file_patient = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequest,
    patientFile,
    patientFileDetails,
    documents,
    packages,
  ]
};

export const clinic_only_work_file_patient_and_assignments = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequest,
    patientFile,
    patientFileDetails,
    documents,
    packages,
    assignments,
  ]
};

export const clinicFinance = {
  collapse: true,
  name: "Clinica",
  rtlName: "صفحات",
  icon: Image,
  state: "clinicaCollapse",
  views: [
    clinicRequest,
  ]
};

export const operations_all = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    warehouseStorage,
    warehousePharmacy,
    outcomeStorage,
    outcomePharmacy,
    incomeStorage,
    incomePharmacy,
    purchases,
    transport_all,
    boss
  ]
};

export const operations_income = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    incomeStorage,
  ]
};

export const operations_purchases = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    purchases,
  ]
};

export const operations_outcome = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    outcomeStorage,
  ]
};

export const operations_only_warehouse = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    warehouseStorage,
  ]
};

export const operations_warehouse = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    warehouseStorage,
    warehousePharmacy,
  ]
};


export const operations_only_pharmacy = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    warehousePharmacy,
    outcomePharmacy,
    incomePharmacy,
  ]
};

export const operations_only_transport_boss = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    transport_only_boss,
  ]
};

export const operations_only_transport_driver = {
  collapse: true,
  name: "Operaciones",
  rtlName: "المكونات",
  icon: Apps,
  state: "operacionesCollapse",
  views: [
    transport_only_driver,
  ]
};

