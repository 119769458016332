import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  Divider,
  Button,
  FormControlLabel,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import {
  Root,
  CustomRadio,
  TypographyMui as Typography,
} from "utils/componentsStyle"
import {indigo} from "@material-ui/core/colors";
import { DoneAll, Info, Warning} from "@material-ui/icons/";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import CardStatistics from "views/Widgets/FichaPaciente/CardStatistics";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import {
  setdictEquipRelSpecific,
  setDiscountQuotation,
} from "actions/getGlobalAction"

import BenefitTable from "components/PatientFile/BenefitTable";
import NavPills from "components/NavPills/NavPills.js";;
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import allActions from "actions";

export default function ModalQuotationPatient(props) {
  const dispatch = useDispatch();
  const { open, closeModal, quotationDataPatient } = props;

  const [equipData, setequipData] = useState({});
  const [workersData, setworkersData] = useState({});
  const [emergencyData, setemergencyData] = useState({});
  const [otherData, setotherData] = useState({});
  const [dataMedicalArray, setdataMedicalArray] = useState(null);
  const [dataResourseArray, setdataResourseArray] = useState(null);
  const [dataProfessionalArray, setdataProfessionalArray] = useState(null);
  const [dataEquipArray, setdataEquipArray] = useState(null);
  const [dataEmergencyArray, setdataEmergencyArray] = useState(null);
  const [dataOtherArray, setdataOtherArray] = useState(null);
  const [initialState, setinitialState] = useState(true);
  const [showPackage, setshowPackage] = useState(null);

  const packageQuotationData = quotationDataPatient[showPackage ? "unbalanced" : "balanced"];

  const resourceTotal = packageQuotationData.insumos;
  const medicineTotal = packageQuotationData.medicamentos;
  const dayBed = packageQuotationData.dia_cama;
  const medicalResourseTotal = resourceTotal + medicineTotal;
  const exemptTotal = packageQuotationData.total_excento;
  const monthTotal = packageQuotationData.total_mes

  const typeDiscount = packageQuotationData.tipo_porcentaje;
  const valuePercentage = packageQuotationData.porcentaje_item

  const benefitsPackage = packageQuotationData.prestaciones;

  const ammountResourcePackage = useSelector(state => state.currentGlobalVar.ammountResourcePackage);
  const ammountMedicinePackage = useSelector(state => state.currentGlobalVar.ammountMedicinePackage);

  const medicalResourseBase =  ammountResourcePackage + ammountMedicinePackage

  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const dictEquipGeneric = useSelector(state => state.currentList.dictEquipGeneric);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const openBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

  useEffect(() => {

    if(Object.keys(medicalResourceData).length === 0) {
      getListSuppliesMedicines(dispatch, chargeMedicine);
    }
    if (Object.keys(dictEquipGeneric).length === 0){
      setdictEquipRelSpecific(dispatch);
    }
  } , [])

  useEffect(() => {
    if (showPackage !== null){
      changePackage()
    }
  } , [showPackage])

  useEffect(() => {
    if (initialState && Object.keys(medicalResourceData).length > 0 && Object.keys(dictEquipGeneric).length > 0){
      initialProcess();
    }
  })


  const changePackage = () => {
    setDiscountQuotation(
      packageQuotationData.porcentaje_item || 0,
      packageQuotationData.tipo_porcentaje || null,
      dispatch
    );

    const auxDiscount = packageQuotationData.descuentos.reduce((accum,obj) => ({
      ...accum,
      [
        obj.id_prestaciones ? `${obj.id_prestaciones_id}_prestacion_${obj.fuera_paquete}`:
        obj.id_insumo_medicamento ? `${obj.id_insumo_medicamento_id}_insumos_medicamentos_${obj.fuera_paquete}`:
        obj.id_equipo ? `${obj.id_equipo}_equipo_${obj.fuera_paquete_id}` : null
      ]: obj
    }), {});
    dispatch({
      type: "DICCIONARIO_DESCUENTOS_COTIZACION",
      payload: auxDiscount
    })

    const tempTotal =  quotationDataPatient["balanced"].prestaciones.filter((row) => row.id_insumo_medicamento_id).reduce((accum,obj) => {return accum + (obj.cantidad * obj.valor)}, 0)

    const auxDataMedicalArray = [];
    const auxDataResourseArray = [];
    const auxDataProfessionalArray = [];
    const auxDataEquipArray = [];
    const auxDataEmergency = [];
    const auxDataOther = [];
    const tempDictItem = {}

    quotationDataPatient["balanced"].prestaciones.filter((row) => row.id_insumo_medicamento_id).sort((a,b) => {
      if (a.fuera_paquete){
        return 1;
      } else if(b.fuera_paquete){
        return -1;
      }
      return 0;
    }).map((row) => {
      const tempRowSource = medicalResourceData[row.id_insumo_medicamento_id];
      if (tempRowSource){
        if (["Insumos", "Articulos", "Alimentos"].includes(tempRowSource.grupo_producto)) {
          if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
            auxDataResourseArray.push({...row,  id_insumo_medicamento: tempRowSource, id_paquete_cotizacion: packageQuotationData,});
          }
        }
        if ( tempRowSource.grupo_producto === "Medicamentos" ) {
          if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
            auxDataMedicalArray.push({...row, id_insumo_medicamento: tempRowSource, id_paquete_cotizacion: packageQuotationData,});
          }
        }
      }
    });

    benefitsPackage.sort((a,b) => {
      if (a.fuera_paquete){
        return 1;
      } else if(b.fuera_paquete){
        return -1;
      }
      return 0;
    }).map((row) => {
      const tempRowPrest = workersData[row.id_prestaciones_id] || emergencyData[row.id_prestaciones_id] || otherData[row.id_prestaciones_id];
      const tempRowEquip = equipData[row.id_equipo_id];

      if (tempRowPrest){
        if ( tempRowPrest.grupo === "Profesionales") {
          if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
            auxDataProfessionalArray.push({...row, id_prestaciones: tempRowPrest,});
          }
        }
        if ( tempRowPrest.grupo === "Emergencias") {
          if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
            auxDataEmergency.push({...row, id_prestaciones: tempRowPrest, id_paquete_cotizacion: packageQuotationData,});
          }
        }
        if ( tempRowPrest.grupo === "Traslados" || tempRowPrest.grupo === "Otros") {
          if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
            auxDataOther.push({...row, id_prestaciones: tempRowPrest, id_paquete_cotizacion: packageQuotationData,});
          }
        }
      }
      if (tempRowEquip){
        if ( tempRowEquip.tipo_producto === "EquiposMedicos") {
          let tempValor = row.valor
          if(!row.valor){
            tempValor = row.id_equipo_id?.valor_boleta || 0;
          }
          if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
            if (row.id_prestacion_cotizacion_padre){
              if (tempDictItem[row.id_prestacion_cotizacion_padre_id]){
                tempDictItem[row.id_prestacion_cotizacion_padre_id].push({...row})
              } else {
                tempDictItem[row.id_prestacion_cotizacion_padre_id] = ([{...row}])
              }

            } else{
              auxDataEquipArray.push({...row, valor: tempValor, id_prestaciones: tempRowPrest, id_equipo: tempRowEquip, id_paquete_cotizacion: packageQuotationData,});
            }
          }
        }
      }
    });
    const tempDataEquipArray = auxDataEquipArray.map((row) => {
      const tempRow = {...row};
      tempRow.items = tempDictItem[row.id] ? tempDictItem[row.id] :[];
      return {...tempRow}
    });
    setdataMedicalArray(auxDataMedicalArray);
    setdataResourseArray(auxDataResourseArray);
    setdataProfessionalArray(auxDataProfessionalArray);
    setdataEquipArray(tempDataEquipArray);
    setdataEmergencyArray(auxDataEmergency)
    setdataOtherArray(auxDataOther);

    dispatch(allActions.setGlobalVar.setammountValuePackage(tempTotal));

  }



  const initialProcess = () => {
    setinitialState(false);

    const requestCharges = api.get(`api/prestacionesclinica/`);
    const requestEquipo = api.get(`api/equipos/tipos/`);
    Promise.all([requestCharges, requestEquipo ]).then((request) => {
      const auxWorkersData = {};
      const auxEquipData = {};
      const auxEmergencyData = {};
      const auxOtherData = {};

      request[0].data.map((row) => {
        if (row.grupo === "Profesionales") {
          auxWorkersData[row.id] = row;
        }
        if (row.grupo === "Emergencias") {
          auxEmergencyData[row.id] = row;
        }
        if (row.grupo === "Traslados" || row.grupo === "Otros") {
          auxOtherData[row.id] = row;
        }
      });
      request[1].data.map((row) => {
        auxEquipData[row.id] = row;
      });

      setworkersData(auxWorkersData);
      setequipData(auxEquipData);
      setemergencyData(auxEmergencyData);
      setotherData(auxOtherData);
      handleShowPackage();
    })
  }

  const handleShowPackage = (value=false) => {
    setshowPackage(value);

  }

  const listCard = [
    {editable: false, amountTotal:dayBed, title:"Día cama", subTitle:"Recursos asignados", IconTitle:Info, IconSub:Info},
    {editable: false, amountTotal:exemptTotal, title:"Total exento", subTitle:"Recursos asignados", IconTitle:Info, IconSub:Info},
    {editable: false, amountTotal:monthTotal, title:"Total mes", subTitle:"Recursos asignados", IconTitle:Info, IconSub:Info},
    {editable: false, amountTotal:medicalResourseTotal, amountBase:0, title:"Insumos / Medicamentos", subTitle:"Recursos por asignar", subTitleError:"Recursos excedidos", IconTitle:DoneAll, IconSub:Info}
  ]

  const _content_ = <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    item
    xs={12}
  >
    {quotationDataPatient.unbalanced &&
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Switch
                checked={showPackage}
                onChange={() => handleShowPackage(!showPackage)}
            />
          }
          label={`Paquete con descompensación ${showPackage? "activado" : "desactivado"}`}
        />
      </Grid>
    }
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      {listCard.map((row, index) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        style={{ padding: "1%" }}
        key={`card-quotation-${index}`}
      >
        <CardStatistics
        {...row}
        />
      </Grid>
      ))}
    </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={10}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        style={{margin: "50px 0"}}
      >
        <Typography variant="h6" style={{color: indigo[500]}} >
          Descuento o recargo general
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          xs={6}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={12}
            style={{margin: "12px 0 0 0"}}
          >
            {["Descuento", "Recargo"].map((row, index) => (
              <FormControlLabel
              control={
                <CustomRadio
                    disabled={true}
                    value="Descuento"
                    checked={typeDiscount === row}

                />
              }
              label={row} style={{textTransform: "none", color: "#0000005e" }}
            />
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          xs={1}
        >
          <Typography variant="body1" style={{color: indigo[500]}} >
            Porcentaje
          </Typography>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={12}
            style={{margin: "12px 0 0 0"}}
          >
            <Typography variant="body1" style={{color: indigo[500]}} >
              {`${typeDiscount === "Descuento" ? "-" : "+"} ${valuePercentage} %`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <Root>
      <Divider
        style={{
        border: "1px solid #303F9F",
        margin: "40px 0"
      }}/>
      </Root>
    </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
      style={{ margin: "0 0 20px 0" }}
    >
       {([dataMedicalArray,dataResourseArray,dataProfessionalArray,dataEquipArray,dataEmergencyArray,dataOtherArray].every((row) => row !== null) && !openBackdrop && showPackage !== null) ?
      <Grid item xs={12}>
        <NavPills
          color="primary"
          alignCenter
          tabs={[
            {
              tabButton: "Profesionales",
              tabIcon:  DoneAll,
              tabContent: (
                <BenefitTable
                  dataArray={dataProfessionalArray}
                  setdataArray={() => {}}
                  listBenefit={[]}
                  benefitData={workersData}
                  selectedPackage={packageQuotationData}
                  deleteArray={[]}
                  setdeleteArray={() => {}}
                  updateArray={[]}
                  setupdateArray={() => {}}
                  typeRequest={"Cotización"}
                  stateRequest={"Finalizada"}
                  title={"Profesional"}
                  type={"prestacion"}
                />
              )
            },
            {
              tabButton: "Equipos",
              tabIcon: DoneAll,
              tabContent: (
                <BenefitTable
                  dataArray={dataEquipArray}
                  setdataArray={() => {}}
                  listBenefit={[]}
                  benefitData={equipData}
                  selectedPackage={packageQuotationData}
                  deleteArray={[]}
                  setdeleteArray={() => {}}
                  updateArray={[]}
                  setupdateArray={() => {}}
                  typeRequest={"Cotización"}
                  stateRequest={"Finalizada"}
                  title={"Equipo"}
                  type={"equipo"}
                />
              )
            },
            {
              tabButton: "Emergencia",
              tabIcon: DoneAll,
              tabContent: (
                <BenefitTable
                  dataArray={dataEmergencyArray}
                  setdataArray={() => {}}
                  listBenefit={[]}
                  benefitData={emergencyData}
                  selectedPackage={packageQuotationData}
                  deleteArray={[]}
                  setdeleteArray={() => {}}
                  updateArray={[]}
                  setupdateArray={() => {}}
                  typeRequest={"Cotización"}
                  stateRequest={"Finalizada"}
                  title={"Prestación"}
                  type={"prestacion"}
                />
              )
            },
            {
              tabButton: "Medicamentos",
              tabIcon: medicalResourseBase > 0 ? DoneAll : Warning,
              tabContent: (
                <BenefitTable
                  dataArray={dataMedicalArray}
                  setdataArray={() => {}}
                  listBenefit={[]}
                  benefitData={medicalResourceData}
                  selectedPackage={quotationDataPatient["balanced"]}
                  deleteArray={[]}
                  setdeleteArray={() => {}}
                  updateArray={[]}
                  setupdateArray={() => {}}
                  typeRequest={"Cotización"}
                  stateRequest={"Finalizada"}
                  title={"Medicamentos"}
                  type={"insumos_medicamentos"}
                />
              )
            },
            {
              tabButton: "Insumos",
              tabIcon: medicalResourseBase > 0 ? DoneAll : Warning,
              tabContent: (
                <BenefitTable
                  dataArray={dataResourseArray}
                  setdataArray={() => {}}
                  listBenefit={[]}
                  benefitData={medicalResourceData}
                  selectedPackage={quotationDataPatient["balanced"]}
                  deleteArray={[]}
                  setdeleteArray={() => {}}
                  updateArray={[]}
                  setupdateArray={() => {}}
                  typeRequest={"Cotización"}
                  stateRequest={"Finalizada"}
                  title={"Insumos"}
                  type={"insumos_medicamentos"}
                />
              )
            },
            {
              tabButton: "Otros",
              tabIcon: DoneAll,
              tabContent: (
                <BenefitTable
                  dataArray={dataOtherArray}
                  setdataArray={() => {}}
                  listBenefit={[]}
                  benefitData={otherData}
                  selectedPackage={packageQuotationData}
                  deleteArray={[]}
                  setdeleteArray={() => {}}
                  updateArray={[]}
                  setupdateArray={() => {}}
                  typeRequest={"Cotización"}
                  stateRequest={"Finalizada"}
                  title={"Prestación"}
                  type={"prestacion"}
                />
              )
            }
          ]}
        />

      </Grid> : <CircularProgress style={{margin: "0 auto"}}/>}
    </Grid>
  </Grid>

  const _actions_ = <>
   <Button
      onClick={closeModal}
      color="primary"
      variant="contained"
    >
      Cerrar
    </Button>

  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={"Ver cotización"}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="xl"
    />
  );
}