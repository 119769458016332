import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import OrderDetailInfo from "components/WareHouse/Orders/OrderDetail";
import ModalReplaceSKU from "./ModalReplaceSKU";

import apiform from "utils/APIForm";
import ModalSaved from "./ModalSaved";
import ModalCancelReplace from "./ModalCancelReplace";
import ModalReplaceInsumos from "./ModalReplaceInsumos";
import ModalSendComplete from "./ModalSendComplete";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import ModalCancelRequest from "./ModalCancelRequest";
import TimeLine from "./TimeLine/TimeLine";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(2.5),
    textTransform: "none",
    minHeight: "3rem",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    color: "#fff",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function OrderDetail() {
  const classes = useStyles();
  const theme = useTheme();
  const [lastUpdate, setLastUpdate] = useState(moment());

  const value = 0;


  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [module, setmodule] = useState("");
  const [actualGroup, setactualGroup] = useState("");

  const id_order = parseInt(id, 10);

  const [loading, setLoading] = useState(false);

  const [id_producto, setId_producto] = useState("");

  const [modalEditSupply, setModalEditSupply] = useState(false);
  const [modalSendComplete, setModalSendComplete] = useState(false);
  const [modalSaved, setModalSaved] = useState(false);
  const [modalCancelReplace, setModalCancelReplace] = useState(false);
  const [modalReplaceInsumos, setModalReplaceInsumos] = useState(false);
  const [show_Save, setShow_Save] = useState(false);
  const [order, setOrder] = useState([]);

  const [replace, setReplace] = useState(false);

  const [checkArray, setCheckArray] = useState([]);

  const [modalCancelRequest, setModalCancelRequest] = useState(false);

  const [getProducts, setGetProducts] = useState([]);
  const [verNotaVenta, setverNotaVenta] = useState(false);
  const enviosPedidos = [];
  const [timeLine, setTimeLine] = useState([]);
  const [getProductsComplete, setGetProductsComplete] = useState([]);
  const [getProductsOther, setGetProductsOther] = useState([]);

  const [changes, setChanges] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [observation, setObservation] = useState("");
  const [file, setFile] = useState(null);
  const [boxNumber, setboxNumber] = useState(0);
  const [showTimeLine, setShowTimeLine] = useState(false);
  const [infoProduct, setinfoProduct] = useState(null)
  const [fileInfo, setfileInfo] = useState(null)

  const tempArrayURL = window.location.href.split("/");
  const tempModuleType = tempArrayURL[tempArrayURL.length - 1];


  useEffect(() => {
    if (changes) {
      initialChargeReception();
      loadSolicitud();
      // loadEnvios();
    }
  });

  const loadSolicitud = () => {
    const requestGetRequest = api.get(`api/solicitudpedidos/${id_order}`);
    Promise.all([requestGetRequest]).then((response) => {
        setOrder(response[0].data);
        if (response[0].data.estado_solicitud === "Esperar a egresos") {
          //setIsWaitForOutcome(true);
        }
      })

  };

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 2];
    const tempModule = tempArrayURL[tempArrayURL.length - 4];
    setmoduleType(tempModuleType);
    setmodule(tempModule.toLocaleLowerCase())
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    setactualGroup(tempModuleType === "storage" ? "insumo" : "farmacia");
    const getProducts = api.get(`api/bodega/pedidos/grupo/${group}/detalle/${id_order}`);
    const getLastUpdate = api.get("/api/plataforma/stockmasivo/");
    const getTimeLine = api.get(`api/bodega/pedidos/grupo/${group}/detalle/${id_order}/timeline/`);
    Promise.all([getProducts, getLastUpdate, getTimeLine])
      .then((response) => {
        const tempData = response[0].data;
        const estado = tempData[0].id_solicitud_pedido[`estado_solicitud_${group === "Insumos" ? "insumo" : "farmacia"}`];
        if (estado === "Hacer picking" || estado === "Continuar picking") {
          setboxNumber(0);
        } else {
          setboxNumber(response[2].data.length);
        }

        const requestFilter = tempData.filter((filtered) => ((filtered.estado === "Incompleta" || filtered.estado === "Incompleto") && filtered.reemplazado === false) || (filtered.estado === "Completa" && filtered.picking > 0));
        const requestComplete = tempData.filter((filtered) => filtered.estado === "Completa" && filtered.picking === 0 )
        const requestOher = tempData.filter((filtered) => filtered.estado !== "Incompleta" && filtered.estado !== "Incompleto" && filtered.estado !== "Completa")

        setverNotaVenta(tempData.filter((filtered) =>(filtered.numero_nota_venta)).length > 0)
        setGetProducts([...requestFilter]);
        setCheckArray([...requestFilter]);
        setGetProductsComplete(requestComplete);
        setGetProductsOther(requestOher);
        setLastUpdate(response[1].data.valor);
        setTimeLine(response[2].data);
        setShowTimeLine(true);

      })
    setLoading(true);
  };

  const setModalEdit = (id_producto, modal_edit, info=null) => {
    setId_producto(id_producto);
    setModalEditSupply(modal_edit);
    setinfoProduct(info)
  };

  const setModalCancel = (id_producto, modal_edit, info=null) => {
    setId_producto(id_producto);
    setModalCancelReplace(modal_edit);
    setinfoProduct(info)
  };

  const setModalInsumo = (id_producto, modal_activate) => {
    setId_producto(id_producto);
    setModalReplaceInsumos(modal_activate);
  };

  const handleAcceptReplacement = (item) => {
    const tempProducts = getProducts.filter((row) => row.id !== id_producto )
    const tempOtherProducts = [...getProductsOther]
    const itemReplace =  getProducts.filter((row) => row.id === id_producto )[0]

    itemReplace.estado_reemplazo = "Aprobado"
    itemReplace.reemplazado = true
    itemReplace.estado = "Reemplazado"

    item.cantidad = parseInt(item.cantidad)
    item.cantidad_por_enviar = parseInt(item.cantidad_por_enviar)
    item.picking = parseInt(item.picking)

    tempOtherProducts.push(itemReplace)
    tempProducts.push(item)
    setGetProducts(tempProducts)
    setGetProductsOther(tempOtherProducts)
    setModalReplaceInsumos(false)
  }

  const handleRequestReplacement = () => {
    const tempProducts = getProducts.filter((row) => row.id !== id_producto )
    const tempOtherProducts = [...getProductsOther]
    const itemReplace =  getProducts.filter((row) => row.id === id_producto )[0]
    itemReplace.estado_reemplazo = "Solicitado"
    itemReplace.estado = "Solicitado"

    tempOtherProducts.push(itemReplace)
    setGetProducts(tempProducts)
    setGetProductsOther(tempOtherProducts)
    setModalEditSupply(false)
  }

  const handlePicking = (event, index) => {
    const tempProduct = [...getProducts];
    const tempRow = { ...tempProduct[index] };
    const tempCheck = { ...checkArray[index] };
    const checkAmount = tempCheck.cantidad_por_enviar;
    tempRow.cantidad_por_enviar = checkAmount - parseInt(event);
    tempRow.picking = parseInt(event);
    if (tempRow.cantidad === parseInt(event)) {
      tempRow.estado = "Completa";
    } else if (tempRow.cantidad < parseInt(event)) {
      tempRow.estado = "Incompleta";
      tempRow.picking = tempRow.cantidad
    } else if (tempRow.cantidad > parseInt(event)) {
      tempRow.estado = "Incompleta";
    } 
    tempProduct[index] = { ...tempRow };
    if (tempRow.cantidad_por_enviar >= 0) {
      setGetProducts([...tempProduct]);
    }
  };


  let count_show_save = 0;

  useEffect(() => {
    getProducts.filter(
      (product) => product.estado_reemplazo === "No solicitado" ||  product.estado_reemplazo === "Rechazado" || product.estado_reemplazo === null
      ).map((filtered) => {
        if (filtered.picking != 0) {
          count_show_save = count_show_save + 1;
        }
      });

  });

  useEffect(() => {
    if (count_show_save != 0) {
      setShow_Save(true);
    } else {
      setShow_Save(false);
    }
    if (
      getProducts.filter((product) => product.estado_reemplazo === "Solicitado")
        .length != 0
    ) {
      setReplace(true);
    } else {
      setReplace(false);
    }
  });

  const updateStock = () => {


    const getStockSoftland = api.get(
      "/api/poblarinsumos/"
    );

    Promise.all([getStockSoftland])
      .then(() => {
        setChanges(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: "primary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Add",
    },
    {
      color: "secondary",
      className: classes.fab,
      icon: <EditIcon />,
      label: "Edit",
    },
    {
      color: "inherit",
      className: clsx(classes.fab, classes.fabGreen),
      icon: <UpIcon />,
      label: "Expand",
    },
  ];

  const saveFile = (number=null, idSolicitud=null, idEnvio=null) => {
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 2];
    let group = "";
    if (tempModuleType === "storage") {
      group = "Insumos";
    } else {
      group = "Medicamentos";
    }

    const checkComplete = getProducts.every((row) => row.estado === "Completa");

    const formDataRequest = new FormData();
    if (checkComplete) {
      formDataRequest.append("estado_picking", "Completa");
    } else {
      formDataRequest.append("estado_picking", "Incompleta");
    }
    if (replace) {
      formDataRequest.append(
        "estado_solicitud",
        "Esperar aprobación de reemplazo área clínica"
      );
    } else if (show_Save) {
      formDataRequest.append("estado_solicitud", "Notificar a egresos");
    } else {
      formDataRequest.append("estado_solicitud", order.estado_solicitud);
    }

    

    let uploadArray = getProducts.filter((row, index) => JSON.stringify(checkArray[index]) !== JSON.stringify(getProducts[index])).map((upload) => {
      const tempUpload = { ...upload };
      tempUpload.id_insumo_medicamento = upload.id_insumo_medicamento.id;
      tempUpload.id_solicitud_pedido = upload.id_solicitud_pedido.id;
      if (tempUpload.id_producto_reemplazo != null) {
        tempUpload.id_producto_reemplazo = upload.id_producto_reemplazo.id;
      }
      return tempUpload
    });

    const formDataDoc = new FormData();
    formDataDoc.append("nombre", `Guía de despacho envío ${number}`);
    formDataDoc.append("tipo_archivo", "NotaVenta");
    formDataDoc.append("archivo", file);
    formDataDoc.append("observacion", observation);
    formDataDoc.append("area", "Bodega");
    formDataDoc.append("id_pedidos", id_order);

    const requestUpdate = apiform.patch(`api/solicitudpedidos/${id_order}`, formDataRequest );
    const requestItem =  api.put("api/ProductosPedidos/bulk", uploadArray);
    
    setfileInfo(formDataDoc)
    Promise.all([requestUpdate, requestItem]).then((request) => {
      setModalSendComplete(true);
    })

   
  };

  const task = module === "outcome" ? "tasks" : "task"
  const orders = module === "outcome" ? "receptions" : "orders"

  return (
    <GridContainer>
      <ViewTitle
        title="PEDIDOS"
        message={`En esta sección podrás revisar los pedidos de ${
          moduleType === "storage" ? "bodega" : "bodega farmacia"
        }.`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/${module}/${task}/${moduleType}`}>
              {`Tareas ${
                moduleType === "storage" ? "Bodega" : "Bodega Farmacia"
              }`}
            </Link>
            <Link
              color="inherit"
              href={`/admin/${module}/${orders}/${moduleType}`}
            >
              Pedidos
            </Link>
            <Typography color="textPrimary">Detalle del pedido</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo id={id_order} getApi={true} verNotaVenta={verNotaVenta} ArgOrder={order}/>
            </GridContainer>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              {showTimeLine && (
                <TimeLine
                  getProducts={getProducts}
                  order={order}
                  setModalCancel={setModalCancel}
                  setModalEdit={setModalEdit}
                  setModalInsumo={setModalInsumo}
                  handlePicking={handlePicking}
                  timeLine={timeLine}
                  checkArray={checkArray}
                  getProductsComplete={getProductsComplete}
                  getProductsOther={getProductsOther}
                  actualGroup={actualGroup}
                  setTabValue={setTabValue}
                  setModalCancelRequest={setModalCancelRequest}
                  moduleType={moduleType}
                  show_Save={show_Save}
                  observation={observation}
                  setObservation={setObservation}
                  file={file}
                  setFile={setFile}
                  saveFile={saveFile}
                  boxNumber={boxNumber}
                />
              )}
            </GridContainer>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalSaved && (
        <ModalSaved open={modalSaved} closeModal={() => setModalSaved(false)} />
      )}
      {modalEditSupply && (
        <ModalReplaceSKU
          open={modalEditSupply}
          closeModal={() => setModalEditSupply(false)}
          id_producto={id_producto}
          id_detalle={id_order}
          order={order}
          moduleType={moduleType}
          infoProduct={infoProduct}
          handleRequestReplacement={handleRequestReplacement}
        />
      )}
      {modalSendComplete && (
        <ModalSendComplete
          open={modalSendComplete}
          closeModal={() => setModalSendComplete(false)}
          id_order={id_order}
          enviosPedidos={enviosPedidos}
          uploadArrayEnviados={getProducts.filter((product) => product.picking > 0)}
          productos_restantes={getProducts.filter((product) => product.estado === "Incompleta")}
          fileInfo={fileInfo}
          moduleType={moduleType}
        />
      )}
      {modalCancelReplace && (
        <ModalCancelReplace
          open={modalCancelReplace}
          closeModal={() => setModalCancelReplace(false)}
          id_producto={id_producto}
          id_detalle={id_order}
          getProductsOther={getProductsOther}
          order={order}
          moduleType={moduleType}
          infoProduct={infoProduct}
        />
      )}
      {modalReplaceInsumos && (
        <ModalReplaceInsumos
          open={modalReplaceInsumos}
          closeModal={() => setModalReplaceInsumos(false)}
          id_producto={id_producto}
          id_detalle={id_order}
          moduleType={moduleType}
          handleAcceptReplacement={handleAcceptReplacement}
        />
      )}
      
      {modalCancelRequest && (
        <ModalCancelRequest
          open={modalCancelRequest}
          closeModal={() => setModalCancelRequest(false)}
          id_order={id_order}
          moduleType={moduleType}
        />
      )}

      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === index ? transitionDuration.exit : 0
            }ms`,
            backgroundColor: "#ff6f00",
          }}
          unmountOnExit
        >
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Última actualización:
                  {moment(lastUpdate).format("YYYY-MM-DD HH:mm")}
                </Typography>
              </React.Fragment>
            }
          >
            <Fab
              variant="extended"
              aria-label={fab.label}
              className={fab.className}
              color={fab.color}
              onClick={() => updateStock()}
            >
              Actualizar stock
            </Fab>
          </HtmlTooltip>
        </Zoom>
      ))}
    </GridContainer>
  );
}
