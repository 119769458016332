import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Grid from "@material-ui/core/Grid";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import ModalReplaceSKU from "./ModalReplaceSKU";
import ModalSendPending from "./ModalSendPending";
import Box from "@material-ui/core/Box";
import ModalSaved from "./ModalSaved";
import ModalCancelReplace from "./ModalCancelReplace";
import ModalReplaceInsumos from "./ModalReplaceInsumos";
import ReceptionInfo from "./ReceptionInfo";
import ModalRejectPending from "./ModalRejectPending";
import ModalRejectComplete from "./ModalRejectComplete";
import ModalSendSupervisorComplete from "./ModalSendSupervisor/ModalSendSupervisorComplete";
import ModalSendSupervisorPending from "./ModalSendSupervisor/ModalSendSupervisorPending";
import ModalCancelSendSupervisor from "./ModalCancelSendSupervisor/ModalCancelSendSupervisor";
import ModalSendComplete from "./ModalSendComplete/ModalSendComplete";
import ModalAceptReceptionComplete from "./ModalAceptReceptionComplete";
import ModalAceptReceptionPending from "./ModalAceptReceptionPending";
import ModalTransport from "./ModalTransport";
import api from "utils/API";
import apiform from "utils/APIForm";


export default function ReceptionDetail() {

  const { id } = useParams();
  const [moduleType, setmoduleType] = React.useState("");
  const id_order = parseInt(id, 10);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id_producto, setId_producto] = useState("");
  const [modalEditSupply, setModalEditSupply] = useState(false);
  const [modalSendPending, setModalSendPending] = useState(false);
  const [modalSaved, setModalSaved] = useState(false);
  const [modalCancelReplace, setModalCancelReplace] = useState(false);
  const [modalReplaceInsumos, setModalReplaceInsumos] = useState(false);
  const [productosRestantes, setProductosRestantes] = useState([]);
  const [order, setOrder] = useState([]);
  const [modalRejectPending, setModalRejectPending] = useState(false);
  const [modalRejectComplete, setModalRejectComplete] = useState(false);
  const [modalSendSupervisorPending, setModalSendSupervisorPending] = useState(false);
  const [modalSendSupervisorComplete, setModalSendSupervisorComplete] = useState(false);
  const [modalCancelSendSupervisor, setModalCancelSendSupervisor] = useState(false);
  const [modalSendComplete, setModalSendComplete] = useState(false);
  const [modalAceptReceptionComplete, setModalAceptReceptionComplete] = useState(false);
  const [modalAceptReceptionPending, setModalAceptReceptionPending] = useState(false);
  const [changes, setChanges] = useState(true);
  const [idRequest, setIdRequest] = useState("");
  const [getEnviosPedidos, setGetEnviosPedidos] = useState([]);
  const [file, setFile] = useState(null);
  const [getFiles, setGetFiles] = useState([]);
  const [observation, setObservation] = useState(null);
  const [transport, settransport] = useState(false)
  const [modalTransport, setmodalTransport] = useState(false)

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split('/');
    const tempModuleType = tempArrayURL[tempArrayURL.length-2];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`;
    const getProducts = api.get(`api/egresos/recepcion/grupo/${group}/envio/${id_order}/detalle/`);
    api.get(`api/enviospedidos/${id_order}`).then(response => {
        setGetEnviosPedidos(response.data);
        setIdRequest(response.data.id_solicitud_pedido);
        api.get(`api/solicitudpedidos/${response.data.id_solicitud_pedido}`).then(result => {
            setOrder(result.data);
        });
        api.get(`api/egresos/documentospedidos/solicitud/${response.data.id_solicitud_pedido}/?area=${groupFile}&tipo_archivo=GuiaDespacho&nombre=${`Guía de despacho caja ${response.data.numero_pedido}`}`).then(result2 => {
          setLoading(true);
          setGetFiles(result2.data);
        })
    })


    Promise.all([getProducts])
      .then(response => {
        setProducts(response[0].data);
        setLoading(true);
      })
      .catch(error => {
        console.log(error.response);j
      });
  };

  const setSendPendingModal = (modal_edit, productos_restantes) => {
    setModalRejectPending(modal_edit);
    setProductosRestantes(productos_restantes);
  };

  console.log(order)

  const colorGreen = {
    bgcolor: "#32CD32",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "1.5rem", height: "1.5rem" }
  };
  const colorRed = {
    bgcolor: "#FF0000",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "1.5rem", height: "1.5rem" }
  };
  const colorWhite = {
    bgcolor: "#FFFFFF",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "1.5rem", height: "1.5rem" }
  };

  const rejectReception = () => {
    let count = 0;
    let productos_restantes = [];
    products.map(product => {
      if (product.id_producto_pedido.estado === "Completa" || product.id_producto_pedido.estado === "Completada") {
        count = count + 1;
      } else if (product.id_producto_pedido.estado === "Incompleta") {
        productos_restantes.push(product);
      } else {
        count = count + 1;
      }
    });
    if (count === products.length) {
      setModalRejectComplete(true);
    } else {
      setSendPendingModal(true, productos_restantes);
    }
  };

  function updateAndSend() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`;
    const formData = new FormData();

    if (file != null) {
      formData.append("archivo", file);
    }
    formData.append("nombre", `Guía de despacho caja ${products[0].numero_pedido}`);
    if (observation != "") {
      formData.append("observacion", observation);
    } else {
      formData.append("observacion", getFiles[0].observacion);
    }
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("area", groupFile);
    formData.append("id_pedidos", products[0].id_producto_pedido.id_solicitud_pedido.id);

    const body = {
      observacion: observation
    };

    const requestDoc = apiform.put(`api/documentospedidos/${getFiles[0].id}`, formData);
    //const requestUpdateReception = apiform.patch(`api/enviospedidos/${products[0].id_envio_pedido.id}`, formData2);
    const requestUpdateReception = api.patch(`api/envios/egresos/caja/${products[0].id_envio_pedido.id}/adjuntado/`, body);
    Promise.all([requestDoc, requestUpdateReception]).then(response => {
      window.location.href = `/admin/outcome/receptions/${moduleType}`;
    })
  }

  function saveAndSend() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`;
    const formData = new FormData();

    formData.append("nombre", `Guía de despacho caja ${products[0].numero_pedido}`);
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("archivo", file);
    formData.append("observacion", observation);
    formData.append("area", groupFile);
    formData.append("id_pedidos", products[0].id_producto_pedido.id_solicitud_pedido.id);

    const formData2 = new FormData();

    formData2.append("numero_pedido", products[0].id_envio_pedido.numero_pedido);
    formData2.append("estado_egreso", "Notificar a transporte");
    formData2.append("estado", "Notificar a transporte");
    const requestDoc = apiform.post("api/documentospedidos/", formData);
    //const requestUpdateReception = apiform.patch(`api/enviospedidos/${products[0].id_envio_pedido.id}`, formData2)

    const requestUpdateReception = api.patch(`api/envios/egresos/caja/${products[0].id_envio_pedido.id}/adjuntado/`)
    Promise.all([requestDoc, requestUpdateReception]).then(response => {
      settransport(true)
      setmodalTransport(true)
    })
     
  }

  const handleBack = () => {
    window.location.href = `/admin/outcome/receptions/${moduleType}`
  }

  function NewTab(url) {
    window.open(url);
    return false;
  }

  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE DEL PEDIDO"
        message="En esta sección podrás revisar el detalle del pedido de bodega."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egreso
            </Link>
            <Link color="inherit" href={`/admin/outcome/receptions/${moduleType}`}>
              Pedidos
            </Link>
            <Typography color="textPrimary">Detalle del pedido</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <ReceptionInfo
                id={idRequest}
                order={order}
                moduleType={moduleType}
              />
            </GridContainer>
            <CardBody>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center align-middle">SKU</th>
                    <th className="text-center align-middle">Grupo</th>
                    <th className="text-center align-middle">Descripción</th>
                    <th className="text-center align-middle">Observaciones</th>
                    <th className="text-center align-middle">
                      Cantidad solicitada
                    </th>
                    <th className="text-center align-middle">
                      Cantidad en esta caja
                    </th>
                    <th className="text-center align-middle">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    product.id_producto_pedido.reemplazado ? (
                      <tr key={product.id}>
                        <td className="text-center align-middle">
                          <s>
                            {product?.id_producto_pedido?.id_insumo_medicamento?.SKU
                              ? product?.id_producto_pedido.id_insumo_medicamento.SKU
                              : product?.id_producto_pedido.id_equipo_asignado
                              ? product?.id_producto_pedido.id_equipo_asignado?.sku_bodega
                              : product?.id_producto_pedido.id_equipo?.SKU}
                          </s>
                        </td>
                        <td className="text-center align-middle">
                          <s>
                            {product?.id_producto_pedido.id_insumo_medicamento !== null
                              ? product?.id_producto_pedido.id_insumo_medicamento?.grupo_producto
                              : "Equipo"}
                          </s>
                        </td>
                        <td className="text-center align-middle">
                          <s>
                          {product?.id_producto_pedido.id_insumo_medicamento !== null
                            ? product?.id_producto_pedido.id_insumo_medicamento?.descripcion_producto
                            : product?.id_producto_pedido.id_equipo_asignado
                            ? product?.id_producto_pedido.id_equipo_asignado?.tipo_equipo
                            : product?.id_producto_pedido.id_equipo?.descripcion}
                          </s>
                        </td>
                        <td className="text-center align-middle">
                          <s>{product.id_producto_pedido.comentario || product.id_producto_pedido.observaciones}</s>
                        </td>
                        <td className="text-center align-middle">
                          <s>{product.id_producto_pedido.cantidad}</s>
                        </td>
                        <td className="text-center align-middle">
                          <s>{product.cantidad_enviada}</s>
                        </td>
                        <td className="text-center align-middle">
                          <div className="text-center align-middle">
                            <div className="row">
                              <div className="align-self-center">
                                  <s>
                                    {product.id_producto_pedido.estado}

                                  </s>
                              </div>
                              {(product.id_producto_pedido.estado === "Completa" || product.id_producto_pedido.estado === "Completada") ? (
                                <s>
                                  <Box display="flex" justifyContent="center">
                                    <Box borderRadius="50%" {...colorGreen} />
                                  </Box>
                                </s>
                              ) : product.id_producto_pedido.estado === "Incompleta" ? (
                                <Box display="flex" justifyContent="center">
                                  <Box borderRadius="50%" {...colorRed} />
                                </Box>
                              ) : (
                                <Box display="flex" justifyContent="center">
                                  <Box borderRadius="50%" {...colorWhite} />
                                </Box>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr key={product.id}>
                        <td className="text-center align-middle">
                          {product?.id_producto_pedido?.id_insumo_medicamento?.SKU
                            ? product?.id_producto_pedido.id_insumo_medicamento.SKU
                            : product?.id_producto_pedido.id_equipo_asignado
                            ? product?.id_producto_pedido.id_equipo_asignado?.sku_bodega
                            : product?.id_producto_pedido.id_equipo?.SKU}
                        </td>
                        <td className="text-center align-middle">
                          {product?.id_producto_pedido.id_insumo_medicamento !== null
                            ? product?.id_producto_pedido.id_insumo_medicamento?.grupo_producto
                            : "Equipo"}
                        </td>
                        <td className="text-center align-middle">
                          {product?.id_producto_pedido.id_insumo_medicamento !== null
                            ? product?.id_producto_pedido.id_insumo_medicamento?.descripcion_producto
                            : product?.id_producto_pedido.id_equipo_asignado
                            ? product?.id_producto_pedido.id_equipo_asignado?.tipo_equipo
                            : product?.id_producto_pedido.id_equipo?.descripcion}
                        </td>
                        <td className="text-center align-middle">
                          {product.id_producto_pedido.comentario}
                        </td>
                        <td className="text-center align-middle">
                          {product.id_producto_pedido.cantidad}
                        </td>
                        <td className="text-center align-middle">
                          {product.cantidad_enviada}
                        </td>
                        <td className="text-center align-middle">
                          <div className="text-center align-middle">
                            <div className="row">
                              <div className="align-self-center">
                                  <span>
                                    {product.id_producto_pedido.estado}

                                  </span>
                              </div>
                              {product.id_producto_pedido.estado === "Completa" || product.id_producto_pedido.estado === "Completada" ? (
                                <Box display="flex" justifyContent="center">
                                  <Box borderRadius="50%" {...colorGreen} />
                                </Box>
                              ) : product.id_producto_pedido.estado === "Incompleta" ? (
                                <Box display="flex" justifyContent="center">
                                  <Box borderRadius="50%" {...colorRed} />
                                </Box>
                              ) : (
                                <Box display="flex" justifyContent="center">
                                  <Box borderRadius="50%" {...colorWhite} />
                                </Box>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </table>
              <GridContainer justify="center" className="m-3 mt-3 mb-3">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {products[0]?.id_envio_pedido.estado !=
                  "Esperar a transporte" ? (
                    <h5 className="mb-2">Adjuntar guía de despacho</h5>
                  ) : (
                    <div>
                      <h5 className="mb-2">Guía de despacho</h5>
                      <p />
                      <br />
                      <p className="card-text">Guía de despacho anterior:</p>
                      {getFiles.map(file => (
                        <a
                          key={file.id}
                          onClick={() => NewTab(file.archivo)}
                          className="btn btn-cmh-color text-white"
                        >
                          {file.nombre}
                        </a>
                      ))}
                      <p>
                        <span className="font-weight-bold">
                          Observación anterior:
                        </span>
                        {getFiles.length > 0 ? getFiles[0].observacion : null}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    {products[0]?.id_envio_pedido.estado === "Esperar a transporte" ? null : (
                      <DetailTextArea
                        label="OBSERVACIONES:"
                        placeholder="Observaciones"
                        value={observation}
                        maxlength="500"
                        onchange={e => {
                          setObservation(e.target.value);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </GridContainer>
              <GridContainer
                justify="center"
                style={{ textAlign: "center" }}
                className="m-3 mt-3 mb-3"
              >
                {products[0]?.id_envio_pedido.estado !== "Esperar a transporte" && (
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <span className="card-title">
                        ADJUNTAR GUÍA DE DESPACHO:
                      </span>
                      <p className="card-text">
                        Archivo seleccionado: {file ? file.name : null}
                      </p>
                      <span className="btn btn-cmh-color btn-file">
                        Seleccionar
                        <input
                          type="file"
                          name={file}
                          onChange={e => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </span>
                      {getFiles.length > 0 ? (
                        <div>
                          <p />
                          <br />
                          <p className="card-text">
                            Guía de despacho anterior:
                          </p>
                          {getFiles.map(file => (
                            <a
                              key={file.id}
                              onClick={() => NewTab(file.archivo)}
                              className="btn btn-cmh-color text-white"
                            >
                              {file.nombre}
                            </a>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              </GridContainer>
              <GridContainer
                justify="center"
                style={{ textAlign: "center" }}
                className="m-3 mt-3 mb-3"
              >
                {(products[0]?.id_envio_pedido.estado !== "Esperar a transporte" &&  getFiles.length > 0) ? (
                  <div>
                    <p>
                      <span className="font-weight-bold">
                        Observación anterior:
                      </span>
                      {getFiles ? getFiles[0].observacion : null}
                    </p>
                  </div>
                ) : file === null ? (
                  <span className="font-weight-bold text-danger">
                    Debes ingresar un archivo
                  </span>
                ) : null}
              </GridContainer>
              <GridContainer justify="center" className="mt-3 mb-3">
                {transport &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setmodalTransport(true)}
                  className="m-1"
                >
                  Notificar Transporte 
                </Button>
                }
                {(getFiles.length > 0 && !transport) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateAndSend}
                    className="m-1"
                  >
                    Guardar y enviar 
                  </Button>
                ) : (file != null && !transport)? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : null}
                {(order.estado_solicitud === "Esperar aprobación de supervisora"  && !transport) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalCancelSendSupervisor(true)}
                    className="m-1"
                  >
                    Cancelar solicitud
                  </Button>
                ) : !transport && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={rejectReception}
                    className="m-1"
                  >
                    No aceptar recepción
                  </Button>
                  
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  className="m-1"
                >
                  Regresar
                </Button>
              </GridContainer>

            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalSaved && (
        <ModalSaved open={modalSaved} closeModal={() => setModalSaved(false)} />
      )}
      {modalEditSupply && (
        <ModalReplaceSKU
          open={modalEditSupply}
          closeModal={() => setModalEditSupply(false)}
          id_producto={id_producto}
          id_detalle={id_order}
        />
      )}
      {modalSendPending && (
        <ModalSendPending
          open={modalSendPending}
          closeModal={() => setModalSendPending(false)}
          productos_restantes={productosRestantes}
          id_producto={id_producto}
          id_order={id_order}
          moduleType={moduleType}
        />
      )}
      {modalCancelReplace && (
        <ModalCancelReplace
          open={modalCancelReplace}
          closeModal={() => setModalCancelReplace(false)}
          id_producto={id_producto}
          id_detalle={id_order}
        />
      )}
      {modalReplaceInsumos && (
        <ModalReplaceInsumos
          open={modalReplaceInsumos}
          closeModal={() => setModalReplaceInsumos(false)}
          id_producto={id_producto}
          id_detalle={id_order}
        />
      )}
      {modalRejectPending && (
        <ModalRejectPending
          open={modalRejectPending}
          closeModal={() => setModalRejectPending(false)}
          productos_restantes={productosRestantes}
          id_producto={id_producto}
          id_order={id_order}
        />
      )}
      {modalRejectComplete && (
        <ModalRejectComplete
          open={modalRejectComplete}
          closeModal={() => setModalRejectComplete(false)}
          id_order={idRequest}
        />
      )}
      {modalSendSupervisorComplete && (
        <ModalSendSupervisorComplete
          open={modalSendSupervisorComplete}
          closeModal={() => setModalSendSupervisorComplete(false)}
          id_order={id_order}
        />
      )}
      {modalSendSupervisorPending && (
        <ModalSendSupervisorPending
          open={modalSendSupervisorPending}
          closeModal={() => setModalSendSupervisorPending(false)}
          productos_restantes={productosRestantes}
          id_producto={id_producto}
          id_order={id_order}
        />
      )}
      {modalCancelSendSupervisor && (
        <ModalCancelSendSupervisor
          open={modalCancelSendSupervisor}
          closeModal={() => setModalCancelSendSupervisor(false)}
          id_order={id_order}
        />
      )}
      {modalSendComplete && (
        <ModalSendComplete
          open={modalSendComplete}
          closeModal={() => setModalSendComplete(false)}
          id_order={id_order}
          moduleType={moduleType}
        />
      )}
      {modalAceptReceptionComplete && (
        <ModalAceptReceptionComplete
          getEnviosPedidos={getEnviosPedidos}
          open={modalAceptReceptionComplete}
          closeModal={() => setModalAceptReceptionComplete(false)}
          id_order={idRequest}
          moduleType={moduleType}
        />
      )}
      {modalAceptReceptionPending && (
        <ModalAceptReceptionPending
          getEnviosPedidos={getEnviosPedidos}
          open={modalAceptReceptionPending}
          closeModal={() => setModalAceptReceptionPending(false)}
          productos_restantes={productosRestantes}
          id_producto={id_producto}
          id_order={idRequest}
          moduleType={moduleType}
        />
      )}
        {modalTransport && (
        <ModalTransport
          open={modalTransport}
          closeModal={() => setmodalTransport(false)}
          idTransport={id_order}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
