import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Calendar, momentLocalizer } from "react-big-calendar";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import { ButtonTextTemplate as Button,  CustomCardItem, ButtonTextMUI } from "utils/componentsStyle";
import {
  FormControlLabel,
  Typography,
  Grid,
  Switch,
  Button as ButtonMUI,
  Fab,
  Link
} from "@material-ui/core";
import WorkOffIcon from '@material-ui/icons/WorkOff';
import WorkIcon from '@material-ui/icons/Work';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PublishIcon from '@material-ui/icons/Publish';
import { indigo } from "@material-ui/core/colors";
import PatientTable from "views/Widgets/Assignments/PatientTable";
import WorkerTable from "views/Widgets/Assignments/WorkerTable";
import DailyTable from "views/Widgets/Assignments/DailyTable";
import DefineVisit from "components/Assignments/DefineVisit";
import DefineVisitCalendar from "components/Assignments/DefineVisitCalendar";
import EditVisitCalendar from "components/Assignments/EditVisitCalendar";
import EditVisit from "components/Assignments/EditVisit";
import { dictMonth } from "utils/validationFunction";
import {firstChartUpperString, eventColors} from "utils/formatFunctions";
import apiformxlsx from "utils/APIForm"
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import {setmessageGeneral } from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

const initAmount = () => {
  return {
    CLIKineMot: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    },
    CLIKineResp: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    },
    CLIKineIntegral: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    },
    CLIFono: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    },
    CLIEnfRuta: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    },
    CLITerOcu: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    },
    CLITecEnfBas: {
      beneficio_actual: 0,
      beneficio_total: 0,
      descompensacion_actual: 0,
      descompensacion_total: 0,
      frecuencia: 0,
      cantidad: 0,
      fecha: null,
      tipo_frecuencia: null,
    }
  };
};

const Assignments = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [changeButtons, setchangeButtons] = useState(true);
  const [updateArrayPatient, setupdateArrayPatient] = useState(false);
  const [updateArrayProfessional, setupdateArrayProfessional] = useState(false);
  const [changeFilter, setchangeFilter] = useState(false);
  const [initialCharge, setinitialCharge] = useState(true);
  const [chargeWorker, setchargeWorker] = useState(false);
  const [updateArrayWorker, setupdateArrayWorker] = useState(true);
  const [updateNavigation, setupdateNavigation] = useState(false);
  const [updateFilterPatient, setupdateFilterPatient] = useState(true);
  const [period, setperiod] = useState("Dias");

  const [showTableSwich, setshowTableSwich] = useState(true);
  const [arrayWorker, setarrayWorker] = useState([]);
  const [auxArrayProfessional, setauxArrayProfessional] = useState([]);
  const [filterArrayProfessional, setfilterArrayProfessional] = useState([]);
  const [filterArrayPatient, setfilterArrayPatient] = useState([]);
  const [eventArray, seteventArray] = useState([]);
  const [dictChargeType, setdictChargeType] = useState({});
  const [dictChargeId, setdictChargeId] = useState({});
  const [dictPatientFile, setdictPatientFile] = useState({});
  const [dictReservation, setdictReservation] = useState({});
  const [dictPatientFileWorker, setdictPatientFileWorker] = useState({});
  const [dictPatientWorker, setdictPatientWorker] = useState({});
  const [dictUser, setdictUser] = useState({});
  const [dictPatientTransient, setdictPatientTransient] = useState({})
  const [dailyArray, setdailyArray] = useState([]);
  const [dailyHeaderPages, setdailyHeaderPages] = useState(0);
  const [dictPatient, setdictPatient] = useState({});
  const [selectDate, setselectDate] = useState(moment());
  const [selectPatient, setselectPatient] = useState(null);
  const [selectProfessional, setselectProfessional] = useState(null);
  const [selectedBox, setselectedBox] = useState("");
  const [selectCharge, setselectCharge] = useState([]);
  const [selectSlot, setselectSlot] = useState(null);
  const [selectEditWeek, setselectEditWeek] = useState(null);
  const [filterProfessionalTable, setfilterProfessionalTable] = useState("");
  const [filterPatientTable, setfilterPatientTable] = useState("");
  const [calendarView, setcalendarView] = useState("days");
  const [listPatient, setlistPatient] = useState([]);
  const [scheduleVisit, setscheduleVisit] = useState(null);
  const [editScheduleVisit, seteditScheduleVisit] = useState(null);
  const [orderProfessional, setorderProfessional] = useState(null);
  const [directionProfessional, setdirectionProfessional] = useState(null);
  const [orderPatient, setorderPatient] = useState(null);
  const [directionPatient, setdirectionPatient] = useState(null);
  const [page, setpage] = useState(0);
  const [showPanelTable, setshowPanelTable] = useState("PANEL");
  const [fileMultiUser, setfileMultiUser] = useState(null)
  const dictHours = {
    "22,5": 60,
    "27": 75,
    "33": 90,
    "45": 120,
  };

  const [pieChart, setpieChart] = useState({
    medico: {
      title: "Médico",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: [],
      total: 0,
    },
    ktm: {
      title: "KTM",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: ["CLIKineMot"],
      total: 0,
    },
    ktr: {
      title: "KTR",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: ["CLIKineResp"],
      total: 0,
    },
    kti: {
      title: "KTI",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: ["CLIKineIntegral"],
      total: 0,
    },
    fono: {
      title: "Fono",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: ["CLIFono"],
      total: 0,
    },
    enfermera: {
      title: "Enfermera",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: ["CLIEnfRuta"],
      total: 0,
    },
    to: {
      title: "TO",
      select: false,
      data: {
        labels: ["62%", "38%"],
        series: [62, 38],
      },
      charge: ["CLITerOcu"],
      total: 0,
    },
  });

  const listCharges = useSelector(state => state.currentList.listCharges);
  const dictCharges = useSelector(state => state.currentList.dictCharges);
  const dictTypeWorkFile = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

  const dictTransient = dictPatientTransient[selectPatient?.id] || {}
  const initialDatePatient = dictTransient?.fecha_inicio_actividades || moment().format("YYYY-MM-DD")



  useEffect(() => {
    if (chargeWorker) {
      getDictsWorkers();
    }
    if (updateArrayPatient) {
      filterPatients();
    }
    if (updateArrayProfessional) {
      filterProfessional();
    }
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (changeFilter) {
      updateFilter();
    }
    if (updateArrayWorker) {
      updateDataProfessional();
    }
    if (updateFilterPatient) {
      updateDataPatient();
    }
    if (initialCharge && listCharges.length > 0) {
      initialData();
    }
    if (updateNavigation){
      handleNavigation(selectDate);
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    const requestPatient = api.get("api/clinica/pacientes/");
    const requestCharges = api.get("api/usuario/cargos/asignaciones/");
    // const requestCity = api.get("api/comuna/")
    Promise.all([
      requestPatient,
      requestCharges,
      // requestCity,
    ]).then((request) => {
      const tempDictChargeType = {};
      const tempDictChargeShortName = {};
      const tempDictChargeId = {};
      const tempDictPatientFile = {};
      const tempDictPatient = {};
      const tempDictUser = {};
      const tempDictCountCharge = {};
      const tempPieChart = {};
      const tempWorker = request[1].data.map((row) => {
        if (tempDictCountCharge[row.cargo]) {
          tempDictCountCharge[row.cargo] += 1;
        } else {
          tempDictCountCharge[row.cargo] = 1;
        }
        return { ...row };
      });

      tempDictCountCharge["CLIKineIntegral"] = tempDictCountCharge["CLIKineResp"] + tempDictCountCharge["CLIKineMot"];
      let tempKeyPie = "";
      let tempKeySelect = "";
      let tempKeyCharge = "";

      Object.keys(pieChart).map((row) => {
        tempPieChart[row] = pieChart[row];
        pieChart[row].charge.map((subRow) => {
          if (tempDictCountCharge[subRow] !== undefined) {
            tempPieChart[row].select = true;
            tempKeyPie = row;
            tempKeySelect = pieChart[row].select;
            tempKeyCharge = pieChart[row].charge;
          }
        });
      });

      request[1].data.map((row) => {
        tempDictUser[row.id] = row;
      });

      listCharges.map((row) => {
        const tempRow = dictCharges[row.value];
        tempDictChargeType[tempRow.tipo_perfil] = tempRow.id;
        tempDictChargeType[tempRow.codigo] = tempRow.id;
        tempDictChargeShortName[tempRow.nombre_corto] = tempRow.id;
        tempDictChargeId[tempRow.id] = tempRow;
      });

      request[0].data.map((row) => {
        const tempRowPatient = row;
        tempDictPatient[row.id] = row.id_ficha_paciente.id;
        tempDictPatientFile[row.id_ficha_paciente.id] = {...tempRowPatient};
      });

      setpieChart(tempPieChart);
      setdictChargeType(tempDictChargeType);
      setdictPatient(tempDictPatient);
      setdictChargeId(tempDictChargeId);
      setdictPatientFile(tempDictPatientFile);
      setdictUser(tempDictUser);
      getArrayDatePeople(selectDate);
      setarrayWorker(tempWorker);
      handleSelectBox(tempKeyPie, tempKeySelect, tempKeyCharge, false);
      setupdateNavigation(true);
    });
  };

  const getDictsWorkers = () => {
    setchargeWorker(false);
    const tempDicReservation = {};
    const tempDictPatientFileWorker = {};
    const tempEventArray = [];
    Object.values(dictPatientWorker).map((row) => {
      const tempData = row;
      const numberModules = parseInt(
        moment(tempData.fecha_final).diff(tempData.fecha_hora, "minutes") / 30
      );
      const tempStartDate = moment(tempData.fecha_hora).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      if (tempDictPatientFileWorker[tempData.id_ficha]) {
        tempDictPatientFileWorker[tempData.id_ficha].push(tempData);
      } else {
        tempDictPatientFileWorker[tempData.id_ficha] = [tempData];
      }
      if (tempDicReservation[tempStartDate]) {
        tempDicReservation[tempStartDate][tempData.id_usuarios] = {
          ...tempData,
          numero_modulos: numberModules,
        };
      } else {
        tempDicReservation[tempStartDate] = {
          [tempData.id_usuarios]: {
            ...tempData,
            numero_modulos: numberModules,
          },
        };
      }
      if (calendarView !== "days" && selectPatient) {
        if (selectPatient.id_ficha_paciente.id === tempData.id_ficha) {
          if (
            (tempData.tipo_tarea != "HabilitacionDomicilio" &&
              tempData.tipo_tarea != "VisitaInicial") ||
            ((tempData.tipo_tarea == "HabilitacionDomicilio" ||
              tempData.tipo_tarea == "VisitaInicial") &&
              selectCharge.includes(
                dictChargeId[tempData.id_cargo_asignado].tipo_perfil
              ))
          ) {
            tempEventArray.push({
              title: generateTitle(
                dictUser[tempData.id_usuarios],
                dictChargeId[tempData.id_cargo_asignado],
                tempData.tipo_tarea
              ),
              start: moment(tempData.fecha_hora).toDate(),
              end: moment(tempData.fecha_final).toDate(),
              color: !selectCharge.includes(dictChargeId[tempData.id_cargo_asignado].tipo_perfil) ?
                "default" :
                (row.estado === "CanceladaPaciente" || row.estado === "NoRealizada" || row.estado === "Cancelada") ?
                  "dark" :
                (tempData.id_usuarios !== null && row.estado === "Incompleto") ?
                  "orange" :
                tempData.id_usuarios !== null ?
                  "green" :
                tempData.tipo_tarea == "Telemedicina" ?
                  "blueGrey" :
                period !== "Negocio" ?
                "default" :
                tempData.tipo_tarea == "HabilitacionDomicilio" ?
                  "azure" :
                tempData.tipo_tarea == "VisitaInicial" ?
                  "rose" :
                tempData.tipo_tarea == "DescompensacionNoTens" ?
                  "red" :
                tempData.tipo_tarea == "ExamenAsignarNoTens" ?
                  "deepOrange" :
                tempData.tipo_tarea == "TerapiaAntibioticoAsignarNoTens" ?
                  "teal" :
                tempData.tipo_tarea == "AsignacionExtra" ?
                  "azuredark" : "indigo",
              row: row,
            });
          }
        }
      } else if (calendarView !== "days" && selectProfessional) {
        if (selectProfessional.id === tempData.id_usuarios) {
          tempEventArray.push({
            title: generateTitlePatient(dictPatientFile[tempData.id_ficha]),
            start: moment(tempData.fecha_hora).toDate(),
            end: moment(tempData.fecha_final).toDate(),
            color: "warning",
            row: row,
          });
        }
      }
    });
    seteventArray(tempEventArray);
    setdictReservation(tempDicReservation);
    setdictPatientFileWorker(tempDictPatientFileWorker);
  };


  const generateTitle = (userInfo, chargeInfo, typeWork) => {
    const tempCharge = chargeInfo ? chargeInfo.nombre_corto : "No disponible";
    return `${tempCharge}: ${
      userInfo ? userInfo.first_name : "No asignar"
    } ${userInfo ? userInfo.last_name : "No asignar"} - ${dictTypeWorkFile[typeWork]?.label}`;
  };

  const generateTitlePatient = (patientInfo) => {
    return `${patientInfo ? patientInfo.nombre : "No disponible"} ${
      patientInfo ? patientInfo.apellido_paterno : "No disponible"
    } ${patientInfo ? patientInfo.apellido_materno : "No disponible"}`;
  };

  const getArrayDatePeople = (tempSelectDate) => {
    const auxSelectDate = moment(tempSelectDate).format("YYYY-MM-DD");
    let countDate = moment(auxSelectDate).set("hour", 8);
    const endDate = moment(auxSelectDate).add(24, "hour");
    const tempDayArray = [];
    while (moment(endDate).diff(countDate, "minutes") >= 0) {
      tempDayArray.push({
        start: moment(countDate).format("HH:mm"),
        end: moment(countDate)
          .add(30, "minutes")
          .format("HH:mm"),
      });
      countDate = moment(countDate).add(30, "minutes");
    }
    setdailyArray(tempDayArray);
  };

  const updateFilter = () => {
    setchangeFilter(false);
    setchangeButtons(true);
  };

  const updateDataPatient = () => {
    setupdateFilterPatient(false);
    let tempArray = [];
    const tempListPatient = [];
    const tempSelectCharge = selectCharge[0]

    Object.values(dictPatientFile).filter((row) => (
      (
        (
          filterPatientTable != "" &&
          `${row.nombre} ${row.apellido_paterno}`.toLowerCase().includes(filterPatientTable.toLowerCase())
        ) ||
        filterPatientTable == ""
      ) &&
      row.amount[tempSelectCharge] &&
      (
        (
          period !== "Negocio" &&
          (
            row.amount[tempSelectCharge]?.beneficio_total > 0 ||
            row.amount[tempSelectCharge]?.descompensacion_total > 0
          ) &&
          row.amount[tempSelectCharge]?.tipo_frecuencia === period
        ) ||
        period === "Negocio"
      )
    )).map((row) => {
      const tempRow = {...row};
      tempRow.beneficio_actual = row.amount[tempSelectCharge].beneficio_actual;
      tempRow.beneficio_total = row.amount[tempSelectCharge].beneficio_total;
      tempRow.descompensacion_actual = row.amount[tempSelectCharge].descompensacion_actual;
      tempRow.descompensacion_total = row.amount[tempSelectCharge].descompensacion_total;

      tempArray.push({
        ...tempRow,
      })
      tempListPatient.push({
        value: row.id,
        label: `${row.rut} ${row.nombre} ${row.apellido_paterno}`,
      });
    });
    if (orderPatient) {
      if (orderPatient === "Paciente") {
        if (directionPatient) {
          tempArray = tempArray.sort((a, b) =>
            `${a.nombre} ${a.apellido_paterno}` <
            `${b.nombre} ${b.apellido_paterno}`
              ? 1
              : -1
          );
        } else {
          tempArray = tempArray.sort((a, b) =>
            `${a.nombre} ${a.apellido_paterno}` >
            `${b.nombre} ${b.apellido_paterno}`
              ? 1
              : -1
          );
        }
      } else if (orderPatient === "Estado") {
        if (directionPatient) {
          tempArray = tempArray.sort((a, b) =>
            a.beneficio_actual / a.beneficio_total <
            b.beneficio_actual / b.beneficio_total
              ? 1
              : -1
          );
        } else {
          tempArray = tempArray.sort((a, b) =>
            a.beneficio_actual / a.beneficio_total >
            b.beneficio_actual / b.beneficio_total
              ? 1
              : -1
          );
        }
      } else if (orderPatient === "Extra") {
        if (directionPatient) {
          tempArray = tempArray.sort((a, b) =>
            a.emergencia < b.emergencia ? 1 : -1
          );
        } else {
          tempArray = tempArray.sort((a, b) =>
            a.emergencia > b.emergencia ? 1 : -1
          );
        }
      }
    }
    setlistPatient(tempListPatient);
    setfilterArrayPatient(tempArray);
    setchangeButtons(true);
  };

  const updateDataProfessional = () => {
    setupdateArrayWorker(false);

    let tempArray = [];
    let tempstartDate = moment();
    let tempendDate = moment();
    if (calendarView === "week" || calendarView === "days") {
      tempstartDate = moment(selectDate).startOf("weeks");
      tempendDate = moment(selectDate).endOf("weeks");
    } else {
      tempstartDate = moment(selectDate).startOf("months");
      tempendDate = moment(selectDate).endOf("months");
    }

    auxArrayProfessional.map((row) => {
      const name = `${row.first_name} ${row.last_name}`;
      const hours = row.id_ficha.variable_personal
        ? dictHours[row.id_ficha.variable_personal.valor]
        : 0;
      let count = 0;
      if (filterProfessionalTable != "") {
        if (
          name.toLowerCase().includes(filterProfessionalTable.toLowerCase())
        ) {
          Object.keys(dictReservation)
            .filter(
              (tempRow) =>
                moment(tempRow).diff(tempstartDate, "minutes") >= 0 &&
                moment(tempendDate).diff(tempRow, "minutes") >= 0
            )
            .map((tempRow) => {
              if (dictReservation[tempRow][row.id]) {
                count += 1;
              }
            });
          tempArray.push({ ...row, visitas: hours, visitas_realizadas: count });
        }
      } else {
        Object.keys(dictReservation).filter((tempRow) => moment(tempRow).isBetween(tempstartDate, tempendDate) ).map((tempRow) => {
            if (dictReservation[tempRow][row.id]) {
              count += 1;
            }
          });
        tempArray.push({ ...row, visitas: hours, visitas_realizadas: count });
      }
    });

    tempArray = tempArray.sort((a, b) => {
      if (orderProfessional === "Profesional"){
        const aName = `${a.first_name} ${a.last_name}`;
        const bName = `${b.first_name} ${b.last_name}`;
        if (directionProfessional){
          return aName < bName ? 1 : -1;
        } else {
          return aName > bName ? 1 : -1;
        }
      } else if (orderProfessional === "Estado"){
        const aState = a.visitas_realizadas/a.visitas;
        const bState = b.visitas_realizadas/b.visitas;
        if (directionProfessional){
          return aState < bState ? 1 : -1;
        } else {
          return aState > bState ? 1 : -1;
        }
      } else {return 0}
    });


    const auxArrayLenght = tempArray.length / 4;
    const tempArrayLenght =
      auxArrayLenght > parseInt(auxArrayLenght)
        ? parseInt(auxArrayLenght)
        : auxArrayLenght;
    setdailyHeaderPages(tempArrayLenght);
    setfilterArrayProfessional(tempArray);
    setchangeButtons(true);
  };

  const getBenefitTotal = (argCalendarView, argDate) => {
    const tempDictPatient = {}
    const dayToDate = moment(selectDate);
    const tempPeriodMoment = argCalendarView;
    Object.keys(dictPatientFile).map((key) => {
      const row = {...dictPatientFile[key]};
      row.amount = initAmount();
      const typeBenefit = {};
      if (row.id_paquete){
        row.id_paquete.id_prestacion.filter((benefit) => dictChargeId[benefit.id_prestaciones.id_cargo_id] ).map((benefit) => {
          const charge = dictChargeId[benefit.id_prestaciones.id_cargo_id]
          if (row.amount[charge.tipo_perfil]){
            row.amount[charge.tipo_perfil].tipo_frecuencia = benefit.tipo_frecuencia
          }

          const frequency = benefit.frecuencia;
          const quantity = benefit.cantidad;
          const benefitDate = benefit.ultima_modificacion;
          const date = moment(benefitDate).endOf(tempPeriodMoment)
          const diffDate = moment(dayToDate).diff(moment(date), tempPeriodMoment);
          if (diffDate % frequency === 0) {
            if (typeBenefit[charge.tipo_perfil]){
              typeBenefit[charge.tipo_perfil] += quantity;
            } else {
              typeBenefit[charge.tipo_perfil] = quantity;
            }
          }
        });
        Object.keys(typeBenefit).map((amountKey) => {
          if(row.amount[amountKey]){
            row.amount[amountKey].beneficio_total += typeBenefit[amountKey]
          }
        });
      }
      tempDictPatient[key] = {...row}
    });
    return {...tempDictPatient}
  }

  const filterPatients = () => {
    setupdateArrayPatient(false);
    setchargeWorker(true);
    setupdateFilterPatient(true);
  };

  const filterProfessional = () => {
    setupdateArrayProfessional(false);
    const tempArrayProfessional = [];
    let tempSelectProfessional = null;
    const tempSelectCharge = selectCharge.includes("CLIKineIntegral") ? ["CLIKineResp", "CLIKineIntegral", "CLIKineMot"] : selectCharge;
    arrayWorker.map((row) => {
      if (tempSelectCharge.includes(row.cargo)) {
        tempArrayProfessional.push(row);
        if (tempSelectProfessional === null && period !== "Dias") {
          tempSelectProfessional = row;
        }
      }
    });

    if (!showTableSwich) {
      setselectProfessional(tempSelectProfessional);
      setchargeWorker(true);
    }
    setauxArrayProfessional(tempArrayProfessional);
    setupdateArrayWorker(true);
  };

  const handleFilterProfessional = (event) => {
    setfilterProfessionalTable(event.target.value);
    setupdateArrayWorker(true);
    setpage(0);
  };

  const handleFilterPatient = (event) => {
    setfilterPatientTable(event.target.value);
    setupdateFilterPatient(true);
  };

  const handleNavigation = (date, argView=null) => {
    setupdateNavigation(false)
    let tempCalendarView = argView
    if (argView === null){
      tempCalendarView = calendarView
    }

    setselectDate(moment(date));
    let tempStartDate = null;
    let tempEndDate = null;
    if (tempCalendarView === "week" || tempCalendarView === "days") {
      tempStartDate = moment(date).startOf("week").format("YYYY-MM-DD");
      tempEndDate = moment(date).endOf("week").format("YYYY-MM-DD");
    } else {
      tempStartDate = moment(date).startOf("months").format("YYYY-MM-DD");
      tempEndDate = moment(date).endOf("months").format("YYYY-MM-DD");
    }

    const tempDictPatientFile = getBenefitTotal(tempCalendarView, date)
    api.get(`api/clinica/tareaspaciente/asignacion/?fecha_inicio=${tempStartDate}T00:00:00&&fecha_fin=${tempEndDate}T23:59:59`).then((response) => {
        const tempDictFileWorker = {};
        const tempDictExtraWorksFile = {};
        response.data.map((row) => {
          const tempCharge =  dictChargeId[row.id_cargo_asignado];
          const tempRow = {
            ...row,
            fecha_hora: moment(row.fecha_hora).format("YYYY-MM-DD HH:mm:ss"),
            fecha_final: moment(row.fecha_final).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          };
          tempDictFileWorker[row.id] = tempRow;
          if (tempDictExtraWorksFile[row.id_ficha] === undefined) {
            tempDictExtraWorksFile[row.id_ficha] = {
              Descompensacion: [],
              Otros: [],
            };
          }
          if (["DescompensacionNoTens","ExamenAsignarNoTens","AsignarEspecialistasNoTENS", "AsignacionExtra"].includes(row.tipo_tarea)) {
            tempDictExtraWorksFile[row.id_ficha]["Descompensacion"].push({
              ...row,
            });
            if (
              tempCharge &&
              (
                (
                  tempCalendarView === "days" &&
                  moment(date).format("YYYY-MM-DD") === moment(row.fecha_hora).format("YYYY-MM-DD")
                ) ||
                tempCalendarView !== "days"
              )
            ){
              if (tempDictPatientFile[row.id_ficha]?.amount[tempCharge.tipo_perfil]){
                tempDictPatientFile[row.id_ficha].amount[tempCharge.tipo_perfil].descompensacion_total += 1;
                if (tempDictPatientFile[row.id_ficha] && row.id_usuarios){
                  tempDictPatientFile[row.id_ficha].amount[tempCharge.tipo_perfil].descompensacion_actual += 1;
                }
              }
            }
          } else {
            tempDictExtraWorksFile[row.id_ficha]["Otros"].push({ ...row });
            if (
              tempDictPatientFile[row.id_ficha] &&
              tempCharge &&
              row.tipo_tarea !== "HabilitacionDomicilio" &&
              row.tipo_tarea !== "VisitaInicial" &&
              row.tipo_tarea !== "TerapiaAntibioticoAsignarNoTens" &&
              (
                (
                  tempCalendarView === "days" &&
                  moment(date).format("YYYY-MM-DD") === moment(row.fecha_hora).format("YYYY-MM-DD")
                ) ||
                tempCalendarView !== "days"
              )
            ){
              if (tempDictPatientFile[row.id_ficha].amount[tempCharge.tipo_perfil]){
                tempDictPatientFile[row.id_ficha].amount[tempCharge.tipo_perfil].beneficio_actual += 1
              }
            }
          }

        });
        setdictPatientFile(tempDictPatientFile)
        setdictPatientWorker(tempDictFileWorker);
        setupdateArrayPatient(true);
        setupdateArrayProfessional(true);
    });
    api.get(`api/clinica/tareaspaciente/dias/transitorios/`).then((response) => {
      setdictPatientTransient(response.data);
    })
  };

  const handlePeriod = (auxPeriod, auxView) => {
    setperiod(auxPeriod);
    setcalendarView(auxView);
    setupdateNavigation(true);
  };

  const handleSelectDateWorker = (row, rowWorker, index) => {
    if (listPatient.length > 0) {
      setscheduleVisit([row, rowWorker, true]);
    }
  };

  const handleSelectPatient = (row) => {
    if (showTableSwich) {
      setselectPatient(row);
      setselectSlot(null);
      setchargeWorker(true);
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    let flagDiff=true;
    let flagFullDays=false;
    let flagCheckDay=true;
    if (dictTransient?.fecha_inicio_actividades){
      flagDiff = moment(dictTransient?.fecha_inicio_actividades).diff(start, "minutes") <=0
      flagFullDays = dictTransient?.dias_periodo <= dictTransient?.repeticiones;
    }

    if (flagFullDays){
      flagCheckDay = dictTransient?.fechas.includes(moment(start).format("YYYY-MM-DD"))
    }

    if (flagDiff && flagCheckDay){
      let tempStart = start
      let tempEnd =  moment(tempStart).add(1, 'hours')
      if (period == "Meses"){
        tempStart = moment(start).add(8, 'hours')
        tempEnd = moment(end).add(9, 'hours')
      }
      if (showTableSwich) {
        setselectSlot([tempStart, tempEnd]);
      } else {
        setscheduleVisit([
          {
            end: tempStart,
            start: tempEnd,
          },
          selectProfessional,
          null,
        ]);
      }
    } else {
      if (!flagDiff){
        setmessageGeneral(dispatch, `El paciente tiene como fecha de inicio de actividades: ${dictTransient?.fecha_inicio_actividades}`, "warning", "¡Advertencia!");
      } else if (!flagCheckDay){
        const stringDays = dictTransient?.fechas.join(", ")
        setmessageGeneral(dispatch, `El paciente ya ha completado todos los días del tratamiento: ${stringDays}. Si lo desea, puede seguir asignando profesionales para estos días`, "warning", "¡Advertencia!");
      }
      
    }
  };

  const handleShowMore = (event, day) => {
    setselectDate(moment(day));
    setcalendarView("week");
  }

  const handleSelectEvent = (event) => {
    if (event.color !== "default") {
      if (event.row.tipo_tarea !== "VisitaInicial") {
        if (showTableSwich) {
          setselectEditWeek(event.row);
        } else {
          seteditScheduleVisit([event.row, selectProfessional, null]);
        }
      } else {
        handleSelectSlot(event.start, event.end);
      }
    } else {
      handleSelectSlot(event.start, event.end);
    }
  };

  const handleEditDateWorker = (row, RowWorker) => {
    seteditScheduleVisit([row, RowWorker, true]);
  };

  const handleSelectBox = (value, select, charge, updatePatient=true) => {
    if (select) {
      setselectSlot(null);
      setselectedBox(value);
      setselectCharge(charge);
      setupdateArrayPatient(updatePatient);
      setupdateArrayProfessional(true);
    }
  };

  const handleSelectProfessional = (row) => {
    if (!showTableSwich) {
      setselectProfessional(row);
      setselectSlot(null);
      setchargeWorker(true);
    }
  };

  const handleOrderProfessional = (headerName) => {
    let tempDirection = directionProfessional;
    if (headerName === orderProfessional) {
      tempDirection = !directionProfessional;
    } else {
      tempDirection = false;
    }

    setorderProfessional(headerName);
    setdirectionProfessional(tempDirection);
    setupdateArrayWorker(true);
  };

  const handleOrderPatient = (headerName) => {
    let tempDirection = directionPatient;
    if (headerName === orderPatient) {
      tempDirection = !directionPatient;
    } else {
      tempDirection = false;
    }

    setorderPatient(headerName);
    setdirectionPatient(tempDirection);
    setupdateFilterPatient(true);
  };

  const handleSaveReservation = (
    patient,
    start,
    end,
    extraLunchAmount,
    shiftMobilizationAmount,
    specialViaticAmount,
    extraTurnValue,
    comment,
    hourType,
    dateArray = [],
    argWorker = null
  ) => {
    const tempWorker = argWorker ? argWorker : scheduleVisit[1];
    const tempCharge = selectCharge[0] ? dictChargeId[dictChargeType[selectCharge[0]]] :  dictChargeId[dictChargeType[tempWorker.cargo]];
    let tempPatient = null;
    let tempStartDate = null;
    let tempEndDate = null;
    if (selectSlot) {
      tempPatient = patient;
      tempStartDate = `${moment(selectSlot[0]).format("YYYY-MM-DD")} ${
        start.value
      }:00`;
      tempEndDate = `${
        end.value === "00:00"
          ? moment(selectSlot[0])
              .add(1, "days")
              .format("YYYY-MM-DD")
          : moment(selectSlot[0]).format("YYYY-MM-DD")
      } ${end.value}:00`;
    } else {
      tempPatient = dictPatientFile[dictPatient[patient.value]]
      tempStartDate = `${moment(selectDate).format("YYYY-MM-DD")} ${
        start.value
      }:00`;
      tempEndDate = `${end.value === "00:00" ?
        moment(selectDate).add(1, "days").format("YYYY-MM-DD") :
        moment(selectDate).format("YYYY-MM-DD")
      } ${end.value}:00`;
    }
    const tempStartTime = moment(tempStartDate).format("HH:mm:ss");
    const tempDurationTime = moment(tempEndDate).diff(moment(tempStartDate), "minutes");
    const reptition = dateArray.map((row) => {
      const auxStartDate = `${row} ${tempStartTime}`;
      const auxEndDate = moment(auxStartDate).add(tempDurationTime, "minutes").format("YYYY-MM-DD HH:mm:ss");
      return {
        fecha_hora: auxStartDate,
        fecha_final: auxEndDate
      }
    })

    const bodyWork = {
      tipo_tarea: "Asignacion",
      fecha_hora: tempStartDate,
      fecha_final: tempEndDate,
      descripcion: "Visita paciente",
      estado: "Incompleto",
      comentarios: comment,
      id_ficha: tempPatient.id_ficha_paciente.id,
      id_cargo_asignado: tempCharge.id,
      id_usuarios: tempWorker.id,
    };

    const extraBody = {
      hora_extra: parseInt(extraTurnValue),
      colacion_turno_extra: parseInt(extraLunchAmount),
      movilizacion_turno_extra: parseInt(shiftMobilizationAmount),
      movilizacion_especial: 0,
      viaticos_especial: parseInt(specialViaticAmount),
      id_ficha_personal: tempWorker.id_ficha.id,
    };

    const body = {
      tarea: bodyWork,
      exta: extraBody,
      recurrencia: reptition,
    };

    setscheduleVisit(null);
    setselectSlot(null);
    api.post("api/clinica/asignar/tarea/", body).then((response) => {
      setupdateNavigation(true);
      setmessageGeneral(dispatch, "Visita ingresada", "success", "¡Exito!");
    });
  };

  const handleEditReservation = (
    patient,
    start,
    end,
    extraLunchAmount,
    shiftMobilizationAmount,
    specialViaticAmount,
    extraTurnValue,
    comment,
    hourType,
    worker = null
  ) => {
    let tempData = null;
    let tempWorker = worker || editScheduleVisit[1];;
    let tempPatient = null;
    if (selectEditWeek) {
      tempData = selectEditWeek;
      tempPatient = patient;
    } else {
      tempData = editScheduleVisit[0];
      tempPatient = dictPatientFile[dictPatient[patient.value]];
    }
    const tempCharge = selectCharge[0] ? dictChargeId[dictChargeType[selectCharge[0]]] :  dictChargeId[dictChargeType[tempWorker.cargo]];

    const tempStartDate = `${moment(tempData.fecha_hora).format(
      "YYYY-MM-DD"
    )} ${start.value}:00`;
    const tempEndDate = `${
      end.value === "00:00"
        ? moment(tempData.fecha_hora)
            .add(1, "days")
            .format("YYYY-MM-DD")
        : moment(tempData.fecha_hora).format("YYYY-MM-DD")
    } ${end.value}:00`;

    const bodyWork = { ...tempData };

    bodyWork["fecha_hora"] = tempStartDate;
    bodyWork["fecha_final"] = tempEndDate;
    bodyWork["estado"] = "Incompleto";
    bodyWork["comentarios"] = comment;
    bodyWork["id_ficha"] = tempPatient.id_ficha_paciente.id;
    bodyWork["id_cargo_asignado"] = tempCharge.id;
    bodyWork["id_usuarios"] = tempWorker.id;

    const extraBody = {
      hora_extra: parseInt(extraTurnValue),
      colacion_turno_extra: parseInt(extraLunchAmount),
      movilizacion_turno_extra: parseInt(shiftMobilizationAmount),
      movilizacion_especial: 0,
      viaticos_especial: parseInt(specialViaticAmount),
      id_ficha_personal: tempWorker.id_ficha.id,
    };

    const body = {
      tarea: bodyWork,
      exta: extraBody,
    };

    seteditScheduleVisit(null);
    setselectEditWeek(null);
    api.put(`api/clinica/asignar/tarea/${tempData.id}`, body)
      .then((response) => {
        setupdateNavigation(true);
        setmessageGeneral(dispatch, "Visita editada", "success", "¡Exito!");
      });
  };

  const handleRemoveReservation = () => {
    let tempData = null;
    if (selectEditWeek) {
      tempData = selectEditWeek;
    } else {
      tempData = editScheduleVisit[0];
    }

    seteditScheduleVisit(null);
    setselectEditWeek(null);
    api.delete(`api/clinica/asignar/tarea/${tempData.id}`).then((response) => {
      setupdateNavigation(true);
      setmessageGeneral(dispatch, "Visita eliminada", "success", "¡Exito!");
    });
  };

  const handleShowTableSwitch = (value) => {
    setshowTableSwich(value);
    if (value) {
      setselectProfessional(null);
    } else {
      setselectPatient(null);
    }
    setupdateNavigation(true);
  };

  const CustomToolbar = () => {
    return (
      <div className="toolbar-container" style={{ margin: "6px 0 0 0" }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          {(period === "Meses" || period === "Negocio") ? (
            <h5 style={{ margin: "0 0 0px 95px" }}>
              {`${
                dictMonth[parseInt(moment(selectDate).format("MM"))]
              } del ${moment(selectDate).format("YYYY")} `}
            </h5>
          ) : (
            <h5 style={{ margin: "0 -24px 0px 95px" }}>
              {`${moment(selectDate)
                .startOf("week")
                .format("DD")} ${
                dictMonth[
                  parseInt(
                    moment(selectDate)
                      .startOf("week")
                      .format("MM")
                  )
                ]
              } - ${moment(selectDate)
                .endOf("week")
                .format("DD")} ${
                dictMonth[
                  parseInt(
                    moment(selectDate)
                      .endOf("week")
                      .format("MM")
                  )
                ]
              }`}
            </h5>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ margin: "-34px 0 0 0" }}
        >
          <Grid
            item
            xs={6}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Button
              round
              color={"primary"}
              size="sm"
              style={{ zIndex: 10 }}
              className={classes.marginRight}
              onClick={() =>
                handleNavigation(moment(selectDate).subtract(1, calendarView))
              }
            >
              Anterior
            </Button>
            <Button
              round
              color={"primary"}
              size="sm"
              style={{ zIndex: 10 }}
              className={classes.marginRight}
              onClick={() => handleNavigation(moment())}
            >
              Hoy
            </Button>
            <Button
              round
              color={"primary"}
              size="sm"
              className={classes.marginRight}
              style={{ zIndex: 10 }}
              onClick={() =>
                handleNavigation(moment(selectDate).add(1, calendarView))
              }
            >
              Siguiente
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          ></Grid>
        </Grid>
      </div>
    );
  };

  const handleMultipleUser = () => {
    const formData = new FormData();
    formData.append("archivo", fileMultiUser);

    apiformxlsx.post(`api/paciente/cargamasiva/ruta/?periodo=${moment(selectDate).format("YYYY-MM")}`, formData).then(response => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiUser(null)
      })
      .catch(error => {
        setfileMultiUser(null)
        {}
      });
  }

  const handleDownloadBaseFile = () => {
    const filter_charge = selectCharge.reduce((accum, obj) => {
      let tempObj = obj;
      if (tempObj=="CLIKineIntegral"){
        tempObj = `CLIKineMot&profesional=CLIKineResp`
      }
      return `${accum}&profesional=${tempObj}`;
    }, "")
    api.get(`api/paciente/descargarbasemasiva/ruta/?periodo=${moment(selectDate).format("YYYY-MM")}${filter_charge}`, { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_masiva_turnos.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => {
        {}
      });
  }

  return (
    <GridContainer>
      {changeButtons === false && <div></div>}
      <Grid container item xs={12}>
        <Grid item xs={showPanelTable ? 8 : 12} style={{transitionDuration: "1000ms" }}>
          <GridItem
            xs={12}
            container
            direction="row"
            alignItems="center"
            justify="space-around"
            style={{margin: "20px 0"}}
          >
            {Object.keys(pieChart).map(
              (row, index) =>
                pieChart[row].select && (
                  <ButtonMUI
                    key={`index-box-${index}`}
                    className='px-3'
                    onClick={() => handleSelectBox(row, pieChart[row].select, pieChart[row].charge)}
                    variant={selectedBox === row ? 'contained' :'outlined' }
                    color='primary'
                  >
                    {pieChart[row].title}
                  </ButtonMUI>
                )
            )}
          </GridItem>
          <GridItem
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Card>
              <CardBody stats className={classes.cardFooter}>
              {calendarView === "days" &&
                <Grid xs={12}>
                  <Typography
                      variant="body1"
                      gutterBottom
                    >
                      { selectPatient ?
                          `Paciente: ${selectPatient.nombre} ${selectPatient.apellido_paterno} ${selectPatient.apellido_materno}`:
                        "No seleccionado"}
                    </Typography>

                </Grid>}
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    <h6 className={classes.legendTitle}>Estados</h6>
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#000000" }}
                    />{" "}
                    Cancelada / Cancelada por el paciente / No realizada{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#ff9800" }}
                    />{" "}
                    En proceso{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#4caf50" }}
                    />{" "}
                    Completada{` `}
                  </Grid>
                  <Grid item xs={12}>
                    <h6 className={classes.legendTitle}>
                      Tareas por asignar
                    </h6>
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#5bc0de" }}
                    />{" "}
                    Habilitación{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#e91e63" }}
                    />{" "}
                    Visita inicial{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#f44336" }}
                    />{" "}
                    Descompensación{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#ff5722" }}
                    />{" "}
                    Examen{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#009688" }}
                    />{" "}
                    Tratamiento Antibiótico{` `}
                  </Grid>
                  <Grid item xs={12}>
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#5c6bc0"}}
                    />{" "}
                    Traslado{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#607d8b"}}
                    />{" "}
                    Telemedicina{` `}
                    <i
                      className={"fas fa-circle"}
                      style={{ color: "#006064"}}
                    />{" "}
                    Extra paciente{` `}
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
            {calendarView !== "days" ? (
              <Card style={{margin: "0 0 0 0"}}>
              <CardBody stats className={classes.cardFooter} style={{padding: "2rem 1rem", margin: "0"}}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showTableSwich}
                        onChange={(event) =>
                          handleShowTableSwitch(event.target.checked)
                        }
                        name="showTableSwich"
                        inputProps={{ "aria-label": "periodSelect" }}
                      />
                    }
                    label={
                      showTableSwich
                        ? selectPatient
                          ? `Paciente: ${selectPatient.nombre} ${selectPatient.apellido_paterno} ${selectPatient.apellido_materno}`
                          : "Paciente: No seleccionado"
                        : selectProfessional
                        ? `Profesional: ${selectProfessional.first_name} ${selectProfessional.last_name}`
                        : "Profesional: No seleccionado"
                    }
                  />
                </Grid>
                <Calendar
                  selectable
                  localizer={localizer}
                  events={eventArray}
                  defaultView={"week"}
                  date={selectDate.toDate()}
                  step={30}
                  view={calendarView}
                  startAccessor="start"
                  endAccessor="end"
                  onNavigate={(date) => handleNavigation(date)}
                  onShowMore={handleShowMore}
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSelectSlot}
                  eventPropGetter={eventColors}
                  min={moment(initialDatePatient).add(8, "hours").toDate()}
                  components={{
                    toolbar: CustomToolbar,
                  }}
                  timeslots={1}
                />
              </CardBody>
              </Card>
            ) : (
              <DailyTable
                dailyArray={dailyArray}
                dailyHeaderArray={filterArrayProfessional}
                dailyHeaderPages={dailyHeaderPages}
                filter={filterProfessionalTable}
                selectDate={selectDate}
                handleNavigation={handleNavigation}
                handleSelectDateWorker={handleSelectDateWorker}
                handleEditDateWorker={handleEditDateWorker}
                dictPatientFile={dictPatientFile}
                dictReservation={dictReservation}
                page={page}
                setpage={setpage}
              />
            )}
          </GridItem>
        </Grid>
        <Grid item xs={4}  style={{transitionDuration: "1000ms", display: showPanelTable ? "block" : "none" }}>
          <GridItem xs={12} style={{transitionDuration: "1000ms", display: (showPanelTable == "PANEL") ? "block" : "none" }}>
            <PatientTable
              arrayPatient={filterArrayPatient}
              period={period}
              calendarView={calendarView}
              filter={filterPatientTable}
              showTableSwich={showTableSwich}
              selectPatient={selectPatient}
              handlePeriod={handlePeriod}
              handleFilter={handleFilterPatient}
              handleSelectPatient={handleSelectPatient}
              handleOrder={handleOrderPatient}
              order={orderPatient}
              direction={directionPatient}
              decompensation={true}
              dictPatientTransient={dictPatientTransient}
            />
          </GridItem>
          <GridItem xs={12} style={{transitionDuration: "1000ms", display: (showPanelTable == "PANEL") ? "block" : "none" }}>
            <WorkerTable
              arrayWorker={filterArrayProfessional}
              period={period}
              filter={filterProfessionalTable}
              handleFilter={handleFilterProfessional}
              showTableSwich={showTableSwich}
              selectProfessional={selectProfessional}
              handleSelectProfessional={handleSelectProfessional}
              handleOrder={handleOrderProfessional}
              order={orderProfessional}
              direction={directionProfessional}
            />
          </GridItem>
          <Grid item container xs={12} direction="row" alignContent="flex-start" justify="center" style={{transitionDuration: "1000ms", display: (showPanelTable == "CARGA") ? "block" : "none" }}>
            <CustomCardItem
              xs={12}
              sm={12}
              md={12}
              title={`Carga masiva ${selectedBox.toUpperCase()} - ${firstChartUpperString(dictMonth[parseInt(moment(selectDate).format("MM"))])} ${moment(selectDate).format("YYYY")}`}
              content={`Permite cargar masivamente los turnos de ${selectedBox.toUpperCase()}.`}
              textAction={fileMultiUser ? `Carga masiva de turnos de ${selectedBox.toUpperCase()}`: ""}
              action={() => handleMultipleUser()}
              subComponent={<Link href="#" onClick={() => handleDownloadBaseFile()}>
                Descargar archivo base
              </Link>}
              component={ fileMultiUser ? null : <ButtonTextMUI
                variant='contained'
                color='primary'
              >
              <span className="btn-file">
                {`Adjuntar archivo carga masiva turnos ${selectedBox.toUpperCase()}`}
              <input
                type="file"
                accept="image/xlsx"
                name="archivo"
                onChange={e => setfileMultiUser(e.target.files[0])}
              />
              </span>
              </ButtonTextMUI>}
            />
          </Grid>
        </Grid>
      </Grid>
      {scheduleVisit && listPatient.length > 0 && (
        <DefineVisit
          open={!!scheduleVisit}
          handleClose={() => setscheduleVisit(null)}
          selectPatient={selectPatient}
          scheduleVisit={scheduleVisit}
          selectDate={selectDate}
          dictChargeId={dictChargeId}
          dictReservation={dictReservation}
          dictPatient={dictPatient}
          dictPatientTransient={dictPatientTransient}
          dictPatientFileWorker={dictPatientFileWorker}
          listPatient={listPatient}
          dailyHeaderArray={auxArrayProfessional}
          handleSaveReservation={handleSaveReservation}
        />
      )}
      {editScheduleVisit && listPatient.length > 0 && (
        <EditVisit
          open={!!editScheduleVisit}
          handleClose={() => seteditScheduleVisit(null)}
          editScheduleVisit={editScheduleVisit}
          dictChargeId={dictChargeId}
          dictReservation={dictReservation}
          dictPatientFile={dictPatientFile}
          dictPatientFileWorker={dictPatientFileWorker}
          dictPatient={dictPatient}
          listPatient={listPatient}
          dailyHeaderArray={auxArrayProfessional}
          handleEditReservation={handleEditReservation}
          handleRemoveReservation={handleRemoveReservation}
        />
      )}
      {selectSlot && selectPatient && (
        <DefineVisitCalendar
          open={!!selectSlot}
          handleClose={() => setselectSlot(null)}
          selectSlot={selectSlot}
          dictChargeId={dictChargeId}
          dictReservation={dictReservation}
          selectPatient={selectPatient}
          handleSaveReservation={handleSaveReservation}
          dailyHeaderArray={auxArrayProfessional}
        />
      )}
      {selectEditWeek && selectPatient && (
        <EditVisitCalendar
          open={!!selectEditWeek}
          handleClose={() => setselectEditWeek(null)}
          selectEditWeek={selectEditWeek}
          dictChargeId={dictChargeId}
          dictReservation={dictReservation}
          dictPatient={dictPatient}
          dictUser={dictUser}
          listPatient={listPatient}
          selectPatient={selectPatient}
          dailyHeaderArray={auxArrayProfessional}
          handleEditReservation={handleEditReservation}
          handleRemoveReservation={handleRemoveReservation}
        />
      )}
      <Fab
        className={"fabGridExtend"}
        color="primary"
        style={{zIndex: "9"}}
        onClick={() => setshowPanelTable(showPanelTable == "PANEL" ? "" : "PANEL")}
        >
        {showPanelTable == "PANEL" ? <WorkOffIcon /> :  <WorkIcon />}
      </Fab>
      <Fab
        className={"fabGridExtend"}
        color="primary"
        style={{margin: "0px 0px 70px 0", zIndex: "9"}}
        onClick={() => setshowPanelTable(showPanelTable == "CARGA" ? "" : "CARGA")}
        >
        {showPanelTable == "CARGA" ? <PublishIcon /> :  <CloudUploadIcon />}
      </Fab>
    </GridContainer>
  );
};

export default Assignments;
