import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export default function RequestFinishTable({
  dataArray,
  handleShowPrescription,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const array = dataArray.filter(
    row =>
      row.estado === "Habilitado" ||
      row.estado === "Finalizado" ||
      row.estado === "Completado" ||
      row.estado === "Rechazada" ||
      row.estado === "Cancelada"
  );

  const dictPosition = useSelector(state => state.currentList.dictCharges);
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PACIENTE);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" className="table-striped">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Prioridad</TableCell>
              <TableCell align="center">Hecha por</TableCell>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {array
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {dictTypeRequest[row.Tipo_Solicitud_Paciente]?.label}
                  </TableCell>
                  <TableCell align="center">{row.prioridad}</TableCell>
                  <TableCell align="center">
                    {row.id_cargo_solicitante
                      ? dictPosition[row.id_cargo_solicitante]?.nombre
                      : null}
                  </TableCell>
                  <TableCell align="center">{row.fecha_creacion}</TableCell>
                 
                  <TableCell align="center">
                    {row.Tipo_Solicitud_Paciente === "recetaMedica" && 
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        margin: "1% 0",
                        width: "100%",
                        textTransform: "none"
                      }}
                      onClick={() => {
                        handleShowPrescription(row)
                      }}
                    >
                      Revisar
                    </Button>
                  }
                </TableCell>
                
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={array.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

RequestFinishTable.propTypes = {
  dataArray: PropTypes.array
};
