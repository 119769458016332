import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { indigo } from "@material-ui/core/colors";
import {
  Grid,
} from "@material-ui/core";
import TableComponent from "views/Widgets/Common/TableComponent";
import { ButtonTextTemplate as Button } from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


// eslint-disable-next-line react/prop-types
export default function RequestTable({
  dataArray,
  handleClickDetails,
  title,
  handleEnableAddress,
  handleEnableAddressContracts,
  handleEnableAddressWarehouse,
  handleDecompetation,
  handleRewriteTelemedicina,
  handleRequestTelemedicina,
  handleProgressExtra,
  handlesTransport,
  setOpenModalExam,
  setOpenModalRequestExam,
  setOpenModalUploadAuthorization,
  setOpenModalWaitingContract,
  setOpenModalToPerform,
  setOpenModalUploadAuthorizationAntibiotic,
  setOpenModalPreviewRequestEquipment,
  setOpenModalUploadOrderAntibiotic,
  setOpenModalRequestAntibiotic,
  setTempRow,
  handleInsumos,
  handleReplacementMedicine,
  setOpenModalAddAuthorization,
  handleCheckEquipment,
  setopenModalAddAuthorizationQuotation,
  setopenModalAddAuthorizationProfExtra,
  setopenModalEditProfExtra,
  setopenModalTabletLose,
  setTipoTarea,
}) {
  const incompleteStateArray = [
    "Incompleto",
    "PorRealizar",
    "GestionarContratos",
    "SubirContratos",
    "Enespera",
    "SubirAutorizacion",
    "EsperandoContrato",
    "EsperandoInstrucciones",
    "InstruccionesEntregadas",
    "SubirOrdenExamenes",
    "SubirAutorizacionIsapre",
    "EnCoordinadorEnfermeria",
    "ExamenDatosEntrega",
    "SubirResultadosExamen",
    "Pendiente",
    "EsperandoRevisionEquipo",
    "EsperandoRevisionMedicamento",
    "AdjuntarGuiaDespacho",
    "AdjuntarGuiaEntrega",
  ];
  const waitStateArray = ["EsperandoConfirmacion", "Agendada"];
  const endStateArray = [
    "Completado",
    "Realizada",
    "RealizadaAtraso",
    "NoRealizada",
    "CanceladaPaciente",
    "Cancelada",
    "Finalizado"
  ];

  const dictStateWork = useSelector(state => state.currentList.DICCIONARIO_ESTADO_TAREAS_FICHA);
  const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

  const bodyRows = row => {
    const flag =
      title === "proceso"
        ? incompleteStateArray.includes(row.estado)
        : title === "otro"
        ? waitStateArray.includes(row.estado)
        : title === "finalizado"
        ? endStateArray.includes(row.estado)
        : true;
    return flag;
  };

  const headerTable = [
    {eval: true, label: "ID solicitud"},
    {eval: true, label: "ID tarea"},
    {eval: true, label: "Tipo de Tarea"},
    {eval: true, label: "Solicitado por"},
    {eval: true, label: "Fecha solicitud"},
    {eval: true, label: "Días de atraso"},
    {eval: true, label: "Paciente"},
    {eval: true, label: "Fecha de inicio"},
    {eval: true, label: "Fecha de término"},
    {eval: true, label: "Estado"},
    {eval:false, label: "Acciones"}
  ]

  const handleClick = (row, index) => {

    
    if (row.tipo_tarea === "VisitaInicial" && row.id_solicitud_paciente && row.id_tarea_padre === null && (row.estado === "EsperandoConfirmacion" || row.estado === "Incompleto")){
      handleClickDetails(row, index)
    } else if (row.tipo_tarea === "HabilitacionDomicilioContratos" && row.id_solicitud_paciente && (row.estado === "SubirContratos" || row.estado === "PorRealizar" || row.estado === "GestionarContratos")){
      handleEnableAddressContracts(row)
    } else if (row.tipo_tarea === "HabilitacionDomicilioSecretaria" && row.id_solicitud_paciente && row.estado === "PorRealizar"){
      handleEnableAddressWarehouse(row)
    } else if (row.tipo_tarea === "HabilitacionDomicilio" && row.id_solicitud_paciente && row.estado === "PorRealizar" ){
      handleEnableAddress(row)
    } else if (row.tipo_tarea === "Examen" && row.estado === "EnCoordinadorEnfermeria" ){
      setTempRow(row);
      setOpenModalExam(true);
    } else if (row.tipo_tarea === "Examen" && row.estado === "SubirAutorizacionIsapre"){
      setTempRow(row);
      setOpenModalUploadAuthorization(true);
    } else if (row.tipo_tarea === "Examen" && row.estado === "EsperandoContrato"){
      setTempRow(row);
      setOpenModalWaitingContract(true);
    } else if (row.tipo_tarea === "Examen" && row.estado === "PorRealizar"){
      setTempRow(row);
      setOpenModalToPerform(true);
    } else if (row.tipo_tarea === "Examen" && row.estado === "SubirOrdenExamenes"){
      setTempRow(row);
      setOpenModalRequestExam(true);
    } else if (row.tipo_tarea === "ExamenLaboratorio" && row.estado === "ExamenDatosEntrega"){
      setTempRow(row);
      setOpenModalWaitingContract(true);
    } else if (row.tipo_tarea === "ExamenLaboratorio" && row.estado === "SubirResultadosExamen"){
      setTempRow(row);
      setOpenModalToPerform(true);
    } else if (row.tipo_tarea === "Descompensacion" && row.id_solicitud_paciente && (row.estado === "EsperandoInstrucciones" || row.estado === "InstruccionesEntregadas")){
      handleDecompetation(row);
    } else if (row.tipo_tarea === "SolicitudInsumos" && row.estado === "PorRealizar") {
      handleInsumos(row)
    } else if (row.tipo_tarea === "TrasladoAutorizacion" && row.estado === "SubirAutorizacionIsapre") {
      setOpenModalAddAuthorization(true);
      setTempRow(row);
    } else if (row.tipo_tarea === "SolicitudCotizacion" && row.estado === "SubirAutorizacionIsapre") {
      setopenModalAddAuthorizationQuotation(true);
      setTempRow(row);
    } else if (row.tipo_tarea === "TerapiaAntibiotico" && row.estado === "PorRealizar") {
      setTempRow(row);
      setOpenModalRequestAntibiotic(true);
    } else if (row.tipo_tarea === "TerapiaAntibioticoAutorizacion" && row.estado === "PorRealizar" ) {
      setTempRow(row);
      setOpenModalUploadAuthorizationAntibiotic(true);
    } else if (row.tipo_tarea === "SolicitarEquipo" && row.estado === "PorRealizar") {
      setTempRow(row);
      setOpenModalPreviewRequestEquipment(true);
      setTipoTarea("SolicitarEquipo");
    } else if (row.tipo_tarea === "ReemplazarEquipo" && row.estado === "PorRealizar") {
      setTempRow(row);
      setOpenModalPreviewRequestEquipment(true);
      setTipoTarea("ReemplazarEquipo");
    } else if ( row.tipo_tarea === "RetirarEquipo" && row.estado === "PorRealizar") {
      setTempRow(row);
      setOpenModalPreviewRequestEquipment(true);
      setTipoTarea("RetirarEquipo");
    } else if (row.tipo_tarea === "TerapiaAntibioticoOrden" && row.estado === "PorRealizar") {
      setTempRow(row);
      setOpenModalUploadOrderAntibiotic(true);
    } else if ( row.tipo_tarea === "ReemplazoMedicamento" && row.estado === "Pendiente") {
      handleReplacementMedicine(row);
    } else if (row.tipo_tarea === "RevisionEquipo" && row.estado === "EsperandoRevisionEquipo") {
      handleCheckEquipment(row);
    } else if (row.tipo_tarea === "TranscribirLLamadaTelemedicina") {
      handleRewriteTelemedicina(row);
    } else if (row.tipo_tarea === "Telemedicina" ) {
      handleRequestTelemedicina(row);
    } else if (row.tipo_tarea === "TrasladosInsumosMedicamentos") {
      handlesTransport(row);
    } else if (row.tipo_tarea === "AutorizacionPersonalExtra" && row.estado === "PorRealizar") {
      setopenModalAddAuthorizationProfExtra(row);
    } else if  (row.tipo_tarea === "EditarExtra" && row.estado === "PorRealizar") {
      setopenModalEditProfExtra(row);
    } else if (row.tipo_tarea === "DispositivoExtraviado") {
      setopenModalTabletLose(row); 
    } else if (row.tipo_tarea === "RespuestaCoordinador" && row.estado === "PorRealizar" || endStateArray.includes(row.estado)) {
      handleProgressExtra(row);
    } else {
      window.open(`/admin/patientDetail/${row.id_ficha.id_paciente.id}`)
    }
  }
    

  const dataTable = dataArray.filter((row) => bodyRows(row)).map((row, index) => {

    const _buttonAction_ = <Button
        size="sm"
        style={{ background: indigo[500] }}
        onClick={() => handleClick(row, index)}
      >
        Ver detalle
      </Button> 

    let nameUserRequest = ""
    if (row?.id_solicitud_paciente?.id_persona_solicitante){
      const userRequest = row?.id_solicitud_paciente?.id_persona_solicitante;
      nameUserRequest = `${userRequest["first_name"]} ${userRequest["last_name"]}`
    }
    return [
      {value: row.id_solicitud_paciente ? row.id_solicitud_paciente.id : "-"},
      {value: row.id},
      {value : dictTypeWork[row.tipo_tarea]? dictTypeWork[row.tipo_tarea].label : row.tipo_tarea},
      {value : nameUserRequest},
      {value : row.id_solicitud_paciente ? moment(row.id_solicitud_paciente.fecha_creacion).format("YYYY-MM-DD") : "-"},
      {value : row.id_solicitud_paciente ? moment().diff(row.id_solicitud_paciente.fecha_creacion, "days"): "-"},
      {value : row?.id_ficha?.id_paciente ? `${row.id_ficha.id_paciente.nombre} ${row.id_ficha.id_paciente.apellido_paterno} ${row.id_ficha.id_paciente.apellido_materno}` : `No disponible`},
      {value : row.fecha_hora ? moment(row.fecha_hora).format("YYYY-MM-DD HH:mm") : "-"},
      {value : row.fecha_final ? moment(row.fecha_final).format("YYYY-MM-DD HH:mm") : "-"},
      {value : dictStateWork[row.estado]?.label || row.estado},
      {_value_ : _buttonAction_},
    ]
  })

  return (
    <Grid item xs={12} style={{ margin: "30px 0 0 0" }}>
      <TableComponent
        headers={headerTable}
        data={dataTable}
        size={'small'}
        pageSize={100}
      />
    </Grid>
  );
}

RequestTable.propTypes = {
  dataArray: PropTypes.array
};
