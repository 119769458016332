import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from "@material-ui/core";
import { red, green, indigo } from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import moment from "moment-timezone";
import "moment/locale/es";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import { ButtonTextMUI as ButtonCustom } from "utils/componentsStyle";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import {toMoneyFormat} from "utils/formatFunctions";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import allActions from "actions";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import api from "utils/API";

moment.locale("es");

export default function NewModalAdd({
  open,
  handleClose,
  listItem,
  dataInfo,
  dataArray,
  handleAddItem,
  nameType,
  message = false,
  setFlagCalculate=null,
  //setPageRefresh
}) {
  const [value, setvalue] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [unitValue, setUnitValue] = useState(0);

  const [alertExceeded, setAlertExceeded] = useState(null);
  const [enableButton, setEnableButton] = useState(true);
  const [SOS, setSOS] = useState({value:0, label: "No"});

  const dispatch = useDispatch()

  const openBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);
  const total_ex = useSelector(state => state.currentGlobalVar.TotalValueMonthConsume)
  const totalDiscount_ex = useSelector(state => state.currentGlobalVar.ValueUpdate)

  const auxDataId = dataArray.reduce((accum, obj) => ([...accum, obj.id_insumo_medicamento || obj.id]), [])

  const totalPackage = useSelector(state => state.currentGlobalVar.TotalValuePackage);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const viewQuotation = useSelector(state => state.currentGlobalVar.patientPackage);

  const validateAmountMedicalResourse = (
    ["ParticularServicios", "ParticularHD"].includes(viewQuotation?.packagingInfo?.tipo_paciente) ||
    // viewQuotation?.packagingInfo?.id_convenio_isapre?.nombre == "Fondo Nacional de Salud" ||
    // viewQuotation?.forecast[0]?.nombre == "Fondo Nacional de Salud" ||
    viewQuotation?.packagingInfo?.tipo_permanencia == "Transitorio" ||
    (viewQuotation?.packagingInfo?.insumos == 0 && viewQuotation?.packagingInfo?.medicamentos == 0)
  )

  const handleOnChange = (value, set) => {

    let element = dataInfo[value.value]
    value.unitValue = element.valor_con_iva || 0
    let totalMount = validateAmountMedicalResourse ? value.unitValue * 1 : totalPackage - (value.unitValue * 1)
    if(totalMount < 0 && (setFlagCalculate == null || !validateAmountMedicalResourse)){
      setAlertExceeded(true)
      setEnableButton(true)
    }else{
      setAlertExceeded(false)
      setEnableButton(false)
    }
    if (setFlagCalculate){
      setFlagCalculate(true)
    }
    setUnitValue(value.unitValue);
    setQuantity(1);
    set(value);
  };



  const handleQuantity = (e) => {
    let totalMount = validateAmountMedicalResourse ? unitValue * e :  totalPackage - (unitValue * e)
    if(totalMount - (total_ex + totalDiscount_ex) < 0 && ( !validateAmountMedicalResourse || setFlagCalculate == null)){
    // if(totalMount < 0 ){
      setAlertExceeded(true)
      setEnableButton(true)
    }else{
      setAlertExceeded(false)
      setEnableButton(false)
    }
    if (setFlagCalculate){
      setFlagCalculate(true)
    }
    setQuantity(e);
  }

  const updateValue = () => {
    handleAddItem(value, quantity, SOS.value);
    handleClose();
  };

  const updateStock = () => {

    api.get("/api/poblarinsumos/").then(() => {
        getListSuppliesMedicines(dispatch, chargeMedicine);
    }).catch((error) => {

    });
  };

  const handleSOS = (value) => {
    setSOS(value)
    if (value.value){
      handleQuantity(0)
    }
  } 

  const DialogContent = <Grid>
    {openBackdrop &&
      <div></div>
    }
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      item
      xs={12}
    >
      <Grid item container xs={12}>
        <Grid item container xs={1}></Grid>
        <Grid item container xs={10}>
          <Typography>
            {
              message ?
                message
                :
                'Al agregar un producto al paquete se agregará también automáticamente al siguiente envío.'
            }

          </Typography>
        </Grid>
        <Grid item container xs={1}></Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container xs={1}></Grid>
        <Grid item container xs={4}>
          <Typography style={{ color: indigo[500], marginTop: "25px", flexBasis: "49.666667%" }}>Item</Typography>
        </Grid>
        <Grid item container xs={1}></Grid>
        <Grid item container style={{ marginLeft: "-16px" }} xs={6}>
          <DetailSelect
            value={value}
            validation={false}
            onchange={value => {
              handleOnChange(value, setvalue);
            }}
            list={listItem}
          />
        </Grid>
      </Grid>
      {!SOS.value &&
      <Grid item container xs={12}>
        <Grid item container xs={1}></Grid>
        <Grid item container xs={4}>
          <Typography style={{ color: indigo[500] }}>Cantidad</Typography>
        </Grid>
        <Grid item container xs={1}></Grid>
        <Grid item container xs={6} >
          <TextField
            variant="outlined" type="number" size={"small"} style={{ width: "60px" }} inputProps={{ min: "1" }} value={quantity} onChange={(e) => handleQuantity(e.target.value)} />
        </Grid>
      </Grid>
      }
    </Grid>

    <Grid item container style={{ marginTop: "30px" }} xs={12} >
      <Grid item container xs={1}></Grid>
      <Grid item container xs={4}>
        <Typography style={{ color: indigo[500] }}> Valor unitario </Typography>
      </Grid>
      <Grid item container xs={1}></Grid>
      <Grid item container xs={5} >
        <Typography style={{ fontSize: "14px" }}>{toMoneyFormat(unitValue)}</Typography>
      </Grid>
      <Grid item container xs={1}></Grid>
    </Grid>
    <Grid item container xs={12} style={{ marginTop: "30px" }}>
      <Grid item container xs={1}></Grid>
      <Grid item container xs={4}>
        <Typography style={{ color: indigo[500] }}>Total </Typography>
      </Grid>
      <Grid item container xs={1}></Grid>
      <Grid item container xs={5} >
        <Typography style={{ fontSize: "14px" }}>{toMoneyFormat(unitValue * quantity)}</Typography>
      </Grid>
      <Grid item container xs={1}></Grid>
    </Grid>
    <Grid item container xs={12}>
      {auxDataId.includes(value.value) &&
        <Typography style={{ color: "red", fontSize: "10px", alignItems: "center", textAlign: "center", paddingTop: "15px"}}>
          {`${value.label} ya se encuentra en el paquete.`}
        </Typography>
      }
      { alertExceeded &&
        <Typography style={{ color: "red", fontSize: "10px", alignItems: "center", textAlign: "center", paddingTop: "15px"}}>
          La cantidad excede el monto disponible, disminúyela para no sobrepasarlo
        </Typography>
      }
    </Grid>
    <Grid item container xs={12}>
        <Grid item container xs={1}></Grid>
        <Grid item container xs={4}>
          <Typography style={{ color: indigo[500], marginTop: "25px", flexBasis: "49.666667%" }}>SOS</Typography>
        </Grid>
        <Grid item container xs={1}></Grid>
        <Grid item container style={{ marginLeft: "-16px" }} xs={6}>
          <DetailSelect
            value={SOS}
            validation={false}
            onchange={value => {handleSOS(value);}}
            list={[{value: 0, label: "No"}, {value: 1, label: "Sí"}]}
          />
        </Grid>

      </Grid>
    
    <UpdateStockButton
        updateStock={updateStock}
    />
  </Grid>

  const _actionDialog_ = <>
    <ButtonCustom
      variant={"outlined"} style={{ color: red[900], width: "125px", heigth: "36px" }}
      onClick={handleClose}
    >
      <CancelIcon />CANCELAR
    </ButtonCustom>
    {(value?.label && !alertExceeded && unitValue > 0 && !enableButton && (quantity > 0 || SOS.value) && !auxDataId.includes(value.value)) ? (
      <ButtonCustom
        mr={2}
        variant={"outlined"} style={{ color: green[500] , width: "125px", heigth: "36px" }}
        onClick={updateValue}
      >
        <AddCircleIcon /> AGREGAR
      </ButtonCustom>
    ) : (
      <ButtonCustom disabled variant={"outlined"} style={{ color: "rgba(0, 0, 0, 0.26)", width: "125px", heigth: "36px" }}>
        <AddCircleIcon /> AGREGAR
      </ButtonCustom>
    )}

  </>

  return (
    <ModalDialog
      open={true}
      onClose={handleClose}
      title={`AGREGAR ${nameType}`}
      _content_={DialogContent}
      _actions_ = {_actionDialog_}
      maxWidth={"xs"}
    />
  )
}