import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import RequestPatientTabs from "components/PatientFile/RequestPatientTabs";
import ModalRequestQuotation  from "components/PatientFile/ModalRequestQuotation";
import RequestVisit  from "components/PatientFile/RequestVisit";
import ViewQuotation  from "components/PatientFile/ViewQuotation";
import ModalRemoveRequest  from "components/PatientFile/ModalRemoveRequest";
import ModalDecompensation from "components/PatientFile/ModalDecompensation";
import ModalGenPrescription from "components/PatientFile/ModalGenPrescription";
import ModalRequestSuppliesMedicine from "components/PatientFile/ModalRequestSuppliesMedicine";
import ModalClinicWorkers from "components/PatientFile/ModalClinicWorkers";
import DetailEnableAddress from "components/PatientFile/DetailEnableAddress";
import ModalRequestAntibiotic from "components/PatientFile/ModalRequestAntibiotic";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import Equipos from "./Equipos/Equipos";
import Examenes from "./Examenes/Examenes";
import ModalRequestTraslate from "./Traslados/ModalRequestTraslate";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Link,
  Hidden,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import moment from "moment-timezone";
import api from "utils/API";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import "moment/locale/es";
moment.locale("es");

const RequestFile = ({
  dataPatient,
  dataTutor,
  dataFile,
  setdataFile,
  listEquip,
  equipData,
  setselectPatient,
  setopenModalQuotation,
  selectForecast,
  selectPackage,
  selectpackageUnbalancedPatient,
  packagePatient,
  clinic,
  handleReloadFile,
}) => {
  const dispatch = useDispatch()
  const [reload, setreload] = useState(false);
  const [typeRequest, settypeRequest] = useState(null);
  const [viewQuotation, setviewQuotation] = useState(null);
  const [initialCharge, setinitialCharge] = useState(true);
  const [loading, setloading] = useState(false);
  const [reloadRequest, setreloadRequest] = useState(null);
  const [selectDelteRow, setSelectDeleteRow] = useState(null);
  const [modalClinicWorkers, setmodalClinicWorkers] = useState(false);

  const [detailEnablementAddress, setdetailEnablementAddress] = useState(null);
  const [openModalHistory, setopenModalHistory] = useState(null);
  const [openShowPrescription, setopenShowPrescription] = useState(null);

  const dataRequest = useSelector(state => state.currentList.listRequestPatient);
  const validationQualitication = useSelector(state => state.currentGlobalVar.enablePatientAddress);

  useEffect(() => {
    getListRequestPatient(dataPatient, dispatch)
  }, []);

  useEffect(() => {
    if (initialCharge){
      initialData();
    }
  });

  const type_user = localStorage.getItem("tipo_perfil");

  const initialData = () => {
    setinitialCharge(false);


    Promise.all([
    ]).then((request) => {
      setreloadRequest(null);
      setloading(true);
    });
  };

  const handleReloadRequest = () => {
    getListRequestPatient(dataPatient, dispatch);
    settypeRequest(null);
  };

  const handleBack = (row) => {
    localStorage.setItem("open_solicitud","false")
    row.map((auxRow) => {
      auxRow(null);
    });
  };

  const handleSelectRow = (row) => {
    setreloadRequest(row);
    setviewQuotation({requestPatientInfo: row});
  };

  const handleQualitication = (row=null) => {
    let tempArrayQualification = row
    if (tempArrayQualification === null){
       tempArrayQualification = dataRequest.filter((row) => row.estado === "EsperandoHabilitacion");
       tempArrayQualification[0]
    }
    
    setreloadRequest(tempArrayQualification);
    setviewQuotation({requestPatientInfo: tempArrayQualification});
  };

  const handleSelectDetail = (row) => {

    api.get(`api/clinica/solicitudes/${row.id}/habilitacion/prestaciones/`).then((result) => {
      setdetailEnablementAddress(result.data);
      setreloadRequest(row);

      setviewQuotation(null);
    });
  };

  const handleHistory = (row) => {
    setopenModalHistory(row);
  }

  const handleShowPrescription = (row) => {
    setopenShowPrescription(row);
  };

  const validateTens = type_user === "CLITecEnfBas";

  const listButtons = [
    {label: "Visitas/Horas medicas", onClick: () => settypeRequest("visita"), disabled: validateTens},
    {label: "Cotización", onClick: () => settypeRequest("Cotización"), disabled: validateTens},
    {label: "Personal clínico", onClick: () => settypeRequest("PersonalClinico"), disabled: validateTens},
    {label: "Traslados", onClick: () => settypeRequest("Traslados"), disabled: validateTens},
    { label: "Próximamente", onClick: () => {}}, 
     
  ]

  const listButtons2 = [

    {label: "Insumos y medicamentos", onClick: () => settypeRequest("insumos"), disabled:false},
    {label: "Receta/Orden médica", onClick: () => settypeRequest("receta"), disabled: validateTens},
    {label: "Tratamiento antibiotico", onClick: () => settypeRequest("antibiotico"), disabled: validateTens},
    {label: "Exámenes", onClick: () => settypeRequest("examenes"), disabled: validateTens},
    {label: "Equipos", onClick: () => settypeRequest("equipos"), disabled: validateTens},
  ]

  const listButton3 = [...listButtons, ...listButtons2]

  return (
    <div>
      {viewQuotation ?
        <ViewQuotation
          dataPatient={dataPatient}
          viewQuotation={viewQuotation}
          setopenModalQuotation={setopenModalQuotation}
          setselectPatient={setselectPatient}
          setviewQuotation={setviewQuotation}
          settypeRequest={handleReloadRequest}
          handleReloadFile={handleReloadFile}
        />
      : detailEnablementAddress ?
      <DetailEnableAddress
        dataPatient={dataPatient}
        dataFile={dataFile}
        setdataFile={setdataFile}
        reloadRequest={reloadRequest}
        detailEnablementAddress={detailEnablementAddress}
        setselectPatient={setselectPatient}
        setopenModalQuotation={setopenModalQuotation}
        settypeRequest={handleReloadRequest}
        setdetailEnablementAddress={setdetailEnablementAddress}

      />
      :
      <GridContainer>
        {loading ?
            <GridItem xs={12}>
              <GridItem xs={12}>
                <CardBody className="rounded shadow border border-primary">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      color="inherit"
                      onClick={() =>
                        handleBack([
                          setselectPatient,
                          setopenModalQuotation,
                          settypeRequest
                        ])
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Ficha Paciente
                    </Link>
                    <Link
                      color="inherit"
                      onClick={() =>
                        handleBack([setopenModalQuotation, settypeRequest])
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {`${dataPatient.nombre} ${dataPatient.apellido_paterno}`}
                    </Link>
                    {typeRequest === null ? (
                      <Link
                        color="inherit"
                        onClick={() => handleReloadRequest()}
                        style={{ cursor: "pointer" }}
                      >
                        {`Solicitudes`}
                      </Link>
                    ) : (
                      <Link
                        color="inherit"
                        onClick={() => handleReloadRequest()}
                        style={{ cursor: "pointer" }}
                      >
                        {`Solicitudes`}
                      </Link>
                    )}
                    {typeRequest === "examenes" ? (
                      <Typography color="textPrimary">{`Exámenes`}</Typography>
                    ) : typeRequest === "visita" ? (
                      <Typography color="textPrimary">{`Visitas`}</Typography>
                    ) : typeRequest === "Cotización" ? (
                      <Typography color="textPrimary">{`Cotización`}</Typography>
                    ) : typeRequest === "Traslados" ? (
                      <Typography color="textPrimary">{`Traslados`}</Typography>
                    ) : typeRequest === "Insumos" ? (
                      <Typography color="textPrimary">{`Insumos`}</Typography>
                    ) : typeRequest === "equipos" ? (
                      <Typography color="textPrimary">{`Equipos`}</Typography>
                    ) :null}
                </Breadcrumbs>
              </CardBody>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Hidden smDown>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  item
                  xs={12}
                >
                  {listButtons.map((row, index) => 
                    <Grid 
                      item 
                      md={2} 
                      key={`${index}-${row.label}`}
                      style={{padding: "10px"}} 
                    >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={row.disabled}
                      size="large"
                      style={{
                        height: "70px",
                        width: "100%",
                      }}
                      onClick={() => row.onClick()}
                    >
                      {row.label}
                    </Button> 
                    </Grid>
                  )}
                </Grid>
                </Hidden>
                <Hidden smDown>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  item
                  xs={12}
                >
                {listButtons2.map((row, index) => 
                    <Grid 
                      item 
                      md={2} 
                      key={`${index}-${row.label}`}
                      style={{padding: "10px"}} 
                    >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={row.disabled}
                      size="large"
                      style={{
                        height: "70px",
                        width: "100%",
                      }}
                      onClick={() => row.onClick()}
                    >
                      {row.label}
                    </Button> 
                    </Grid>
                  )}
                </Grid>
                </Hidden>
                <Hidden mdUp>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  item
                  xs={12}
                >
                {listButton3.map((row, index) => 
                    <Grid 
                      item 
                      sm={6} 
                      xs={12}
                      key={`${index}-${row.label}`}
                      style={{padding: "10px"}} 
                    >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={row.disabled}
                      size="large"
                      style={{
                        height: "70px",
                        width: "100%",
                      }}
                      onClick={() => row.onClick()}
                    >
                      {row.label}
                    </Button> 
                    </Grid>
                  )}
                </Grid>
                </Hidden>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  item
                  xs={12}
                  style={{margin: "0 0 20px 0"}}
                >
                  {typeRequest === "visita" ?
                      <RequestVisit
                        dataPatient={dataPatient}
                        dataTutor={dataTutor}

                        handleReloadRequest={handleReloadRequest}
                      />
                  : typeRequest === "equipos" ?
                      <Equipos
                        settypeRequest={settypeRequest}
                          dataFile={dataFile}
                          dataPatient={dataPatient}
                      />
                  : typeRequest === "examenes" ?
                        <Examenes
                          setTypeRequest={settypeRequest}
                          dataPatient={dataPatient}
                          clinic={clinic}
                        />
                  : <RequestPatientTabs
                      dataPatient={dataPatient}
                      handleSelectRow={handleSelectRow}
                      handleDeleteRow={setSelectDeleteRow}
                      handleSelectDetail={handleSelectDetail}
                      handleQualitication={handleQualitication}
                      handleHistory={handleHistory}
                      handleShowPrescription={handleShowPrescription}
                      listEquip={listEquip}
                      equipData={equipData}
                      reload={reload}
                    />
                  }
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridItem>
        :
        <GridItem xs={12}>
          <LoadInfo
            title={"Cargando información ..."}
          />
        </GridItem>
        }


      {typeRequest === "Cotización" &&
       <ModalRequestQuotation
        open={typeRequest === "Cotización"}
        closeModal={() => settypeRequest(null)}
        dataFile={dataFile}
        setviewQuotation={setviewQuotation}
        settypeRequest={settypeRequest}
        selectForecast={selectForecast}
        selectPackage={selectPackage}
        selectpackageUnbalancedPatient={selectpackageUnbalancedPatient}
        dataPatient={dataPatient}
        setreloadRequest={setreloadRequest}
        actualPackage={packagePatient}
     />
      }
      {typeRequest === "PersonalClinico" &&
       <ModalClinicWorkers
        open={typeRequest === "PersonalClinico"}
        closeModal={() => settypeRequest(null)}
        dataPatient={dataPatient}
     />
      }
      {typeRequest === "receta" &&
        <ModalGenPrescription
          open={typeRequest === "receta"}
          closeModal={() => settypeRequest(null)}
          dataPatient={dataPatient}
        />
      }
      {openShowPrescription &&
        <ModalGenPrescription
          open={openShowPrescription !== null}
          requestInfo={openShowPrescription}
          editable={false}
          closeModal={() => setopenShowPrescription(null)}
          dataPatient={dataPatient}
        />
      }
      {typeRequest === "insumos" &&
        <ModalRequestSuppliesMedicine
          openModal={typeRequest === "insumos"}
          closeModal={() => settypeRequest(null)}
          dataPatient={dataPatient}
          recharche={() => {}}
        />
      }
      {typeRequest === "antibiotico" &&
        <ModalRequestAntibiotic
          openModal={typeRequest === "antibiotico"}
          closeModal={() => settypeRequest(null)}
          dataPatient={dataPatient}
        />
      }
      {typeRequest === "Traslados" &&
        <ModalRequestTraslate
            openModal={typeRequest === "Traslados"}
            closeModal={() => settypeRequest(null)}
            dataPatient={dataPatient}
          />
      }
      {selectDelteRow &&
        <ModalRemoveRequest
          open={!!selectDelteRow}
          handleClose={() => setSelectDeleteRow(null)}
          row={selectDelteRow}
          dataPatient={dataPatient}

        />
      }
       {openModalHistory &&
        <ModalDecompensation
          open={openModalHistory}
          handleClose={() => setopenModalHistory(false)}
          requestData={openModalHistory}
          patient={dataPatient}
          flagProps={"historial"}
          switchProps={false}
        />
      }
      </GridContainer>
      }
    </div>
    );
};

export default RequestFile;
