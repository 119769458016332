import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { green, indigo, orange, red  } from "@material-ui/core/colors";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import FirstPart from "./Components/FirstPart";
import NavPills from "components/NavPills/NavPills.js";
import Motivo from "./Components/Motivo";
import Tipo from "./Components/Tipo";
import Origen from "./Components/Origen";
import Profesionales from "./Components/Profesionales";
import api from "utils/API";
import moment from "moment-timezone";
import {
  getListRequestPatient,
} from 'actions/getListAction';


import ModalDialog from "views/Widgets/Common/ModalDialog";

const ModalRequestTraslate = props => {
  const dispatch = useDispatch()
  const { openModal, closeModal, dataPatient } = props;
  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [professionalSelected, setProfessionalSelected] = useState(false);
  const [sendApproval, setSendApproval] = useState(false);
  const [transferQuantity, setTransferQuantity] = useState({});
  const [firstCharge, setFirstCharge] = useState(true);
  const [tabSection, setTabSection] = useState(0);
  const [reason, setReason] = useState("");
  const [reasons, setReasons] = useState("");
  const [indications, setIndications] = useState("");
  const [transferType, setTransferType] = useState("Auto");
  const [origin, setOrigin] = useState("");
  const [originDate, setOriginDate] = useState(moment());
  const [originStreet, setOriginStreet] = useState("");
  const [originNumber, setOriginNumber] = useState("");
  const [originDepto, setOriginDepto] = useState("");
  const [originBlock, setOriginBlock] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [originComuna, setOriginComuna] = useState("");
  const [originRegion, setOriginRegion] = useState("");
  const [originDescription, setOriginDescription] = useState("");
  const [originComments, setOriginComments] = useState("");
  const [originGoing, setoriginGoing] = useState(true)
  const [originReturn, setoriginReturn] = useState(true)
  const [amounts, setamounts] = useState({})

  const [destination, setDestination] = useState("");
  const [destinationDate, setDestinationDate] = useState(moment());
  const [destinationStreet, setDestinationStreet] = useState("");
  const [destinationNumber, setDestinationNumber] = useState("");
  const [destinationDepto, setDestinationDepto] = useState("");
  const [destinationBlock, setDestinationBlock] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationComuna, setDestinationComuna] = useState("");
  const [destinationRegion, setDestinationRegion] = useState("");
  const [destinationDescription, setDestinationDescription] = useState("");
  const [destinationComments, setDestinationComments] = useState("");
  const [waitPatient, setwaitPatient] = useState(true);

  const dictTab = {
    "0": "motivo",
    "1": "tipo",
    "2": "origen",
    "3": "profesionales",
  }

  const [transferPrice, setTransferPrice] = useState(0);

  const viewOnly = false;

  const compareProfessionals = [];
  const checkProfessionals = [];
  const [deleteProfessionals, setDeleteProfessionals] = useState([]);
  const [addProfessionals, setAddProfessionals] = useState([]);
  const [editProfessionals, setEditProfessionals] = useState([]);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  useEffect(() => {
    checkFileSendApproval();
    if (!originGoing & !originReturn){
      setoriginReturn(true);
      setoriginGoing(true);

    }
  }, [originGoing, originReturn]);

  const checkFileSendApproval = (argAmount=null) => {
    const tempAmount = argAmount || amounts
    if (originGoing && originReturn){
      if (tempAmount.cantidad_pedida_ida_vuelta >= tempAmount.cantidad_asignada_ida_vuelta) {
        setSendApproval(true);
      } else {
        setSendApproval(false);
      }
      setTransferQuantity({cantidad_pedida: tempAmount.cantidad_pedida_ida_vuelta, cantidad_asignada: tempAmount.cantidad_asignada_ida_vuelta});
    } else if (originGoing) {
      if (tempAmount.cantidad_pedida_ida >= tempAmount.cantidad_asignada_ida) {
        setSendApproval(true);
      } else {
        setSendApproval(false);
      }
      setTransferQuantity({cantidad_pedida: amounts.cantidad_pedida_ida, cantidad_asignada: amounts.cantidad_asignada_ida});
    } else if (originReturn) {
      if (tempAmount.cantidad_pedida_vuelta >= tempAmount.cantidad_asignada_vuelta) {
        setSendApproval(true);
      } else {
        setSendApproval(false);
      }
      setTransferQuantity({cantidad_pedida: amounts.cantidad_pedida_vuelta, cantidad_asignada: amounts.cantidad_asignada_vuelta});
    }

  }

  const initialCharge = () => {
    setFirstCharge(false);
    const getRegiones = api.get("api/locaciones/regiones/");
    const getProfessionals = api.get(
      `api/paciente/${dataPatient.id}/solicitudes/traslados/datosbase/`
    );

    Promise.all([getRegiones, getProfessionals])
      .then(response => {
        let tempRegiones = [];
        response[0].data.map(row => {
          tempRegiones.push({
            value: row.id,
            label: row.nombre
          });
        });
        let tempProfessionals = [];
        response[1].data.cargos.map(row => {
          tempProfessionals.push({
            id: row.id,
            name: row.nombre_corto,
            selected: false,
            cantidad: 1
          });
        });
        const tempAmounts= response[1].data.cantidades
        checkFileSendApproval(tempAmounts)
        setamounts(tempAmounts)
        setProfessionals(tempProfessionals);
        setRegiones(tempRegiones);

      })

  };

  const sendRequest = save => {
    let tempProfessionals = [];
    professionals
      .filter(professional => professional.selected)
      .map(row => {
        tempProfessionals.push({
          cantidad: row.cantidad,
          id_cargo: row.id
        });
      });

    let send = {
      tipo_motivo_traslado: reason,
      indicaciones_generales: indications,
      tipo_traslado: transferType,
      fecha_hora_inicio: moment(originDate).format("YYYY-MM-DDTHH:mm:ss"),
      calle_inicio: originStreet,
      numero_inicio: originNumber,
      ciudad_inicio: originCity,
      comuna_inicio: originComuna.label,
      region_inicio: originRegion.label,
      fecha_hora_fin: moment(destinationDate).format("YYYY-MM-DDTHH:mm:ss"),
      calle_fin: destinationStreet,
      numero_fin: destinationNumber,
      ciudad_fin: destinationCity,
      comuna_fin: destinationComuna.label,
      region_fin: destinationRegion.label,
      esperar_paciente: waitPatient,
      profesionales_solicitados: tempProfessionals,
      texto_motivo_traslado: reasons,
      valor_traslado: transferPrice,
      depto_casa_inicio: originDepto,
      block_inicio: originBlock,
      comentarios_inicio: originComments,
      depto_casa_fin: destinationDepto,
      block_fin: destinationBlock,
      ida: originGoing,
      vuelta: originReturn,
      extra: sendApproval
    };

    api.post(`/api/paciente/${dataPatient.id}/solicitudes/traslados/`, send).then(response => {
        if (save) {
          api.put(`/api/paciente/${dataPatient.id}/solicitudes/traslados/${response.data.id}/enviar/`).then(responsePut => {
            getListRequestPatient(dataPatient, dispatch)
            closeModal();
          })
        } else {
            getListRequestPatient(dataPatient, dispatch)
            closeModal();
        }

      }).catch(error => console.log("error", error));
  };

  const validateSend = reason !== "" && indications !== "" && transferType !== "" && originStreet !== "" && originNumber !== "" && originRegion !== "" && originComuna !== "" && 
    originCity !== "" && destinationStreet !== "" && destinationNumber !== "" && destinationRegion !== "" && destinationComuna !== "" && (originGoing || originReturn ) && professionalSelected 

  const _content_ =  <div>
  <FirstPart
    dataPatient={dataPatient}
    transferPrice={transferPrice}
    setTransferPrice={setTransferPrice}
    transferQuantity={transferQuantity}
    originGoing={originGoing}
    originReturn={originReturn}
  />
    <GridItem xs={12}>
      <NavPills
        color="primary"
        alignCenter
        tabs={[
            {
            tabButton: "1. Motivo traslado",
            tabContent: (
              <Motivo
                reason={reason}
                setReason={setReason}
                reasons={reasons}
                setReasons={setReasons}
                indications={indications}
                setIndications={setIndications}
                viewOnly={viewOnly}
              />

            )},
            {
            tabButton: "2. Tipo de traslado",
            tabContent: (
              <Tipo
                transferType={transferType}
                setTransferType={setTransferType}
                originGoing={originGoing}
                setoriginGoing={setoriginGoing}
                originReturn={originReturn}
                setoriginReturn={setoriginReturn}
                viewOnly={viewOnly}
              />
            )},
            {
            tabButton: "3. Origen y destino",
            tabContent: (
              <Origen
              dataPatient={dataPatient}
              regiones={regiones}
              comunas={comunas}
              setComunas={setComunas}
              origin={origin}
              setOrigin={setOrigin}
              originDate={originDate}
              setOriginDate={setOriginDate}
              originStreet={originStreet}
              setOriginStreet={setOriginStreet}
              originNumber={originNumber}
              setOriginNumber={setOriginNumber}
              originDepto={originDepto}
              setOriginDepto={setOriginDepto}
              originBlock={originBlock}
              setOriginBlock={setOriginBlock}
              originCity={originCity}
              setOriginCity={setOriginCity}
              originComuna={originComuna}
              setOriginComuna={setOriginComuna}
              originRegion={originRegion}
              setOriginRegion={setOriginRegion}
              originDescription={originDescription}
              setOriginDescription={setOriginDescription}
              originComments={originComments}
              setOriginComments={setOriginComments}
              destination={destination}
              setDestination={setDestination}
              destinationDate={destinationDate}
              setDestinationDate={setDestinationDate}
              destinationStreet={destinationStreet}
              setDestinationStreet={setDestinationStreet}
              destinationNumber={destinationNumber}
              setDestinationNumber={setDestinationNumber}
              destinationDepto={destinationDepto}
              setDestinationDepto={setDestinationDepto}
              destinationBlock={destinationBlock}
              setDestinationBlock={setDestinationBlock}
              destinationCity={destinationCity}
              setDestinationCity={setDestinationCity}
              destinationComuna={destinationComuna}
              setDestinationComuna={setDestinationComuna}
              destinationRegion={destinationRegion}
              setDestinationRegion={setDestinationRegion}
              destinationDescription={destinationDescription}
              setDestinationDescription={setDestinationDescription}
              destinationComments={destinationComments}
              setDestinationComments={setDestinationComments}
              waitPatient={waitPatient}
              setwaitPatient={setwaitPatient}
              viewOnly={viewOnly}
            />
            )},
            {
                tabButton: "4. Profesionales clinicos",
                tabContent: <Profesionales
                  professionals={professionals}
                  setProfessionals={setProfessionals}
                  professionalSelected={professionalSelected}
                  setProfessionalSelected={setProfessionalSelected}
                  viewOnly={viewOnly}
                  compareProfessionals={[...compareProfessionals]}
                  checkProfessionals={checkProfessionals}
                  addProfessionals={addProfessionals}
                  setAddProfessionals={setAddProfessionals}
                  deleteProfessionals={deleteProfessionals}
                  setDeleteProfessionals={setDeleteProfessionals}
                  editProfessionals={editProfessionals}
                  setEditProfessionals={setEditProfessionals}
              />

            }
        ]}
        onClick={setTabSection}
        updateTab={tabSection}
      />
      </GridItem>
</div>

const backTab = tabSection-1;
const nextTab = tabSection+1;

console.log(tabSection)

const _actions_ = <>
   {dictTab[backTab.toString()] &&
    <Button
      variant="contained"
      color="primary"
      style={{background: red[500]}}
      onClick={() => setTabSection(backTab)}
    >
      Atras
    </Button>
    }
  {dictTab[nextTab.toString()] &&
    <Button
      variant="contained"
      color="primary"
      style={{background: green[500]}}
      onClick={() => setTabSection(nextTab)}
    >
      Siguiente
    </Button>
    }
    {dictTab[nextTab.toString()] === undefined &&
    <Button
      disabled={!validateSend}
      variant="contained"
      color="primary"
      style={{ textTransform: "none", marginLeft: "5px" }}
      onClick={() => sendRequest(true)}
    >
      Enviar
    </Button>
    }
    <Button
      variant="contained"
      color="primary"
      style={{ textTransform: "none", marginLeft: "5px" }}
    >
      Cancelar
    </Button>
</>



  return (
    <ModalDialog
    open={openModal}
    onClose={closeModal}
    title={`Traslado paciente`}
    _content_={_content_}
    _actions_ = {_actions_}
    maxWidth="lg"
/>
  );
};

export default ModalRequestTraslate;

